import { useEffect } from 'react';

export default function useResizingTextArea(textAreaRef: HTMLTextAreaElement | null, value: string) {
    useEffect(() => {
        if (textAreaRef) {
            textAreaRef.style.height = '0px';
            const scrollHeight = textAreaRef.scrollHeight + 2;
            textAreaRef.style.height = scrollHeight + 'px';
        }
    }, [textAreaRef, value]);
}
