export enum NotificationType {
    AVAILABLE_STATION = 'AVAILABLE_STATION',
    FINISHED_CHARGING = 'FINISHED_CHARGING',
    STATION_DOWN = 'STATION_DOWN',
    UNMOVED_EV = 'UNMOVED_EV',
    UNKNOWN_FUELCARD = 'UNKNOWN_FUELCARD',
    STATION_DOWN_REMINDER = 'STATION_DOWN_REMINDER',
    BLOCKED_FUELCARD = 'BLOCKED_FUELCARD',
    POOL_USER_READY_FOR_PICKUP = 'POOL_USER_READY_FOR_PICKUP'
}

export default NotificationType;
