import { Alert } from 'react-bootstrap';
import FuelcardFormField from '../FuelcardFormField';
import FuelcardFormData from '../../../../model/FuelcardFormData';
import { SetValueConfig, UseFormReturn } from 'react-hook-form';
import { ChangeEvent } from 'react';

interface ExternalUserEmailFieldProps {
    fuelcard: FuelcardFormData;
    isAdminOrManager: boolean;
    handleChange: <K extends keyof FuelcardFormData>(
        name: K,
        value: FuelcardFormData[K],
        setValue: (name: K, value: FuelcardFormData[K], options?: Partial<SetValueConfig>) => void
    ) => void;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
}

export default function ExternalUserEmailField(props: ExternalUserEmailFieldProps) {
    return (
        <>
            <Alert variant={'info'} style={{ fontSize: 'small', padding: '0.5em' }}>
                External users will be sent an email informing them that an account has been created for them but that they cannot log into it.
            </Alert>
            <FuelcardFormField
                label={'Email'}
                type="text"
                name="contactEmail"
                autoComplete="email"
                value={props.fuelcard.contactEmail}
                readOnly={!props.isAdminOrManager}
                onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange('contactEmail', e.target.value, props.methods.setValue)}
                register={props.methods.register}
                registerOptions={{
                    required: 'Contact email is required.',
                    pattern: {
                        value: /.+@.+\.+/,
                        message: 'Email should contain a @ and a .'
                    }
                }}
            />
        </>
    );
}
