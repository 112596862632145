import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import { TIMEZONE } from '../../shared/Timezone';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { searchFuelcards } from '../../../service/Api';
import SearchFuelcard from '../../../model/fuelcard/SearchFuelcard';

interface Option {
    value: string | undefined;
    label: string;
    fuelcardTag: string;
    licensePlate: string;
    displayName: string;
    fullName: string;
}

interface SelectFuelCardListProps {
    setSelectedFuelCard: React.Dispatch<React.SetStateAction<string>>;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

export default function SelectFuelCardList(props: SelectFuelCardListProps) {
    const [searchText, setSearchText] = useState('1');
    const [page, setPage] = useState(0);

    const { data, isLoading, isError } = useQuery(['fuelCards', searchText, page], () => searchFuelcards(searchText, page), {
        keepPreviousData: true,
        select: (response) => {
            // Transform the response data into FuelcardResponse format
            return response.data.content as SearchFuelcard[];
        }
    });

    useEffect(() => {
        setPage(0);
    }, [searchText]);

    const sameNameAndLastTwoMap = new Map<string, SearchFuelcard[]>();

    if (isLoading) {
        return <p>Loading fuel cards...</p>;
    }

    if (isError) {
        return <p>Error fetching fuel cards</p>;
    }

    const fuelCards = data || [];

    const options = fuelCards.map((fuelCard: SearchFuelcard) => {
        const key = `${fuelCard.name}-${fuelCard.displayName}-${moment(fuelCard.lastUsed).tz(TIMEZONE).format('DD/MM/YYYY HH:mm')}`;

        if (!sameNameAndLastTwoMap.has(key)) {
            sameNameAndLastTwoMap.set(key, []);

            const newDuplicates = fuelCards.filter(
                (fc: SearchFuelcard) =>
                    fc.name === fuelCard.name &&
                    fc.serialNumber !== fuelCard.serialNumber &&
                    fc.serialNumber?.slice(0, -2) !== fuelCard.serialNumber?.slice(0, -2) &&
                    fc.serialNumber?.slice(-2) === fuelCard.serialNumber?.slice(-2)
            );

            // eslint-disable-next-line
            sameNameAndLastTwoMap.set(key, sameNameAndLastTwoMap.get(key)!.concat(newDuplicates));
        }

        // eslint-disable-next-line
        const duplicates = sameNameAndLastTwoMap.get(key)!;
        const duplicateIndex = duplicates.indexOf(fuelCard);

        const label =
            `${fuelCard.name} ${fuelCard.displayName} (${moment(fuelCard.lastUsed).tz(TIMEZONE).format('DD/MM/YYYY HH:mm')})` +
            (duplicateIndex !== -1 ? ` - ${duplicateIndex + 2}` : '');

        return {
            value: fuelCard.id,
            label,
            fuelcardTag: fuelCard.id ?? 'Unknown',
            licensePlate: fuelCard.licensePlate ?? 'Unknown',
            displayName: fuelCard.displayName ?? 'Unknown',
            fullName: fuelCard.name ?? 'Unknown'
        };
    });

    const customFilterOption = (option: FilterOptionOption<Option>, inputValue: string) => {
        const label = option.data.label.toLowerCase();
        const licensePlate = option.data.licensePlate.toLowerCase();
        const fuelcardTag = option.data.fuelcardTag.toLowerCase();
        const displayName = option.data.displayName.toLowerCase();
        return (
            label.includes(inputValue.toLowerCase()) ||
            licensePlate.includes(inputValue.toLowerCase()) ||
            fuelcardTag.includes(inputValue.toLowerCase()) ||
            displayName.includes(inputValue.toLowerCase())
        );
    };

    return (
        <Select
            classNamePrefix="react-select"
            className="selectDropdown"
            onChange={(selection) => {
                if (selection) {
                    props.setSelectedFuelCard(selection.value ? selection.value : '');
                    props.setSearchText(selection.fullName);
                }
            }}
            options={options}
            filterOption={customFilterOption}
            placeholder="Type here..."
            autoFocus
            onInputChange={(inputValue: string) => {
                if (inputValue.length > 0) {
                    props.setSearchText(inputValue);
                    setSearchText(inputValue);
                }
            }}
            styles={{
                placeholder: (base) => ({
                    ...base,
                    pointerEvents: 'none',
                    userSelect: 'none',
                    MozUserSelect: 'none',
                    WebkitUserSelect: 'none',
                    msUserSelect: 'none'
                }),
                input: (base) => ({
                    ...base,
                    gridTemplateColumns: '0 minmax(min-content, 1fr)'
                })
            }}
        />
    );
}
