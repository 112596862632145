import { Button, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import InformationPopover from '../../../shared/InformationPopover';
import Search from '../../../shared/icon/Search';
import { ChangeEvent } from 'react';
import { getCarOfUser, getUserDetailsForFcRegistration } from '../../../../service/Api';
import Car from '../../../../model/car/Car';
import UserDto from '../../../../model/user/UserDto';
import { isAxiosError } from 'axios';
import FuelcardFormData from '../../../../model/FuelcardFormData';
import { Variant } from 'react-bootstrap/types';
import { UseFormReturn } from 'react-hook-form';
import { MngrUserRegistrationState } from '../../../../model/MngrUserRegistrationState';

interface SearchForUserButtonProps {
    mngrUserRegistrationSearchQuery: string;
    setMngrUserRegistrationSearchQuery: (value: React.SetStateAction<string>) => void;
    setFuelCard: (value: React.SetStateAction<FuelcardFormData>) => void;
    setToast: React.Dispatch<React.SetStateAction<{ message: string; bg?: Variant; autoHide: boolean; title?: string }>>;
    setMngrUserRegistrationState: React.Dispatch<React.SetStateAction<MngrUserRegistrationState>>;
    fuelcard: FuelcardFormData;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
    setIsSearchingForUser: React.Dispatch<React.SetStateAction<boolean>>;
    isSearchingForUser: boolean;
    setHideCarRegistration: React.Dispatch<React.SetStateAction<boolean>>;
    autoFocus: boolean;
}

export default function SearchForUserButton(props: SearchForUserButtonProps) {
    const searchForUser = async () => {
        props.setIsSearchingForUser(true);
        props.setHideCarRegistration(true);
        try {
            const userForRegistration = await getUserDetailsForFcRegistration(props.mngrUserRegistrationSearchQuery);
            const user = userForRegistration.data as UserDto;
            if (user.id) {
                try {
                    const userCarResp = await getCarOfUser(user.id.toString());
                    const userCar = userCarResp.data as Car;
                    prefillUserWithCar(user, userCar);
                    props.setMngrUserRegistrationState('alreadyRegisteredWithCar');
                } catch (e) {
                    prefillUserWithoutCar(user);
                    props.setMngrUserRegistrationState('alreadyRegisteredWithoutCar');
                }
            } else {
                prefillUserWithoutCar(user);
                props.setMngrUserRegistrationState('newXploregroup');
            }
        } catch (e) {
            clearUserAndCarDetails();
            if (isAxiosError(e) && e.response?.status === 404) {
                props.setMngrUserRegistrationState('newUnknown');
            } else {
                props.setToast({
                    bg: 'danger',
                    autoHide: false,
                    title: 'Unexpected error',
                    message: 'An unexpected error occurred while trying to fetch the user details'
                });
                props.setMngrUserRegistrationState('initial');
            }
        }

        props.setIsSearchingForUser(false);
        props.setHideCarRegistration(false);
    };

    const prefillUserWithCar = (user: UserDto, userCar: Car) => {
        props.setFuelCard({
            ...props.fuelcard,
            contactEmail: user.contactEmail,
            primaryEmail: user.primaryEmail,
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile ?? '',
            licensePlate: userCar.licensePlate,
            brand: userCar.brand,
            model: userCar.model
        });
        props.methods.setValue('contactEmail', user.contactEmail);
        props.methods.setValue('primaryEmail', user.primaryEmail);
        props.methods.setValue('firstName', user.firstName);
        props.methods.setValue('lastName', user.lastName);
        props.methods.setValue('mobile', user.mobile ?? '');
        props.methods.setValue('licensePlate', userCar.licensePlate);
        props.methods.setValue('brand', userCar.brand);
        props.methods.setValue('model', userCar.model);
    };

    const prefillUserWithoutCar = (user: UserDto) => {
        props.setFuelCard({
            ...props.fuelcard,
            contactEmail: user.contactEmail,
            primaryEmail: user.primaryEmail,
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile ?? '',
            licensePlate: '',
            brand: '',
            model: ''
        });
        props.methods.setValue('contactEmail', user.contactEmail);
        props.methods.setValue('primaryEmail', user.primaryEmail);
        props.methods.setValue('firstName', user.firstName);
        props.methods.setValue('lastName', user.lastName);
        props.methods.setValue('mobile', user.mobile ?? '');
        props.methods.setValue('licensePlate', '');
        props.methods.setValue('brand', '');
        props.methods.setValue('model', '');
    };

    const clearUserAndCarDetails = () => {
        props.setFuelCard({
            ...props.fuelcard,
            contactEmail: '',
            primaryEmail: '',
            firstName: '',
            lastName: '',
            mobile: '',
            licensePlate: '',
            brand: '',
            model: ''
        });
        props.methods.setValue('contactEmail', '');
        props.methods.setValue('primaryEmail', '');
        props.methods.setValue('firstName', '');
        props.methods.setValue('lastName', '');
        props.methods.setValue('mobile', '');
        props.methods.setValue('licensePlate', '');
        props.methods.setValue('brand', '');
        props.methods.setValue('model', '');
    };

    return (
        <InputGroup className="form-field-wrapper">
            <InputGroup.Text className="fuelcardForm-input-pretext">
                Search for user
                <InformationPopover infoHeader="Search for user">
                    <p>
                        Please search for a user using preferably their cronos username (e.g. doejo@cronos.be, with or without the @cronos.be). You
                        can also search using their contact email (e.g. john.doe@xploregroup.be).
                    </p>
                </InformationPopover>
                :
            </InputGroup.Text>
            <FormControl
                className="form-control"
                autoComplete="off"
                value={props.mngrUserRegistrationSearchQuery}
                onChange={(e: ChangeEvent<HTMLInputElement>) => props.setMngrUserRegistrationSearchQuery(e.target.value)}
                placeholder="Search ..."
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        searchForUser();
                    }
                }}
                autoFocus={props.autoFocus}
            />
            <Button
                variant="outline-secondary"
                onClick={searchForUser}
                className="fuelcardForm-input-postText"
                disabled={props.isSearchingForUser || props.mngrUserRegistrationSearchQuery === ''}
            >
                {props.isSearchingForUser ? <Spinner animation="border" variant="primary" role="status" size="sm" /> : <Search />}
            </Button>
        </InputGroup>
    );
}
