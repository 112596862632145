import axiosInstance from './AxiosInstance';
import { API_URL } from '../config';
import Fuelcard from '../model/fuelcard/Fuelcard';
import NotificationToggle from '../model/NotificationToggle';
import NotificationToggleMgrLocation from '../model/NotifcationToggleMgrLocation';
import NotificationToggleMgr from '../model/NotificationToggleMgr';
import Announcement from '../model/Announcement';
import CarBrand from '../model/car/CarBrand';
import CarModel from '../model/car/CarModel';
import Car from '../model/car/Car';
import CarCreateDto from '../model/car/CarCreateDto';
import Feedback from '../model/feedback/Feedback';
import UserFuelcard from '../model/fuelcard/UserFuelcard';
import NotificationCcPreference from '../model/notification/NotificationCcPreference';
import NotificationType from '../model/NotificationType';

export const postRegisterFuelcard = async (fuelcard: Fuelcard) => await axiosInstance.post(`${API_URL}/fuelcard`, fuelcard);

export const acceptEula = async (userId: number, eulaId: number) =>
    await axiosInstance.patch(`${API_URL}/user/${userId}/eula/accept?eulaId=${eulaId}`, null);

export const getLatestEula = async () => await axiosInstance.get(`${API_URL}/eula/latest`);

export const checkFuelcard = async (number: string) => await axiosInstance.get(`${API_URL}/fuelcard/check/${number}`);

export const getFuelCardSerial = async (fuelcardId: string) => await axiosInstance.get(`${API_URL}/restricted/fuelcard/${fuelcardId}/serial`);

export const getContactEmailByFuelcard = async (fuelcardId: string) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/restricted/fuelcard/${fuelcardId}/contact`);
        return response.data;
    } catch (error) {
        console.error('Error fetching contact mail:', error);
    }
};

export const searchFuelcards = async (fuelcardSearch: string, page: number) => {
    const encodedSearch = encodeURIComponent(fuelcardSearch);
    return await axiosInstance.get(`${API_URL}/restricted/fuelcard/search?search=${encodedSearch}&page=${page}`);
};

export const getAllChargingStationLocations = async () => await axiosInstance.get(`${API_URL}/chargingstationlocation?include=false`);

export const sortSearchFuelcards = async (fuelcardSearch: string, page: number, sortingField: string, sortingDirection: string) => {
    const encodedSearch = encodeURIComponent(fuelcardSearch);
    return await axiosInstance.get(
        `${API_URL}/restricted/fuelcard/search?search=${encodedSearch}&page=${page}&sortingField=${sortingField}&sortingDirection=${sortingDirection}`
    );
};

export const getChargingStationLocations = async (location: string) =>
    await axiosInstance.get(`${API_URL}/chargingstationlocation?include=true&location=${location}`);

export const getChargingStationsOfLocation = async (location: string) => await axiosInstance.get(`${API_URL}/chargingstation/${location}`);

export const getLocations = async () => await axiosInstance.get(`${API_URL}/chargingstationlocation`);

export const getSerialOfChargingStation = async (locationName: string, nr: number) =>
    await axiosInstance.get(`${API_URL}/restricted/chargingstation/${locationName}/${nr}/serial`);

export const getNotificationToggle = async () => await axiosInstance.get(`${API_URL}/notificationtoggle`);

export const toggleNotification = async (notificationToggle: NotificationToggle) =>
    await axiosInstance.put(`${API_URL}/notificationtoggle`, notificationToggle);

export const toggleNotificationLocation = async (toggleLoction: NotificationToggleMgrLocation) =>
    await axiosInstance.put(`${API_URL}/restricted/notificationtogglemanager/location`, toggleLoction);

export const getNotificationToggleMgr = async () => await axiosInstance.get(`${API_URL}/restricted/notificationtogglemanager`);

export const toggleNotificationMgr = async (notificationToggleMgr: NotificationToggleMgr) =>
    await axiosInstance.put(`${API_URL}/restricted/notificationtogglemanager`, notificationToggleMgr);

export const startTransaction = async (location: string, nr: number, fuelcardId: string) =>
    await axiosInstance.post(`${API_URL}/restricted/transaction/${location}/${nr}/start`, { fuelCardId: fuelcardId });

export const stopTransaction = async (location: string, nr: number) =>
    await axiosInstance.put(`${API_URL}/restricted/transaction/${location}/${nr}/stop`, {});

export const getUserData = async (accessToken: string, registrationToken: string) =>
    await axiosInstance.post(`${API_URL}/user/login`, {
        accessToken: accessToken,
        registrationToken: registrationToken
    });

export const getFuelCardsOfUser = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/fuelcard/user`);

        return response.data;
    } catch (error) {
        console.error('Error fetching fuel cards:', error);
    }
};

export const getAnnouncementOfLocation = async (locationName: string): Promise<Announcement | null> =>
    await axiosInstance
        .get(`${API_URL}/announcement/location/${locationName}`)
        .then((response) => response.data)
        .catch(() => null);

export const getAnnouncements = async () => await axiosInstance.get(`${API_URL}/announcement`);

export const createAnnouncement = async (announcement: Announcement) => await axiosInstance.post(`${API_URL}/restricted/announcement`, announcement);

export const deleteAnnouncement = async (locationName: string) =>
    await axiosInstance.delete(`${API_URL}/restricted/announcement/location/${locationName}`);

export const getCarBrandNames = async () => await axiosInstance.get(`${API_URL}/car/brand`);

export const getCarModelNames = async (brandName: string) => await axiosInstance.get(`${API_URL}/car/brand/${brandName}/model`);

export const updateCarBrand = async (brand: CarBrand) => await axiosInstance.put(`${API_URL}/restricted/car/brand/${brand.id}`, brand);

export const replaceCarBrand = async (toReplace: number, replacement: number) =>
    await axiosInstance.post(`${API_URL}/restricted/car/brand/${toReplace}/replaceby/${replacement}`, null);

export const updateCarModel = async (model: CarModel) =>
    await axiosInstance.put(`${API_URL}/restricted/car/brand/${model.brand.id}/model/${model.id}`, model);

export const replaceCarModel = async (brand: number, toReplace: number, replacement: number) =>
    await axiosInstance.post(`${API_URL}/restricted/car/brand/${brand}/model/${toReplace}/replaceby/${replacement}`, null);

export const getUserOwnCar = async () => await axiosInstance.get(`${API_URL}/car/user`);

export const getCarOfUser = async (userId: string) => await axiosInstance.get(`${API_URL}/restricted/car/user/${userId}`);

export const createCar = async (car: CarCreateDto) => await axiosInstance.post(`${API_URL}/car`, car);

export const deleteCar = async (carId: number) => await axiosInstance.delete(`${API_URL}/restricted/car/${carId}`);

export const blockFuelcard = async (id: string) => await axiosInstance.patch(`${API_URL}/restricted/fuelcard/${id}/block`, null);

export const unblockFuelcard = async (id: string) => await axiosInstance.patch(`${API_URL}/restricted/fuelcard/${id}/unblock`, null);

export const getCarByLicensePlate = async (licensePlate: string) => await axiosInstance.get(`${API_URL}/car/${licensePlate}`);

export const sendTemplateNotification = async (subject: string, body: string, to: string) =>
    await axiosInstance.post(`${API_URL}/restricted/notification/template/send`, {
        subject: subject,
        body: body,
        toEmail: to
    });

export const getPoolOfUser = async () => {
    try {
        const response = await axiosInstance.get(`${API_URL}/pool/user`);
        return response.data;
    } catch (error) {
        console.error('Error fetching userPool:', error);
        throw error;
    }
};

export const sendAddRequest = async (email: string) => await axiosInstance.post(`${API_URL}/pool/${email}`, null);

export const removeUserFromPool = async (email: string) => await axiosInstance.delete(`${API_URL}/pool/${email}`);

export const acceptAddRequest = async (email: string) => await axiosInstance.post(`${API_URL}/pool/${email}/accept`, null);

export const reassignCar = async (car: Car) => await axiosInstance.patch(`${API_URL}/restricted/car/${car.licensePlate}/reassign/${car.userId}`);

export const getOpenPoolRequests = async () => await axiosInstance.get(`${API_URL}/pool/user/requests`);

export const updateCar = async (car: Car) => await axiosInstance.put(`${API_URL}/car/update`, car);

export const getUserById = async (id: number) => await axiosInstance.get(`${API_URL}/restricted/user/id/${id}`);

export const getUserByEmail = async (email: string) => await axiosInstance.get(`${API_URL}/user?email=${email}`);

export const submitFeedback = async (feedback: Feedback) => await axiosInstance.post(`${API_URL}/feedback`, feedback);

export const updateFuelcardDisplayName = async (fuelcard: UserFuelcard) =>
    await axiosInstance.put(`${API_URL}/fuelcard/user/${fuelcard.fuelcardId}`, fuelcard);

export const getFullLeaderboardScores = async (cslName: string, page: number) =>
    await axiosInstance.get(`${API_URL}/restricted/leaderboard/${cslName}/scores?page=${page}&size=50`);

export const deleteUserFuelcard = async (id: string) => await axiosInstance.delete(`${API_URL}/fuelcard/${id}`);

export const getAllCompaniesLeaderboardScores = async (cslName: string) => await axiosInstance.get(`${API_URL}/leaderboard/${cslName}/companies`);

export const getUserCompanyLeaderboardScores = async (cslName: string) => await axiosInstance.get(`${API_URL}/leaderboard/${cslName}/company`);

export const getUserPoolLeaderboardScores = async (cslName: string) => await axiosInstance.get(`${API_URL}/leaderboard/${cslName}/pool`);

export const getTopUsersLeaderboardScores = async (cslName: string) => await axiosInstance.get(`${API_URL}/leaderboard/${cslName}/top`);

export const getChargingStationsOfLocationTVMode = async (locationName: string) =>
    await axiosInstance.get(`${API_URL}/tvdashboard/chargingstation/${locationName}`, {});

export const getUserNotifCcPreferences = async (locationName: string) =>
    await axiosInstance.get(`${API_URL}/restricted/notificationccpreference/${locationName}/user`);

export const updateUserNotifCcPreferences = async (notificationCcPreference: NotificationCcPreference) =>
    await axiosInstance.put(`${API_URL}/restricted/notificationccpreference/user`, notificationCcPreference);

export const getNotificationSettings = async (cslName: string) => await axiosInstance.get(`${API_URL}/restricted/notificationsetting/${cslName}`);

export const updateNotificationSetting = async (notification: NotificationType, cslName: string) =>
    await axiosInstance.post(`${API_URL}/restricted/notificationsetting/toggle/${notification}/${cslName}`);

export const getUserDetailsForFcRegistration = async (email: string) =>
    await axiosInstance.get(`${API_URL}/restricted/fuelcard/registration/user/${encodeURIComponent(email)}`);

export const getDisabledNotifications = async () => await axiosInstance.get(`${API_URL}/restricted/notificationsetting/disabled`);

export const searchInactiveUsers = async (searchQuery: string, date: string, page: number, sortingField: string, sortingDirection: string) => {
    const encodedSearch = encodeURIComponent(searchQuery);
    const encodedDate = encodeURIComponent(date);
    return await axiosInstance.get(
        `${API_URL}/restricted/user/inactive/search?search=${encodedSearch}&date=${encodedDate}&page=${page}&sortingField=${sortingField}&sortingDirection=${sortingDirection}`
    );
};

export const deleteUser = async (contactEmail: string) => await axiosInstance.delete(`${API_URL}/restricted/user?contactEmail=${contactEmail}`);

export const getStatsOfUser = async () => await axiosInstance.get(`${API_URL}/stats/user`);

export const getAverageOccupation = async (cslName: string, dayOfWeek: number) => {
    return await axiosInstance.get(`${API_URL}/avgcsloccupation/${cslName}/${dayOfWeek}`);
};

export const getRecentlyCreatedBrands = async (page = 0, size = 10) => {
    return await axiosInstance.get(`${API_URL}/restricted/car/management/brand?page=${page}&size=${size}`);
};

export const getRecentlyCreatedModels = async (page = 0, size = 10) => {
    return await axiosInstance.get(`${API_URL}/restricted/car/management/model?page=${page}&size=${size}`);
};

export const getPoolSuggestions = async () => await axiosInstance.get(`${API_URL}/pool/suggestions`);
