import { Button } from 'react-bootstrap';
import ChargingStation from '../../../../model/ChargingStation';
import CommonDashboardProps from '../../../../props/CommonDashboardProps';
import CommonDashboardTransactionProps from '../../../../props/CommonDashboardTransactionProps';
import getStatusColours from '../../../../hooks/useGetStatusColour';
import { useState } from 'react';
import Gear from '../../../shared/icon/Gear';

interface TransactionButtonProps extends CommonDashboardProps, CommonDashboardTransactionProps {
    chargingStation: ChargingStation;
    showAdminSettings: boolean;
    setShowAdminSettings: (showAdminSettings: boolean) => void;
}

export default function TransactionButton(props: TransactionButtonProps) {
    const statusColours = getStatusColours(
        props.chargingStation.comStatus === 'DOWN' ? props.chargingStation.comStatus : props.chargingStation.ocppStatus
    );
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const buttonStyle = {
        backgroundColor: isHovered ? statusColours.border : statusColours.background,
        borderColor: statusColours.border
    };

    const handleStopTransaction = async (location: string, chargingStation: ChargingStation) => {
        props.setShowStopTransactionVerification(true);
        props.setSelectedChargingStation(chargingStation);
    };

    function createTransactionButton(chargingStation: ChargingStation, location: string) {
        const isCharging = chargingStation.ocppStatus === 'CHARGING' && chargingStation.fuelCardId !== null;

        function isStartButtonByStatus(status: string) {
            return chargingStation.comStatus !== 'DOWN' && ['AVAILABLE', 'PREPARING', 'FINISHING'].includes(status);
        }

        function isStopButtonDisabled() {
            return chargingStation.comStatus === 'DOWN' || (!isCharging && !['SUSPENDED_EVSE'].includes(chargingStation.ocppStatus));
        }

        function isStartButtonDisabled() {
            return chargingStation.comStatus === 'DOWN' || !isStartButtonByStatus(chargingStation.ocppStatus);
        }

        const stopButton = (
            <Button
                variant="secondary"
                size="sm"
                className="transactionButton"
                onClick={() => handleStopTransaction(location, chargingStation)}
                disabled={isStopButtonDisabled()}
                style={buttonStyle}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                STOP
            </Button>
        );

        const startButton = (
            <Button
                variant="secondary"
                size="sm"
                className="transactionButton"
                onClick={() => {
                    props.setShowStartTransactionModal(true);
                    props.setSelectedChargingStation(chargingStation);
                }}
                disabled={isStartButtonDisabled()}
                style={buttonStyle}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                START
            </Button>
        );

        return isStartButtonByStatus(chargingStation.ocppStatus) ? startButton : stopButton;
    }

    return (
        <div className="transactionButton">
            {createTransactionButton(props.chargingStation, props.locationName)}
            <button style={{ width: 'fit-content' }} className={'btn'} onClick={() => props.setShowAdminSettings(!props.showAdminSettings)}>
                <Gear />
            </button>
        </div>
    );
}
