import LoadingSpinner from './LoadingSpinner';

export default function LoadingSpinnerPage() {
    return (
        <div className="d-flex justify-content-center align-items-center flex-column" style={{ minHeight: '100vh' }}>
            <LoadingSpinner style={{ marginBottom: '1em' }} />
            <p style={{ color: '#645CA2' }}>loading...</p>
        </div>
    );
}
