import NotificationToggle from './NotificationToggle';
import CommonDashboardProps from '../../../../props/CommonDashboardProps';
import { isStationDown } from '../../../../service/ChargingStationFunctions';
import ChargingStation from '../../../../model/ChargingStation';
import { useState } from 'react';
import { getContactEmailByFuelcard } from '../../../../service/Api';
import SendMailFromTemplate from '../../../management/mail/SendMailFromTemplate';
import MailOpen from '../../../shared/icon/MailOpen';
import MailPlus from '../../../shared/icon/MailPlus';

interface StationNotificationProps extends CommonDashboardProps {
    chargingStation: ChargingStation;
}

export default function StationNotifications(props: StationNotificationProps) {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [contactEmail, setContactEmail] = useState<string | null>(null);

    const fetchContactEmail = () => {
        const result = getContactEmailByFuelcard(props.chargingStation.fuelCardId);
        result.then((email) => {
            setContactEmail(email);
        });
    };

    return (
        <>
            <div className="stationDownText">
                <p className="dashboard-island-notification-toggle-text">Station Down Notification</p>
            </div>
            <div className="stationDownToggle">
                <NotificationToggle {...props} csKey={props.chargingStation.key} notificationType="STATION_DOWN" />
            </div>
            <div className="stationReminderText">
                <p className="dashboard-island-notification-toggle-text">Station Down Reminder</p>
            </div>
            <div className="stationReminderToggle">
                <NotificationToggle
                    {...props}
                    csKey={props.chargingStation.key}
                    disabled={!isStationDown(props.chargingStation)}
                    notificationType="STATION_DOWN_REMINDER"
                />
            </div>
            <div className="unknownFcText">
                <p className="dashboard-island-notification-toggle-text">Unknown fuelcard</p>
            </div>
            <div className="unknownFcToggle">
                <NotificationToggle {...props} csKey={props.chargingStation.key} notificationType="UNKNOWN_FUELCARD" />
            </div>
            {props.chargingStation.ocppStatus !== 'AVAILABLE' && props.chargingStation.fuelCardId !== '' ? (
                <>
                    <div className="sendEmailDashboardText">
                        <p className="dashboard-island-notification-toggle-text">Send Email From Template</p>
                    </div>
                    <div className="sendEmailDashboard" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        {isHovered ? <MailOpen width="18" height="18" onClick={() => fetchContactEmail()} /> : <MailPlus width="18" height="18" />}
                        {contactEmail ? (
                            <SendMailFromTemplate
                                show={true}
                                setShow={(show) => {
                                    if (!show) setContactEmail(null);
                                }}
                                contactEmail={contactEmail}
                                name={props.chargingStation.fullName ?? ''}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
