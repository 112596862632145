import { Button } from 'react-bootstrap';
import { checkFuelcard } from '../../../service/Api';
import axios from 'axios';
import { UseFormReturn } from 'react-hook-form';
import FuelcardFormData from '../../../model/FuelcardFormData';
import InfoCircle from '../../shared/icon/InfoCircle';

interface ScanFuelCardButtonProps {
    setUpdates: React.Dispatch<React.SetStateAction<string>>;
    setMessageTypeNfc: React.Dispatch<React.SetStateAction<'info' | 'error' | 'success' | null>>;
    setFuelcardNumber: (serial: string) => void;
    handleResponseStatus: (
        status: number | undefined,
        defaultErrorMessage: string,
        errorMessage?: string
    ) => {
        message: string | JSX.Element;
        type: 'success' | 'danger' | 'info';
    };
    setMessage: React.Dispatch<React.SetStateAction<string | JSX.Element | null>>;
    setMessageType: React.Dispatch<React.SetStateAction<'success' | 'danger' | 'info' | null>>;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
    setShowInformationModal: (value: React.SetStateAction<boolean>) => void;
    setShowManualFallbackModal: (value: React.SetStateAction<'info' | 'warning' | null>) => void;
    setIsFcIdReadOnly: React.Dispatch<React.SetStateAction<boolean>>;
    showFallbackButton: boolean;
}

export default function ScanFuelCardButton(props: ScanFuelCardButtonProps) {
    const handleNFCScan = async () => {
        props.methods.clearErrors();
        props.setUpdates('');
        const controller = new AbortController(); // controller.abort() to stop scanning fuel card
        try {
            const ndef = new NDEFReader();
            await ndef.scan(controller);
            props.setUpdates('> Scan started');
            props.setMessageTypeNfc('info');

            ndef.addEventListener('readingerror', () => {
                props.setUpdates("Couldn't read data from the NFC tag.");
                props.setMessageTypeNfc('error');
                controller.abort();
            });

            ndef.addEventListener('reading', (event: Event) => {
                const { message, serialNumber } = event as NDEFReadingEvent;
                if (message) {
                    const formattedSerialNumber = serialNumber.replace(/[:\s]/g, '').toUpperCase(); // remove colons and whitespaces
                    props.setFuelcardNumber(formattedSerialNumber);
                    checkFuelcardNumber(formattedSerialNumber);
                    props.setUpdates('Scan successful!');
                    props.setMessageTypeNfc('success');
                }
                controller.abort();
            });
        } catch (error) {
            props.setUpdates('Could not start scan, your browser might not support WebNFC.');
            props.setMessageTypeNfc('error');
            controller.abort();
        }
    };

    const checkFuelcardNumber = async (fuelcardNumber: string) => {
        try {
            await checkFuelcard(fuelcardNumber);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const response = error.response;
                if (response?.status === 409) {
                    props.setUpdates('Fuelcard serial number has already been registered before.');
                    props.setMessageTypeNfc('info');
                }
            }
        }
    };

    return (
        <div className="button-wrapper">
            <Button type="button" onClick={handleNFCScan} className="scanFcButton">
                Scan Fuelcard
            </Button>
            <Button
                type="button"
                variant={'outline-secondary'}
                className="scanFcFallbackButton"
                onClick={() => {
                    props.setShowManualFallbackModal('info');
                    props.setIsFcIdReadOnly(false);
                    props.setUpdates('');
                }}
                hidden={!props.showFallbackButton}
            >
                Manually enter ID
            </Button>
            <Button onClick={() => props.setShowInformationModal(true)} variant="outline-secondary" className="information-icon-button">
                <InfoCircle alt="info" />
            </Button>
        </div>
    );
}
