import { Button, Modal } from 'react-bootstrap';
import { stopTransaction } from '../../../service/Api';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { AxiosResponse } from 'axios';

interface StopTransactionVerificationModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setTransactionMessage: React.Dispatch<React.SetStateAction<string>>;
    setShowMessageModal: React.Dispatch<React.SetStateAction<boolean>>;
    refetchChargingStations: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>;
    location: string;
    nr: number;
}

export default function StopTransactionVerificationModal(props: StopTransactionVerificationModalProps) {
    const handleClose = () => props.setShow(false);

    const handleStopTransaction = async () => {
        try {
            props.setShow(false);
            await stopTransaction(props.location, props.nr);
            props.refetchChargingStations();
        } catch (error) {
            props.setTransactionMessage('Could not stop transaction, something went wrong.  Please try again later');
            props.setShow(false);
            props.setShowMessageModal(true);
        }
    };

    return (
        <>
            <Modal show={props.show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Stop Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to stop this transaction?</Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" style={{ flexWrap: 'nowrap' }}>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleStopTransaction()}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
