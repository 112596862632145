import Gear from '../../shared/icon/Gear';

interface NotificationSettingsButtonProps {
    showNotificationSettings: boolean | null;
    setShowNotificationSettings: React.Dispatch<React.SetStateAction<boolean | null>>;
}

export default function NotificationSettingsButton(props: NotificationSettingsButtonProps) {
    const handleClick = () => {
        props.setShowNotificationSettings(!props.showNotificationSettings);
    };

    return (
        <button className={'notificationSettingsButtonHolder btn'} onClick={() => handleClick()}>
            notification settings <Gear />
        </button>
    );
}
