import { Button, Modal } from 'react-bootstrap';
import { Dispatch, SetStateAction } from 'react';

interface CopyOrRegisterFcIdModalProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    doCopy: VoidFunction;
    doRegistration: VoidFunction;
}

export default function CopyOrRegisterFcIdModal(props: CopyOrRegisterFcIdModalProps) {
    const handleClose = () => props.setShow(false);

    const handleRegister = () => {
        handleClose();
        props.doRegistration();
    };
    const handleCopy = () => {
        handleClose();
        props.doCopy();
    };

    return (
        <Modal show={props.show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Copy fuelcard serial number or register</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you wish to only copy the fuelcard serial number or go to the registration page to register it?</Modal.Body>
            <Modal.Footer className="d-flex justify-content-center" style={{ flexWrap: 'nowrap' }}>
                <Button variant="secondary" onClick={handleCopy}>
                    Only copy
                </Button>
                <Button variant="primary" onClick={handleRegister}>
                    Register
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
