import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { UseFormHandleSubmit, UseFormRegister, UseFormWatch } from 'react-hook-form';
import '../../../styling/notificationModalBody.css';
import ToastMessage from '../../shared/ToastMessage';
import { useState } from 'react';
import { NotificationSaveRequest } from '../../../model/NotificationSaveRequest';

interface NotificationToggleModalBodyProps {
    titleText: string;
    register: UseFormRegister<NotificationSaveRequest>;
    onSubmit: (data: any) => void;
    handleSubmit: UseFormHandleSubmit<any, undefined>;
    show: boolean;
    onHide: () => void;
    watch: UseFormWatch<NotificationSaveRequest>;
}

export default function NotificationToggleModalBody(props: NotificationToggleModalBodyProps) {
    const [showToast, setShowToast] = useState<boolean>(false);

    const watchMailEnabled = props.watch('mailEnabled', false);
    const watchPushEnabled = props.watch('pushEnabled', false);

    const requestPermission = () => {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                window.location.reload(); // Reload the page to update the permission status
            } else if (permission === 'denied') {
                setShowToast(true);
            }
        });
    };

    return (
        <>
            <ToastMessage
                message="You have blocked notifications for this browser. Please enable notifications in your browser settings."
                showToastMessage={showToast}
                setShowToastMessage={setShowToast}
            />
            <Modal show={props.show} onHide={props.onHide} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{props.titleText} Notifications</Modal.Title>
                </Modal.Header>
                <Form onSubmit={props.handleSubmit(props.onSubmit)}>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Row className="align-items-center">
                                <Col xs={6}>
                                    <Form.Label className="mb-0">Email:</Form.Label>
                                </Col>
                                <Col xs={6} className="text-end">
                                    <Form.Check type="switch" {...props.register('mailEnabled')} className="switch-toggle" />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Row className="align-items-center">
                                <Col xs={6}>
                                    <Form.Label className="mb-0">Push:</Form.Label>
                                </Col>
                                <Col xs={6} className="text-end">
                                    <Form.Check type="switch" {...props.register('pushEnabled')} className="switch-toggle" />
                                </Col>
                                {Notification !== undefined && Notification.permission !== undefined && Notification.permission !== 'granted' && (
                                    <span style={{ color: 'red', fontSize: '75%' }}>
                                        You have not given permission for push notifications for this browser.{' '}
                                        <span
                                            style={{ textDecoration: 'underline', cursor: 'pointer', color: '#1e90ff' }}
                                            onClick={requestPermission}
                                        >
                                            give permission
                                        </span>
                                    </span>
                                )}
                            </Row>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="secondary" onClick={props.onHide}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={!watchMailEnabled && !watchPushEnabled} variant="primary">
                            Enable
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
