import FuelcardFormData from '../../../model/FuelcardFormData';
import CommonFuelcardFormFieldProps from '../../../props/CommonFuelcardFormFieldProps';
import { ReactNode } from 'react';

interface FuelcardFormFieldCheckBoxProps extends CommonFuelcardFormFieldProps {
    value: boolean;
    children?: ReactNode;
    autoComplete: string;
}

export default function FuelcardFormFieldCheckBox(props: FuelcardFormFieldCheckBoxProps) {
    return (
        <div className="form-group row">
            <div className="col-sm-12" style={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                <label className="col-form-label">{props.children ? props.children : props.label}</label>
                <input
                    style={{ marginLeft: '3%' }}
                    className="form-check-input"
                    type={props.type}
                    autoComplete={props.autoComplete}
                    {...props.register(props.name as keyof FuelcardFormData, props.registerOptions)}
                    checked={props.value}
                    onChange={props.onChange}
                    readOnly={props.readOnly}
                />
                <label className="form-check-label" htmlFor={props.name}>
                    {props.placeHolder}
                </label>
            </div>
        </div>
    );
}
