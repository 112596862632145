import { Dispatch, SetStateAction, useState } from 'react';
import CopyOrRegisterFcIdModal from './CopyOrRegisterFcIdModal';
import { useNavigate } from 'react-router-dom';
import ChargingStation from '../../../../model/ChargingStation';
import { getSerialOfChargingStation } from '../../../../service/Api';
import ButtonMailto from '../../../pool/ButtonMailto';
import ChargingStationLocation from '../../../../model/ChargingStationLocation';

interface InitialsProps {
    isAdminOrManager: boolean;
    setShowToastMessage: Dispatch<SetStateAction<boolean>>;
    chargingStation: ChargingStation;
    chargingStationLocation: ChargingStationLocation;
}

export default function Initials(props: InitialsProps) {
    const [showInitials, setShowInitials] = useState(false);
    const [showFcIdModal, setShowFcIdModal] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
    const navigate = useNavigate();

    const handleHover = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
        }
        setHoverTimeout(
            setTimeout(() => {
                setShowInitials(true);
            }, 1000)
        );
    };

    const handleMouseLeave = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
        }
        setShowInitials(false);
    };

    const copyToClipboard = async () => {
        if (!props.isAdminOrManager) {
            return;
        }

        let toCopy = '';

        if (props.chargingStation.fuelCardId != null && props.chargingStation.fuelCardId !== '') {
            toCopy = props.chargingStation.fuelCardId;
        } else {
            try {
                const resp = await getSerialOfChargingStation(props.chargingStationLocation.name, props.chargingStation.nr);
                toCopy = resp.data;
            } catch (err) {
                console.error('Unable to copy text to clipboard', err);
                return;
            }
        }

        navigator.clipboard
            .writeText(toCopy)
            .then(() => {
                props.setShowToastMessage(true);
            })
            .catch((err) => {
                console.error('Unable to copy text to clipboard', err);
            });
    };

    const redirectToRegistration = () => {
        getSerialOfChargingStation(props.chargingStationLocation.name, props.chargingStation.nr).then((resp) =>
            navigate('/register', { state: { serialNumber: resp.data } })
        );
    };

    const handleInitialsClicked = () => {
        if (!props.isAdminOrManager) {
            return;
        }

        if (props.chargingStation.initials.toLowerCase() === 'unknown') {
            setShowFcIdModal(true);
        } else {
            copyToClipboard();
        }
    };

    return (
        <>
            <CopyOrRegisterFcIdModal
                show={showFcIdModal}
                setShow={setShowFcIdModal}
                doCopy={copyToClipboard}
                doRegistration={redirectToRegistration}
            />
            <div className="initials" onMouseEnter={() => handleHover()} onMouseLeave={handleMouseLeave} style={{ position: 'relative' }}>
                <p
                    className={props.chargingStation.fuelCardBlocked ? 'NoPaddingOrMargin blockedFuelCardInitials' : 'NoPaddingOrMargin'}
                    style={{ cursor: 'pointer' }}
                    onClick={handleInitialsClicked}
                >
                    {props.chargingStation.initials}
                </p>

                {showInitials &&
                    props.chargingStation.status !== 'Available' &&
                    props.chargingStation.initials !== undefined &&
                    (props.chargingStation.fullName !== undefined || props.chargingStation.phoneNumber !== undefined) && (
                        <div className="initials-hover">
                            <div className="initials-name">
                                {props.chargingStation.contactEmail !== undefined &&
                                    props.chargingStation.contactEmail !== null &&
                                    props.chargingStation.contactEmail !== '' && <ButtonMailto mail={props.chargingStation.contactEmail} />}
                                <span>
                                    &nbsp;{props.chargingStation.fullName === 'null null' ? 'Fullname not known' : props.chargingStation.fullName}
                                </span>
                            </div>
                            {props.isAdminOrManager && (
                                <>
                                    <div>{props.chargingStation.phoneNumber}</div>
                                    <div>{props.chargingStation.fuelCardBlocked ? <>Blocked&nbsp;Fuelcard</> : ''}</div>
                                </>
                            )}
                        </div>
                    )}
            </div>
        </>
    );
}
