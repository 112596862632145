import StatusColours from '../model/StatusColours';

export default function getStatusColours(status: string): StatusColours {
    switch (status) {
        case 'SUSPENDED_EV':
            return { border: 'rgba(255, 211, 47, 0.6)', background: 'rgba(255, 211, 47, 0.30)' };
        case 'AVAILABLE':
            return { border: 'rgba(0, 159, 123, 0.25)', background: 'rgba(0, 159, 123, 0.20)' };
        case 'CHARGING':
            return { border: 'rgba(130, 111, 175, 0.40)', background: 'rgba(130, 111, 175, 0.30)' };
        case 'DOWN':
        case 'FAULTED':
            return { border: 'rgba(255, 93, 104, 0.25)', background: 'rgba(255, 93, 104, 0.20)' };
        default:
            return { border: 'rgba(64, 64, 75, 0.4)', background: 'rgba(64, 64, 75, 0.3)' };
    }
}
