import { Button } from 'react-bootstrap';
import '../../styling/commonButton.css';

interface CommonButtonProps {
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    disabled: boolean;
    buttonText: string;
}

export default function CommonButton(props: CommonButtonProps) {
    const getClassName = (classname?: string) => {
        let combinedClassNames = 'common-button';
        if (classname) {
            combinedClassNames += ' ' + classname;
        }
        return combinedClassNames;
    };

    return (
        <Button className={getClassName(props.className)} variant="outline-dark" onClick={props.onClick} disabled={props.disabled}>
            {props.buttonText}
        </Button>
    );
}
