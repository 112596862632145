import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import NotificationToggleMgr from '../../../../model/NotificationToggleMgr';
import NotificationType from '../../../../model/NotificationType';
import NotificationToggleModalBody from '../../notificationToggle/NotificationToggleModalBody';
import { NotificationSaveRequest } from '../../../../model/NotificationSaveRequest';

interface CsNotificationToggleModalProps {
    show: boolean;
    onHide: () => void;
    notification: NotificationToggleMgr;
    handleToggleNotification: (toggle: NotificationToggleMgr) => Promise<void>;
}

export default function CsNotificationToggleModal(props: CsNotificationToggleModalProps) {
    const [notificationToggle, setNotificationToggle] = useState<NotificationToggleMgr>(props.notification);

    useEffect(() => {
        if (props.show) {
            setNotificationToggle(props.notification);
            reset({
                mailEnabled: !(props.notification.enabled || props.notification.pushEnabled),
                pushEnabled: !(props.notification.enabled || props.notification.pushEnabled)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, props.notification]);

    const { register, handleSubmit, reset, watch } = useForm<NotificationSaveRequest>();

    const onSubmit = (data: NotificationSaveRequest) => {
        const updatedNotification = {
            ...notificationToggle,
            enabled: data.mailEnabled,
            pushEnabled: data.pushEnabled
        };
        props.handleToggleNotification(updatedNotification);
        props.onHide();
        setNotificationToggle(updatedNotification);
    };

    function getNotificationTitleText() {
        switch (props.notification.notificationType) {
            case NotificationType.STATION_DOWN:
                return 'Station Down';
            default:
                return '';
        }
    }

    return (
        <NotificationToggleModalBody
            titleText={getNotificationTitleText()}
            register={register}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            watch={watch}
            {...props}
        />
    );
}
