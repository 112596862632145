import InformationPopover from '../../../shared/InformationPopover';

interface OccupationTimeProps {
    isAdminOrManager: boolean;
    occupationTime: string;
    chargingTime: string;
    status: string;
}

export default function OccupationTime(props: OccupationTimeProps) {
    function checkTime(occupationTime: string, chargingTime: string, status: string) {
        if (props.isAdminOrManager) {
            if (status === 'SUSPENDED_EV' || status === 'SUSPENDED_EVSE') {
                // Parse hours and minutes from the time strings
                const [chargingHours, chargingMinutes] = chargingTime.split(':').map(Number);
                const [occupationHours, occupationMinutes] = occupationTime.split(':').map(Number);

                // Calculate the time in minutes
                const chargingTotalMinutes = chargingHours * 60 + chargingMinutes;
                const occupationTotalMinutes = occupationHours * 60 + occupationMinutes;

                // Check if the time difference is greater than 2 hours (120 minutes)
                const timeDifference = occupationTotalMinutes - chargingTotalMinutes;
                const isTimeDifferenceMoreThan2Hours = timeDifference > 120;

                return isTimeDifferenceMoreThan2Hours;
            }
        }
    }

    const color = checkTime(props.occupationTime, props.chargingTime, props.status) ? '#cc0000' : 'inherit';

    return (
        <div className="occupationTime">
            <InformationPopover
                icon={
                    <svg
                        style={{ marginRight: '5%', color: color }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-clock"
                        viewBox="0 0 16 16"
                    >
                        {/* Bootstrap Icon "clock" */}
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                    </svg>
                }
                desktopPopoverStyle={{ width: 'fit-content', maxWidth: '95vw' }}
                mobilePopoverStyle={{ width: 'fit-content', maxWidth: '95vw' }}
                desktopOverLayPlacement={'bottom'}
                overlayDelay={{ show: 350, hide: 0 }}
            >
                <p>How long this station has been occupied</p>
            </InformationPopover>
            <p className="NoPaddingOrMargin biggerFontSize" style={{ color: color }}>
                {props.occupationTime}
            </p>
        </div>
    );
}
