import FuelcardFormData from '../../../../model/FuelcardFormData';
import { MngrUserRegistrationState } from '../../../../model/MngrUserRegistrationState';
import FuelcardFormField from '../FuelcardFormField';
import { ChangeEvent } from 'react';
import { SetValueConfig, UseFormReturn } from 'react-hook-form';

interface UserFoundInDatabaseInfoProps {
    fuelcard: FuelcardFormData;
    mngrUserRegistrationState: MngrUserRegistrationState;
    isAdminOrManager: boolean;
    handleChange: <K extends keyof FuelcardFormData>(
        name: K,
        value: FuelcardFormData[K],
        setValue: (name: K, value: FuelcardFormData[K], options?: Partial<SetValueConfig>) => void
    ) => void;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
}

export default function UserFoundInDatabaseInfo(props: UserFoundInDatabaseInfoProps) {
    return (
        <div className={'mb-1'}>
            <p>User found in our database:</p>

            <p className={'uar-user-preview'}>
                {props.fuelcard.firstName} {props.fuelcard.lastName}{' '}
            </p>
            <p className={'uar-user-preview'}>
                {' '}
                {props.fuelcard.contactEmail} {props.fuelcard.primaryEmail} {props.fuelcard.mobile}
            </p>
            <p className={'uar-car-previs'} hidden={props.mngrUserRegistrationState === 'alreadyRegisteredWithoutCar'}>
                {props.fuelcard.model} {props.fuelcard.brand} {props.fuelcard.licensePlate}
            </p>

            <p hidden={props.mngrUserRegistrationState === 'alreadyRegisteredWithoutCar'}>
                If you wish to update the car or the user, please do so via the fuelcard search page after registering this fuelcard.
            </p>

            {props.fuelcard.mobile == null || props.fuelcard.mobile === '' ? (
                <FuelcardFormField
                    label="Mobile"
                    type="text"
                    name="mobile"
                    autoComplete="tel"
                    value={props.fuelcard.mobile}
                    readOnly={!props.isAdminOrManager}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange('mobile', e.target.value, props.methods.setValue)}
                    register={props.methods.register}
                    registerOptions={{
                        required: 'Mobile is required.',
                        pattern: {
                            value: /^\+?\d{9,13}$/,
                            message: 'Valid formats: "0468330850" or "+32468330850" or "0032468330850"'
                        }
                    }}
                />
            ) : (
                <></>
            )}
        </div>
    );
}
