import '../../styling/ApiError.css';
import { ReactElement, ReactNode } from 'react';
import NavigationBar from './NavigationBar';
import Footer from './Footer';

interface TemplateProps {
    children?: ReactNode[] | ReactNode | ReactElement | ReactElement[];
    includeHeader?: boolean;
    className?: string;
}

export default function PageTemplate(props: TemplateProps) {
    const includeHeader = props.includeHeader ?? true;

    return (
        <>
            <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                {includeHeader ? <NavigationBar /> : <></>}
                <div className={props.className}>{props.children}</div>
            </div>
            <Footer />
        </>
    );
}
