import StatusColours from '../../../model/StatusColours';
import { mapStatus } from '../../../service/StatusMapper';

interface StatusProps {
    status: string;
    getStatusColours: (status: string) => StatusColours;
}

export default function Status(props: StatusProps) {
    return (
        <div
            className="status center-grid-text"
            style={{
                border: '1px solid black',
                borderColor: props.getStatusColours(props.status).border,
                backgroundColor: props.getStatusColours(props.status).background
            }}
        >
            <p className="NoPaddingOrMargin">{mapStatus(props.status)}</p>
        </div>
    );
}
