import { useEffect, useState } from 'react';
import UserLogin from '../model/user/UserLogin';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import Cookies from 'js-cookie';

export default function useGetUserInfo() {
    const navigate = useNavigate();
    const location = useLocation();
    const [userLoginData] = useState<UserLogin>(() => {
        // Initialize userLoginData from localStorage when the component mounts
        const storedUserInfo = localStorage.getItem('userInfo');
        return storedUserInfo ? JSON.parse(storedUserInfo) : { id: 0, email: '', phoneNumber: '', firstName: '', surname: '', role: 'USER' };
    });
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const allowedPaths = ['/login'];

        if (Cookies.get('token') === undefined || localStorage.getItem('userInfo') === null) {
            if (!allowedPaths.includes(location.pathname)) {
                navigate('/login');
            }
        }
    }, [isAuthenticated, location.pathname, navigate]);

    return userLoginData;
}
