import { FC, ReactNode } from 'react';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';

interface InformationModalProps {
    show: boolean;
    onHide: () => void;
}

interface LinkProps {
    id: string;
    title: string;
    children: ReactNode;
}

export default function InformationModal(props: InformationModalProps) {
    const Link: FC<LinkProps> = ({ id, children, title }) => (
        <OverlayTrigger
            overlay={
                <Popover id={id} style={{ padding: '1em' }}>
                    {title}
                </Popover>
            }
        >
            <span style={{ textDecoration: 'underline' }}>{children}</span>
        </OverlayTrigger>
    );

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>How to scan fuelcard using NFC</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'left' }}>
                <ol style={{ listStylePosition: 'inside', padding: '0' }}>
                    <li>
                        Access this page on an Android device using a{' '}
                        <Link title="Chrome Android, Opera Android, Samsung Internet or WebView Android." id="t-1">
                            supported browser
                        </Link>{' '}
                        for NFC scanning.
                    </li>
                    <li>Press the "Scan Fuelcard" button.</li>
                    <li>When prompted, grant permission for NFC scanning.</li>
                    <li>Wait for the "{'>'} Scan started" message.</li>
                    <li>Place your fuelcard against the back of your device.</li>
                    <li>Once scanned, the fuelcard serial number will be automatically inserted in the fuelcard serial number text field.</li>
                </ol>
            </Modal.Body>
        </Modal>
    );
}
