import { useCallback, useEffect, useRef, useState } from 'react';
import { searchFuelcards, sortSearchFuelcards } from '../../service/Api';
import '../../styling/fuelcardSearch.css';
import Footer from '../shared/Footer';
import NavigationBar from '../shared/NavigationBar';
import useCheckIfAdminOrManager from '../../hooks/useCheckIfAdminOrManager';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, InputGroup } from 'react-bootstrap';
import SearchFuelcardResult from './SearchFuelcardResult';
import ToastMessage from '../shared/ToastMessage';
import PaginationButtons from '../shared/PaginationButtons';
import { AxiosResponse, isAxiosError } from 'axios';
import SessionExpiredErrorMessage from '../shared/SessionExpiredErrorMessage';
import StartTransactionSearchPageModal from './StartTransactionSearchPageModal';
import MessageModal from '../dashboard/MessageModal';
import SearchFuelcard from '../../model/fuelcard/SearchFuelcard';
import Page from '../../model/Page';
import ChargingStationLocation from '../../model/ChargingStationLocation';
import SortingOption from './SortingOption';
import LoadingSpinner from '../shared/LoadingSpinner';
import ApiError from '../shared/ApiError';

export default function SearchFuelcardPage() {
    const location = useLocation();
    const isAdminOrManager = useCheckIfAdminOrManager();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAdminOrManager) {
            navigate('/dashboard');
        }
    }, [isAdminOrManager, navigate]);

    const [page, setPage] = useState<Page>({
        currentPage: 0,
        totalPages: 0,
        isEmpty: true
    });
    const [searchResult, setSearchResult] = useState<SearchFuelcard[]>([]);
    const [queryState, setQueryState] = useState<string>('initial');
    const [changingPage, setChangingPage] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [sessionExpired, setSessionExpired] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>(location.state?.searchText || '');
    const [lastSearch, setLastSearch] = useState<string>('');
    const [showStartTransactionModal, setShowStartTransactionModal] = useState<boolean>(false);
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
    const [transactionMessage, setTransactionMessage] = useState<string>('');
    const [selectedFuelCard, setSelectedFuelCard] = useState<SearchFuelcard>({
        id: '',
        serialNumber: '',
        displayName: '',
        isBlocked: false,
        lastUsed: new Date(),
        lastModificationDate: new Date(),
        userId: -1,
        contactEmail: '',
        primaryEmail: '',
        mobile: '',
        name: '',
        carId: -1,
        licensePlate: '',
        modelAndBrand: ''
    });
    const [chargingStationLocations, setChargingStationLocations] = useState<ChargingStationLocation[]>([]);
    const [sortingOption, setSortingOption] = useState<SortingOption>({ field: 'name', type: 'ASC' });

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const handleSuccessfulResponse = useCallback((resp: AxiosResponse<any, any>) => {
        setSearchResult(resp.data.content);
        setPage({
            currentPage: resp.data.number,
            totalPages: resp.data.totalPages,
            isEmpty: resp.data.totalElements === 0
        });
        setQueryState('success');
    }, []);

    const handleErrorResponse = useCallback((e: any) => {
        if (isAxiosError(e) && (e.response?.status === 403 || e.response?.status === 401)) {
            setSessionExpired(true);
        } else {
            setError(e);
        }
        setQueryState('error');
    }, []);

    const doSearch = useCallback(
        (query: string, localPage: Page) => {
            if (query.length === 0) {
                setQueryState('initial');
                setSearchResult([]);
                setPage({
                    currentPage: 0,
                    totalPages: 0,
                    isEmpty: true
                });
                return;
            }

            setQueryState('loading');

            const isNewSearch = query !== lastSearch;
            if (isNewSearch) {
                setLastSearch(query);
                setSearchTerm(query);
            }
            searchFuelcards(query, isNewSearch ? 0 : localPage.currentPage)
                .then(handleSuccessfulResponse)
                .catch(handleErrorResponse);
        },
        [handleSuccessfulResponse, handleErrorResponse, lastSearch]
    );

    const debouncedSearch = useCallback(
        (query: string) => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            setQueryState('loading');
            debounceTimeout.current = setTimeout(() => {
                doSearch(query, page);
            }, 500);
        },
        [doSearch, page]
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };

    const changePage = useCallback(
        (newPage: Page) => {
            setChangingPage(true);
            sortSearchFuelcards(searchTerm, newPage.currentPage, sortingOption.field, sortingOption.type)
                .then((resp) => {
                    handleSuccessfulResponse(resp);
                    setPage(newPage);
                    setChangingPage(false);
                })
                .catch(handleErrorResponse);
        },
        [searchTerm, sortingOption, handleSuccessfulResponse, handleErrorResponse]
    );

    if (queryState === 'initial' && searchTerm !== '') {
        debouncedSearch(searchTerm);
    }
    return (
        <>
            <StartTransactionSearchPageModal
                show={showStartTransactionModal}
                onHide={() => setShowStartTransactionModal(false)}
                location={{ name: location.state?.location || '', displayName: location.state?.displayName || '' }}
                chargingStationNr={location.state?.nr || 1}
                setShowStartTransactionModal={setShowStartTransactionModal}
                fuelCard={selectedFuelCard}
                chargingStationLocations={chargingStationLocations}
                setChargingStationLocations={setChargingStationLocations}
                setTransactionMessage={setTransactionMessage}
                setShowMessage={setShowMessageModal}
            />
            <MessageModal show={showMessageModal} onHide={() => setShowMessageModal(false)} message={transactionMessage} staticBackdrop={true} />
            <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                <NavigationBar />
                <ToastMessage message="Copied fuel card to clipboard." showToastMessage={showToast} setShowToastMessage={setShowToast} />
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        doSearch(searchTerm, page);
                    }}
                >
                    <h2 hidden={queryState !== 'initial'}>Start typing to search:</h2>
                    <InputGroup className="mb-3">
                        <Form.Control
                            aria-label="Fuelcard id"
                            placeholder="Search term"
                            value={searchTerm}
                            autoFocus
                            onChange={handleSearchChange}
                            onPaste={(e) => {
                                debouncedSearch(e.clipboardData.getData('Text'));
                            }}
                        />
                    </InputGroup>
                </Form>
                <div className="fuelcard-search-table-holder" style={{ height: page.totalPages === 1 ? '82.5vh' : '77.5vh' }}>
                    {queryState === 'loading' && (
                        <>
                            <LoadingSpinner />
                            <p style={{ textAlign: 'center', marginTop: '1%' }}>Fetching fuelcards ...</p>
                        </>
                    )}
                    {queryState === 'error' && (
                        <div style={{ margin: 'auto', textAlign: 'center', width: '90%' }}>
                            {sessionExpired ? (
                                <SessionExpiredErrorMessage />
                            ) : (
                                <ApiError
                                    error={error}
                                    title={<p>Something went wrong while trying to search for '{searchTerm}'</p>}
                                    message={isAxiosError(error) ? (error.response?.data?.detail ?? undefined) : undefined}
                                />
                            )}
                        </div>
                    )}
                    {queryState === 'success' && (
                        <SearchFuelcardResult
                            fuelcards={searchResult}
                            setShowToastMessage={setShowToast}
                            setShowStartTransactionModal={setShowStartTransactionModal}
                            setSelectedFuelCard={setSelectedFuelCard}
                            setSearchResult={setSearchResult}
                            searchTerm={searchTerm}
                            page={page}
                            setPage={setPage}
                            setSortingOption={setSortingOption}
                        />
                    )}
                </div>
                {queryState === 'success' && <PaginationButtons setPage={(newPage) => changePage(newPage)} page={page} changingPage={changingPage} />}
            </div>
            <Footer />
        </>
    );
}
