import { DOCUMENT_TITLE } from '../../config';
import '../../styling/footer.css';

export default function Footer() {
    return (
        <div className="footer">
            <p>
                {DOCUMENT_TITLE} - v{process.env.REACT_APP_VERSION}
            </p>
        </div>
    );
}
