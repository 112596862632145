import UserPool from '../../model/UserPool';
import ButtonMailto from './ButtonMailto';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import ConfirmRemoveUserFromPool from './ConfirmRemoveUserFromPool';
import { acceptAddRequest, removeUserFromPool } from '../../service/Api';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { isAxiosError } from 'axios';
import Check from '../shared/icon/Check';
import Cross from '../shared/icon/Cross';
import Delete from '../shared/icon/Delete';
import ChargingStation from '../../model/ChargingStation';
import getStatusColours from '../../hooks/useGetStatusColour';
import StatusColours from '../../model/StatusColours';
import InformationPopover from '../shared/InformationPopover';
import { mapStatus } from '../../service/StatusMapper';
import useGetUserInfo from '../../hooks/useGetUserInfo';

interface PoolItemProps {
    userPoolItem: UserPool;
    setToastMessage: (message: string) => void;
    refetchPool: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<UserPool[], unknown>>;
    chargingStation: ChargingStation | undefined;
    isAllCslsLoading: boolean;
    location: string | undefined;
}

export default function PoolItem(props: PoolItemProps) {
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const userName = useGetUserInfo().firstName;
    const doRemoveUserFromPool = () => {
        removeUserFromPool(props.userPoolItem.email)
            .then(() => {
                props.setToastMessage(props.userPoolItem.name + ' removed successfully');
                props.refetchPool();
            })
            .catch((err) => {
                console.error(err);
                if (isAxiosError(err) && err.response?.data?.detail) {
                    props.setToastMessage(err.response.data.detail);
                } else {
                    props.setToastMessage('Something unexpected went wrong, please try again later.');
                }
            });
    };

    const handleAccept = () => {
        acceptAddRequest(props.userPoolItem.email)
            .then(() => {
                props.setToastMessage(props.userPoolItem.name + ' accepted successfully');
                props.refetchPool();
            })
            .catch((err) => {
                console.error(err);
                if (isAxiosError(err) && err.response?.data?.detail) {
                    props.setToastMessage(err.response.data.detail);
                } else {
                    props.setToastMessage('Something unexpected went wrong, please try again later.');
                }
            });
    };

    function getChargingSessionStatus(cs: ChargingStation) {
        if (cs.ocppStatus === 'SUSPENDED_EV') {
            return 'Fully Charged';
        } else {
            return 'Charging';
        }
    }

    function getColours(cs: ChargingStation | undefined): StatusColours {
        if (cs && cs.ocppStatus === 'SUSPENDED_EV') {
            return getStatusColours(cs.ocppStatus);
        } else if (cs) {
            return getStatusColours('CHARGING');
        } else {
            return { border: 'rgba(128, 128, 128, 0.8)', background: 'rgba(128, 128, 128, 0.6)' }; // NOT CHARGING
        }
    }

    const statusText = (
        <div
            className="pool-item--status-text"
            data-testid={`status-text-test-id-${props.userPoolItem.id}`}
            style={{ borderColor: getColours(props.chargingStation).border, backgroundColor: getColours(props.chargingStation).background }}
        >
            {props.chargingStation === undefined ? 'Not Charging' : getChargingSessionStatus(props.chargingStation)}
        </div>
    );

    return (
        <>
            {showConfirm ? (
                <ConfirmRemoveUserFromPool
                    show={showConfirm}
                    setShow={setShowConfirm}
                    doRemove={doRemoveUserFromPool}
                    userPoolItem={props.userPoolItem}
                />
            ) : (
                <></>
            )}

            <div className="pool-item" data-testid={`test-id-${props.userPoolItem.id}`} key={props.userPoolItem.name}>
                {props.userPoolItem.poolStatus === 'ACTIVE' &&
                    !props.isAllCslsLoading &&
                    (props.chargingStation ? (
                        <InformationPopover
                            icon={statusText}
                            desktopOverLayPlacement={'bottom'}
                            desktopPopoverStyle={{ width: 'fit-content' }}
                            mobilePopoverStyle={{ width: 'fit-content' }}
                            trigger={'hover'}
                        >
                            <div className="status-admin-popover">
                                <p>
                                    <span className={'status-admin-popover-key'}>Location: </span>
                                    <span className={'status-admin-popover-value'}>
                                        {props.location} #{props.chargingStation?.nr}
                                    </span>
                                </p>
                                <p>
                                    <span className={'status-admin-popover-key'}>Status: </span>
                                    <span className={'status-admin-popover-value'}>{mapStatus(props.chargingStation?.ocppStatus)}</span>
                                </p>
                                <p>
                                    <span className={'status-admin-popover-key'}>Charging Time: </span>
                                    <span className={'status-admin-popover-value'}>{props.chargingStation?.chargingTime}</span>
                                </p>
                                <p>
                                    <span className={'status-admin-popover-key'}>Occupation Time: </span>
                                    <span className={'status-admin-popover-value'}>{props.chargingStation?.occupationTime}</span>
                                </p>
                            </div>
                        </InformationPopover>
                    ) : (
                        statusText
                    ))}
                <div className="pool-item-flex-container">
                    <div className="pool-item-content">
                        <p>
                            {props.userPoolItem.name}
                            {props.userPoolItem.poolStatus === 'ACTIVE' && (
                                <span data-testid={`mail-button-test-id-${props.userPoolItem.id}`}>
                                    {' '}
                                    <ButtonMailto mail={`${props.userPoolItem.email}`} />
                                </span>
                            )}
                        </p>
                        {props.userPoolItem.poolStatus === 'REQUESTING' ? (
                            <p className="pool-item-content-subtext" style={{ marginTop: '0' }}>
                                Awaiting response{' '}
                                <span data-testid={`request-reminder-button-test-id-${props.userPoolItem.id}`}>
                                    <ButtonMailto
                                        mail={`${props.userPoolItem.email}`}
                                        body={`Hey${props.userPoolItem.name === undefined ? '' : ' ' + props.userPoolItem.name}, 
            
I would like to remind you to handle my pool request.

Thanks in advance,
${userName}`}
                                        subject="Car Charging Pool Reminder"
                                    />
                                </span>
                            </p>
                        ) : (
                            <></>
                        )}
                        {props.userPoolItem.poolStatus === 'REQUESTED' ? (
                            <p className="pool-item-content-subtext" style={{ marginTop: '0' }}>
                                Pending request{' '}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="pool-item-buttons">
                        {props.userPoolItem.poolStatus === 'REQUESTED' ? (
                            <Button size="sm" variant="success" onClick={() => handleAccept()}>
                                <Check data-testid={`check-button-test-id-${props.userPoolItem.id}`} alt={'Accept into pool'} />
                            </Button>
                        ) : (
                            <></>
                        )}

                        <Button size="sm" variant="danger" onClick={() => setShowConfirm(true)}>
                            {props.userPoolItem.poolStatus === 'REQUESTED' ? (
                                <Cross data-testid={`cross-button-test-id-${props.userPoolItem.id}`} alt="Decline request" />
                            ) : (
                                <Delete data-testid={`delete-button-test-id-${props.userPoolItem.id}`} alt="Remove user from pool" />
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
