import PageTemplate from '../shared/PageTemplate';
import '../../styling/chargingHistoryPage.css';
import { useQuery } from 'react-query';
import { getStatsOfUser } from '../../service/Api';
import LoadingSpinner from '../shared/LoadingSpinner';
import ApiError from '../shared/ApiError';
import { useEffect, useState } from 'react';
import UserStats from '../../model/stats/UserStats';
import { Card, Col } from 'react-bootstrap';
import moment, { Duration } from 'moment-timezone';
import ArrowUp from '../shared/icon/ArrowUp';
import Clock from '../shared/icon/Clock';
import InformationPopover from '../shared/InformationPopover';
import UserStatsTable from './UserStatsTable';

export default function ChargingHistoryPage() {
    const [userStats, setUserStats] = useState<UserStats[]>([]);
    const [averages, setAverages] = useState<{
        averageChargingTime: Duration;
        averageOccupationTime: Duration;
        averageKWh: number;
        averageMoveTime: Duration;
    }>({
        averageChargingTime: moment.duration(),
        averageOccupationTime: moment.duration(),
        averageKWh: 0,
        averageMoveTime: moment.duration()
    });

    const {
        data: userStatsData,
        isLoading: isUserStatsDataLoading,
        error: userStatsDataError
    } = useQuery('allCsls', () => getStatsOfUser(), {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (isUserStatsDataLoading || userStatsDataError || !userStatsData) {
            setUserStats([]);
            return;
        }

        if (userStatsData.data) {
            const cleanedStats = userStatsData.data.filter((stat: any) => stat.endDate != null) as UserStats[];

            setUserStats(cleanedStats);
            setAverages(calculateAverages(cleanedStats));
        } else {
            setUserStats([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserStatsDataLoading, userStatsData, userStatsDataError]);

    const calculateAverages = (userStatsArray: UserStats[]) => {
        let totalChargingTime = 0;
        let totalOccupationTime = 0;
        let totalMoveTime = 0;
        let totalKWh = 0;

        userStatsArray.forEach((stats) => {
            const chargingTimeInMinutes = moment.duration(stats.chargingTime).asMinutes();
            const occupationTimeInMinutes = moment.duration(stats.occupationTime).asMinutes();

            totalChargingTime += chargingTimeInMinutes;
            totalOccupationTime += occupationTimeInMinutes;
            totalMoveTime += occupationTimeInMinutes - chargingTimeInMinutes;
            totalKWh += stats.kWh;
        });

        const averageChargingTime = totalChargingTime / userStatsArray.length;
        const averageOccupationTime = totalOccupationTime / userStatsArray.length;
        const averageMoveTime = totalMoveTime / userStatsArray.length;
        const averageKWh = totalKWh / userStatsArray.length;

        return {
            averageChargingTime: moment.duration(averageChargingTime, 'minutes'),
            averageOccupationTime: moment.duration(averageOccupationTime, 'minutes'),
            averageMoveTime: moment.duration(averageMoveTime, 'minutes'),
            averageKWh
        };
    };

    if (isUserStatsDataLoading) {
        return (
            <PageTemplate className="chargingHistoryPage">
                <LoadingSpinner />
            </PageTemplate>
        );
    } else if (userStatsDataError) {
        return (
            <PageTemplate className="chargingHistoryPage">
                <ApiError title={'Something went wrong while fetching your charging history'} error={userStatsDataError} />
            </PageTemplate>
        );
    } else if (userStats.length === 0) {
        return (
            <PageTemplate className="chargingHistoryPage">
                <h1>Charging History</h1>
                <p>We do not have any charging history for you at any of our locations.</p>
            </PageTemplate>
        );
    }

    return (
        <PageTemplate className="chargingHistoryPage">
            <h1 className="text-center h2">Charging Session History</h1>
            <p className="text-center text-muted">
                On this page you can view the history of your charging sessions at our offices in the past month.
            </p>

            <div className="row mt-4">
                <Col sm={6} md={4} lg={3} className="mb-4">
                    <Card className="text-center shadow">
                        <Card.Body>
                            <Card.Title>
                                Average Charge
                                <InformationPopover infoHeader="Average Charge" mobilePxBreakPoint={99999}>
                                    <p>The amount of electricity you charged on average</p>
                                </InformationPopover>
                            </Card.Title>
                            <Card.Text>
                                <ArrowUp /> {Math.round(averages.averageKWh * 100) / 100} kWh
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={6} md={4} lg={3} className="mb-4">
                    <Card className="text-center shadow">
                        <Card.Body>
                            <Card.Title>
                                Average Charging Time
                                <InformationPopover infoHeader="Average Charging Time" mobilePxBreakPoint={99999}>
                                    <p>The amount of time on average you spent charging</p>
                                </InformationPopover>
                            </Card.Title>
                            <Card.Text>
                                <Clock /> {averages.averageChargingTime.hours()} hour(s) {averages.averageChargingTime.minutes()} minute(s)
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6} md={4} lg={3} className="mb-4">
                    <Card className="text-center shadow">
                        <Card.Body>
                            <Card.Title>
                                Average Occupation Time
                                <InformationPopover infoHeader="Average Occupation Time" mobilePxBreakPoint={99999}>
                                    <p>The amount of time your car is occupying a charging station on average</p>
                                </InformationPopover>
                            </Card.Title>
                            <Card.Text>
                                <Clock /> {averages.averageOccupationTime.hours()} hour(s) {averages.averageOccupationTime.minutes()} minute(s)
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={6} md={4} lg={3} className="mb-4">
                    <Card className="text-center shadow">
                        <Card.Body>
                            <Card.Title>
                                Average Move Time
                                <InformationPopover infoHeader="Average Move Time" mobilePxBreakPoint={99999}>
                                    <p>How long your car remains connected to the charging station after it has finished charging on average</p>
                                </InformationPopover>
                            </Card.Title>
                            <Card.Text
                                style={
                                    averages.averageMoveTime.asMinutes() > 30
                                        ? { color: 'var(--bs-xploregroup-red)' }
                                        : { color: 'var(--bs-xploregroup-green)' }
                                }
                            >
                                <Clock /> {averages.averageMoveTime.hours()} hour(s) {averages.averageMoveTime.minutes()} minute(s)
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </div>

            <UserStatsTable userStats={userStats} />
        </PageTemplate>
    );
}
