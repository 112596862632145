import DashboardFilterButton from './DashboardFilterButton';
import useCheckIfAdminOrManagerForLocation from '../../../hooks/useCheckIfAdminOrManagerForLocation';
import CommonDropdownButton from '../../shared/CommonDropdownButton';
import { Dropdown } from 'react-bootstrap';
import ChargingStation from '../../../model/ChargingStation';
import '../../../styling/switchButtonContainer.css';
import UserFuelcard from '../../../model/fuelcard/UserFuelcard';
import ChargingStationLocation from '../../../model/ChargingStationLocation';

interface SwitchButtonsContainerProps {
    selectedLocation: ChargingStationLocation;
    setSelectedLocation: (csl: ChargingStationLocation) => void;
    locationOptions: ChargingStationLocation[];
    chargingStations: ChargingStation[];
    userFuelcards: UserFuelcard[];
    showFilter: boolean;
    filter: string | null;
    setFilter: (filter: string | null) => void;
    isUserCharging: boolean;
}

export default function SwitchButtonsContainer(props: SwitchButtonsContainerProps) {
    const isAdminOrManager = useCheckIfAdminOrManagerForLocation(props.selectedLocation.name);

    return (
        <div className="switchButtonContainer align-self-center d-flex flex-row flex-wrap justify-content-center">
            <div style={{ width: 'fit-content', margin: '0.2vh 0.5vw' }}>
                <CommonDropdownButton title={'Select location'} dropDownChild={"Showing '" + props.selectedLocation.displayName + "'"}>
                    {props.locationOptions.map((csl) => (
                        <Dropdown.Item onClick={() => props.setSelectedLocation(csl)} key={'selectLocationItem-' + csl.key}>
                            Show {csl.displayName}
                        </Dropdown.Item>
                    ))}
                </CommonDropdownButton>
            </div>
            <div style={{ width: 'fit-content', margin: '0.2vh 0.5vw' }} hidden={!props.showFilter}>
                <DashboardFilterButton isAdminOrManager={isAdminOrManager} {...props} />
            </div>
        </div>
    );
}
