interface InitialsProps {
    initials: string;
}

export default function Initials(props: InitialsProps) {
    return (
        <div className="initials" style={{ position: 'relative' }}>
            <p className="NoPaddingOrMargin">{props.initials}</p>
        </div>
    );
}
