import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CSSProperties, ReactElement, ReactNode } from 'react';
import { Placement } from 'react-bootstrap/types';
import { OverlayDelay, OverlayTriggerType } from 'react-bootstrap/OverlayTrigger';
import useWindowSize from '../../hooks/useWindowsSize';
import InfoCircle from './icon/InfoCircle';

interface InformationPopoverProps {
    children: ReactNode;
    infoHeader?: ReactNode;
    mobilePxBreakPoint?: number;
    desktopOverLayPlacement?: Placement;
    mobileOverLayPlacement?: Placement;
    desktopPopoverStyle?: CSSProperties;
    mobilePopoverStyle?: CSSProperties;
    popoverBodyStyle?: CSSProperties;
    iconStyle?: CSSProperties;
    icon?: ReactElement;
    trigger?: OverlayTriggerType | OverlayTriggerType[];
    overlayDelay?: OverlayDelay;
}

export default function InformationPopover(props: InformationPopoverProps) {
    const windowSize = useWindowSize();
    const defaultMobileBreakPointPx = 750;

    const getPlacement = (width: number): Placement => {
        const breakpoint = props.mobilePxBreakPoint ?? defaultMobileBreakPointPx;

        if (width > breakpoint) {
            return props.desktopOverLayPlacement ? props.desktopOverLayPlacement : 'right';
        } else {
            return props.mobileOverLayPlacement ? props.mobileOverLayPlacement : 'bottom';
        }
    };

    const getPopOverStyle = (width: number): CSSProperties => {
        const breakpoint = props.mobilePxBreakPoint ?? defaultMobileBreakPointPx;

        let style = {};
        if (width > breakpoint) {
            style = props.desktopPopoverStyle ? props.desktopPopoverStyle : { width: '100%', maxWidth: '37vw' };
        } else {
            style = props.mobilePopoverStyle ? props.mobilePopoverStyle : { width: '100%', maxWidth: '95vw' };
        }
        return {
            ...style,
            position: 'fixed'
        };
    };

    return (
        <OverlayTrigger
            placement={getPlacement(windowSize.width)}
            trigger={props.trigger ?? ['focus', 'click', 'hover']}
            delay={props.overlayDelay ?? { show: 100, hide: 100 }}
            overlay={
                <Popover style={getPopOverStyle(windowSize.width)}>
                    {props.infoHeader ? <Popover.Header>{props.infoHeader}</Popover.Header> : <></>}
                    <Popover.Body style={props.popoverBodyStyle ?? { paddingBottom: '0' }}>{props.children}</Popover.Body>
                </Popover>
            }
        >
            {props.icon ?? (
                <span>
                    <InfoCircle style={props.iconStyle ? props.iconStyle : { margin: '0.25em' }} alt="Hover to show info" />
                </span>
            )}
        </OverlayTrigger>
    );
}
