import { Alert, Button, Modal } from 'react-bootstrap';
import getUserAgent from '../../../../hooks/useGetUserAgent';

interface InformationModalProps {
    show: 'info' | 'warning' | null;
    onHide: () => void;
    onIgnoreWarning: () => void;
}

export default function ManualFallbackModal(props: InformationModalProps) {
    const userAgent = getUserAgent();

    return (
        <Modal
            onHide={props.onHide}
            show={props.show !== null}
            size="lg"
            fullscreen={'md-down'}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>WebNFC might not be supported in your browser</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
                {props.show === 'warning' ? (
                    <>
                        <Alert variant="danger">The fuelcard serial number is not written anywhere on the fuelcard.</Alert>
                        <p>
                            It appears you entered the id that is written on the fuelcard itself. This is not the serial number we need. Please read
                            the instructions below on how to obtain the correct serial number.
                        </p>
                    </>
                ) : (
                    <></>
                )}
                <p>
                    This webpage uses{' '}
                    <a href="https://developer.mozilla.org/en-US/docs/Web/API/Web_NFC_API" rel="noopener" target="_blank">
                        WebNFC
                    </a>{' '}
                    to scan fuelcards. This is only supported by Chrome for Android, Opera for Android, Samsung internet and WebView android.
                </p>
                <p>
                    If you are not using one of these browsers but your device does support NFC scanning you can use an NFC scanner tool like NFC
                    tools app (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        onClick={props.onHide}
                        href="https://play.google.com/store/apps/details?id=com.wakdev.wdnfc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    >
                        for android
                    </a>{' '}
                    or{' '}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        onClick={props.onHide}
                        href="https://apps.apple.com/us/app/nfc-tools/id1252962749?itsct=apps_box_badge&amp;itscg=30200"
                    >
                        for iPhone
                    </a>
                    ) to scan your fuelcard.
                </p>

                {userAgent?.getDevice().vendor?.toLowerCase() === 'apple' ? (
                    <Alert>
                        <p>
                            It appears you are using an Apple device. While Apple devices have had the hardware to scan NFC tags for a while, in iOS
                            versions prior to 18.1 you cannot use this hardware to scan NFC tags. (See{' '}
                            <a
                                href="https://developer.apple.com/support/nfc-se-platform/#:~:text=iOS%2018.1%20will%20introduce%20APIs,government%20IDs%20to%20be%20available"
                                rel="noopener"
                                target="_blank"
                            >
                                Apple developer blog
                            </a>
                            )
                        </p>
                        <p>
                            If you are not using iOS 18.1 or later you must ask someone else (with an Android device) to scan your fuelcard for you.
                        </p>
                    </Alert>
                ) : (
                    <></>
                )}

                <Alert variant="warning">
                    The Fuelcard serial number is not written on the fuelcard, it can only be obtained by scanning your fuelcard with an NFC reader.
                </Alert>

                {props.show === 'warning' ? (
                    <Button variant="primary" onClick={props.onHide} style={{ width: 'fit-content' }}>
                        Change fuelcard serial number
                    </Button>
                ) : (
                    <Button variant="outline-secondary" onClick={props.onHide} style={{ width: 'fit-content' }}>
                        I have scanned my fuelcard using an NFC scanner and am ready to enter it
                    </Button>
                )}
            </Modal.Body>
        </Modal>
    );
}
