import { useQuery } from 'react-query';
import { getCarModelNames, replaceCarBrand, updateCarBrand } from '../../../../service/Api';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { useEffect, useState } from 'react';
import '../../../../styling/carInfoManagement.css';
import CarBrand from '../../../../model/car/CarBrand';
import CarModel from '../../../../model/car/CarModel';
import { Button, FormControl, FormGroup, FormSelect, InputGroup, Tab, Tabs } from 'react-bootstrap';
import CarInfoModelManagement from './CarInfoModelManagement';
import ConfirmCarInfoMergeModal from './ConfirmCarInfoMergeModal';
import ToastMessage from '../../../shared/ToastMessage';

interface CarInfoBrandManagementProps {
    brandToManage: CarBrand;
    allBrands: CarBrand[];
    refetchAllBrands: () => void;
}

export default function CarInfoBrandManagement(props: CarInfoBrandManagementProps) {
    const [modelToManage, setModelToManage] = useState<CarModel | null>(null);
    const [carModels, setCarModels] = useState<CarModel[]>([]);

    const [updatedName, setUpdatedName] = useState<string>(props.brandToManage.name);
    const [replacementBrand, setReplacementBrand] = useState<CarBrand | null>(null);
    const [showReplacementModal, setShowReplacementModal] = useState<boolean>(false);

    const [toast, setToast] = useState<{ message: string; show: boolean }>({ message: '', show: false });

    const {
        data: carModelsData,
        isLoading: isCarModelsDataLoading,
        error: carModelsDataError,
        refetch: refetchCarModelsData
    } = useQuery(['carModelsForManagement', props.brandToManage.name], () => getCarModelNames(props.brandToManage.name), {
        refetchOnWindowFocus: false
    });

    /**
     * Once models are loaded, perform some validation and put it onto state
     */
    useEffect(() => {
        if (isCarModelsDataLoading) {
            return;
        }
        if (carModelsData && carModelsData.data !== undefined) {
            setCarModels(carModelsData.data);
            if (modelToManage != null && !carModelsData.data.some((m: CarModel) => m.id === modelToManage.id)) {
                setModelToManage(null);
            }
        } else {
            setModelToManage(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carModelsData, isCarModelsDataLoading]);

    /**
     * On change of the brand to manage, reset everything to its original state
     */
    useEffect(() => {
        setUpdatedName(props.brandToManage.name);
        setReplacementBrand(null);
        setModelToManage(null);
    }, [props.brandToManage.name]);

    const updateBrand = () => {
        updateCarBrand({ id: props.brandToManage.id, name: updatedName, createdDate: props.brandToManage.createdDate })
            .then(() => {
                setToast({ message: 'Successfully updated brand', show: true });
                props.refetchAllBrands();
            })
            .catch((e) => {
                console.error(e);
                setToast({ message: 'Something went wrong while updating brand, please try again later.', show: true });
            });
    };

    const replaceBrand = () => {
        if (replacementBrand == null) {
            setToast({ message: 'Please select a replacement brand first.', show: true });
            return;
        }
        replaceCarBrand(props.brandToManage.id, replacementBrand.id)
            .then(() => {
                setToast({ message: 'Successfully merged brands', show: true });
                props.refetchAllBrands();
                setShowReplacementModal(false);
                setReplacementBrand(null);
            })
            .catch((e) => {
                console.error(e);
                setToast({
                    message: 'Something went wrong while trying to replace brand, please try again later.',
                    show: true
                });
            });
    };

    if (isCarModelsDataLoading) {
        return (
            <>
                <div style={{ padding: '1em' }}>
                    <p>Loading car models of {props.brandToManage.name} ...</p>
                    <LoadingSpinner />
                </div>
            </>
        );
    } else if (carModelsDataError) {
        return (
            <>
                <div style={{ padding: '1em' }}>
                    <h1 className="h2">Manage cars</h1>
                    <p>Something went wrong fetching the models of {props.brandToManage.name}. Please try again later.</p>
                    <p>If this error persists, please contact the website maintainer.</p>
                </div>
            </>
        );
    }

    return (
        <div className="carInfoBrandMngmtItem">
            <ConfirmCarInfoMergeModal
                show={showReplacementModal}
                title={'Confirm merging brands'}
                onCancel={() => setShowReplacementModal(false)}
                onConfirm={replaceBrand}
                confirmButtonText={'Merge brands'}
                cancelButtonText={'Cancel'}
            >
                <>
                    <p>
                        This action will update all existing cars of brand '{props.brandToManage.name}' to be '{replacementBrand?.name}'. It will
                        update all models of brand '{props.brandToManage.name}' to now be models of brand '{replacementBrand?.name}'. And finally it
                        will delete brand '{props.brandToManage.name}'.
                    </p>
                    <p>
                        Do you wish to proceed with merging '{props.brandToManage.name}' into '{replacementBrand?.name}'?
                    </p>
                </>
            </ConfirmCarInfoMergeModal>
            <ToastMessage message={toast.message} showToastMessage={toast.show} setShowToastMessage={() => setToast({ message: '', show: false })} />

            <Tabs defaultActiveKey="brand" className={'carInfoTabs'}>
                <Tab eventKey="brand" title="Manage brand">
                    <p>This section allows you to manage the brand itself</p>
                    <FormGroup className="carInfoBrandMngmtFormGroup">
                        <InputGroup style={{ width: '40%', minWidth: 'fit-content' }}>
                            <InputGroup.Text>Change brand name:</InputGroup.Text>
                            <FormControl
                                style={{ width: 'fit-content', minWidth: '10em' }}
                                placeholder="Name"
                                value={updatedName}
                                onChange={(e) => setUpdatedName(e.target.value)}
                            />
                            {updatedName !== props.brandToManage.name ? (
                                <Button
                                    variant="outline-danger"
                                    className="carInfoMngmtInputDecorationButton"
                                    onClick={() => setUpdatedName(props.brandToManage.name)}
                                >
                                    Revert
                                </Button>
                            ) : (
                                <></>
                            )}
                            <Button variant="outline-secondary" className="carInfoMngmtInputDecorationButton" onClick={updateBrand}>
                                Update
                            </Button>
                        </InputGroup>
                    </FormGroup>

                    <FormGroup className="carInfoBrandMngmtFormGroup">
                        <p style={{ margin: '1em 0 0 0' }}>Replace {props.brandToManage.name}:</p>
                        <p
                            style={{
                                margin: '0 0 0.2em 0',
                                fontSize: 'x-small',
                                color: 'var(--bs-xploregroup-dark-gray)'
                            }}
                        >
                            This option allows you to merge this brand ({props.brandToManage.name}) into another brand of your choice. This will
                            result in this brand being deleted, all cars that had this brand being updated to have the selected brand as their new
                            brand and all models of this brand being updated to be of this new brand.
                        </p>
                        <InputGroup>
                            <InputGroup.Text>Merge into:</InputGroup.Text>
                            <FormSelect
                                value={replacementBrand?.name ?? ''}
                                onChange={(e) => {
                                    setReplacementBrand(props.allBrands.find((model) => model.name === e.target.value) ?? null);
                                }}
                            >
                                <option>Select replacement brand ...</option>
                                {props.allBrands
                                    .filter((b) => b.name !== props.brandToManage.name)
                                    .map((brand) => (
                                        <option key={'merge-brand-' + props.brandToManage.id + '-into-' + brand.id}>{brand.name}</option>
                                    ))}
                            </FormSelect>
                            <Button
                                variant="outline-danger"
                                className="carInfoMngmtInputDecorationButton"
                                onClick={() => setShowReplacementModal(true)}
                                disabled={replacementBrand == null}
                            >
                                Merge
                            </Button>
                        </InputGroup>
                    </FormGroup>
                </Tab>
                <Tab title="Manage models" eventKey="model">
                    <p>This section allows you to manage the models of the selected brand</p>
                    <InputGroup style={{ width: '40%', minWidth: 'fit-content' }}>
                        <InputGroup.Text>Select model to manage:</InputGroup.Text>
                        <FormSelect
                            style={{ width: 'fit-content', minWidth: '10em' }}
                            value={modelToManage?.name ?? ''}
                            onChange={(e) => {
                                setModelToManage(carModels.find((model) => model.name === e.target.value) ?? null);
                            }}
                        >
                            <option>select ...</option>
                            {carModels.map((model) => (
                                <option key={'manage-brand-' + props.brandToManage.id + '-model-' + model.id}>{model.name}</option>
                            ))}
                        </FormSelect>
                    </InputGroup>

                    <div style={{ padding: '2vh 2vw' }}>
                        {modelToManage == null ? (
                            <></>
                        ) : (
                            <CarInfoModelManagement modelToManage={modelToManage} allModels={carModels} refetchAllModels={refetchCarModelsData} />
                        )}
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}
