const mapStatus = (status: string) => {
    switch (status) {
        case 'SUSPENDED_EV':
            return 'Ready for pickup';
        case 'SUSPENDED_EVSE':
            return 'Paused';
        default:
            return status.charAt(0).toUpperCase() + status.toLowerCase().replaceAll('_', ' ').slice(1);
    }
};

export { mapStatus };
