import { Button, Modal } from 'react-bootstrap';
import { ReactElement } from 'react';

interface ConfirmCarInfoMergeModalProps {
    show: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
    confirmButtonText: string;
    cancelButtonText: string;
    children: ReactElement;
}

export default function ConfirmCarInfoMergeModal(props: ConfirmCarInfoMergeModalProps) {
    return (
        <Modal onHide={props.onCancel} show={props.show} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="danger" onClick={props.onConfirm}>
                    {props.confirmButtonText}
                </Button>
                <Button variant="primary" onClick={props.onCancel}>
                    {props.cancelButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
