import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useCheckIfAdminOrManager from '../../../../hooks/useCheckIfAdminOrManager';
import FuelcardFormData from '../../../../model/FuelcardFormData';

interface FcAlreadyExistsModalProps {
    showAlreadyExistsModal: boolean;
    setShowAlreadyExistsModal: (show: boolean) => void;
    fuelcard: FuelcardFormData;
    clearFcSerial: () => void;
}

export default function FcAlreadyExistsModal(props: FcAlreadyExistsModalProps) {
    const isAdminOrManager = useCheckIfAdminOrManager();

    return (
        <Modal size="lg" centered show={props.showAlreadyExistsModal}>
            <Modal.Header>
                <Modal.Title>Fuelcard already exists</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {isAdminOrManager ? (
                        <>
                            A fuelcard with this serial number already exists, you can manage this fuelcard via{' '}
                            <Link to={{ pathname: '/fuelcard/search' }} state={{ searchText: props.fuelcard.serialNumber }}>
                                the fuelcard search page
                            </Link>
                            .
                        </>
                    ) : (
                        <>
                            This fuelcard is already registered to you. If you want to update the display name you can do so on the{' '}
                            <Link to={{ pathname: '/fuelcard' }}>my fuelcards page</Link>. If you want to update your car you can do so on the{' '}
                            <Link to={{ pathname: '/car' }}>my car page</Link>.
                        </>
                    )}
                </p>
                Or you can continue with a different serial number by clicking the button below.
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    hidden={!isAdminOrManager}
                    variant={'danger'}
                    onClick={() => {
                        props.setShowAlreadyExistsModal(false);
                    }}
                >
                    Continue regardless
                </Button>
                <Button
                    variant={isAdminOrManager ? 'warning' : 'danger'}
                    onClick={() => {
                        props.clearFcSerial();
                        props.setShowAlreadyExistsModal(false);
                    }}
                >
                    Enter different fuelcard serial number
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
