import UserDto from '../../../../model/user/UserDto';
import { Button, Modal, Table } from 'react-bootstrap';
import moment from 'moment-timezone';

interface InactiveUserDetailsModalProps {
    userData: UserDto;
    show: boolean;
    setShow: (show: boolean) => void;
}

export default function InactiveUserDetailsModal(props: InactiveUserDetailsModalProps) {
    if (!props.show) {
        return <></>;
    }

    return (
        <Modal show={props.show} onHide={() => props.setShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    User details of {props.userData.firstName} {props.userData.lastName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'scroll' }}>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Id:</th>
                            <td>{props.userData.id}</td>
                        </tr>
                        <tr>
                            <th scope="row">Firstname:</th>
                            <td>{props.userData.firstName}</td>
                        </tr>
                        <tr>
                            <th scope="row">Lastname:</th>
                            <td>{props.userData.lastName}</td>
                        </tr>
                        <tr>
                            <th scope="row">Company/Contact email:</th>
                            <td>{props.userData.contactEmail}</td>
                        </tr>
                        <tr>
                            <th scope="row">Cronos/primary email:</th>
                            <td>{props.userData.primaryEmail}</td>
                        </tr>
                        <tr>
                            <th scope="row">Mobile:</th>
                            <td>{props.userData.mobile}</td>
                        </tr>
                        <tr>
                            <th scope="row">User type:</th>
                            <td>{props.userData.userType}</td>
                        </tr>
                        <tr>
                            <th scope="row" rowSpan={2}>
                                Last login:
                            </th>
                            <td>
                                {moment(props.userData.lastLogin).format('HH:mm:ss DD/MM/YY')} ({moment(props.userData.lastLogin).from(moment())})
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center" style={{ flexWrap: 'nowrap' }}>
                <Button size={'sm'} variant="primary" onClick={() => props.setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
