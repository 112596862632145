import React, { useEffect, useState } from 'react';
import AverageCslOccupation from '../../../model/AverageCslOccupation';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import CommonDropdownButton from '../../shared/CommonDropdownButton';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment-timezone'; // Import Dropdown for the items

interface AverageOccupationChartProps {
    avgOccupation: AverageCslOccupation[];
    currentAvg: AverageCslOccupation | undefined;
    currentOccupation: number;
    currentTime: string;
    maxOccupation: number;
    setDayOfWeek: React.Dispatch<React.SetStateAction<number>>;
}

export default function AverageOccupationChart(props: AverageOccupationChartProps) {
    const getTodayAsWeekday = () => {
        const dayOfWeek = new Date().getDay();
        return dayOfWeek === 0 ? 7 : dayOfWeek; // Convert Sunday (0) to 7 to handle Monday-Sunday as 1-7.
    };

    const [selectedDay, setSelectedDay] = useState(getTodayAsWeekday);

    const daysOfWeek = [
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 },
        { label: 'Sunday', value: 0 }
    ];

    useEffect(() => {
        props.setDayOfWeek(selectedDay);
    }, [selectedDay, props]);

    const handleDayChange = (dayValue: number) => {
        setSelectedDay(dayValue);
        props.setDayOfWeek(dayValue);
    };

    if (props.currentAvg === undefined) {
        return <></>;
    }

    const parseTime = (time: string) => {
        return time.substring(0, time.lastIndexOf(':'));
    };

    return (
        <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
            <h3 className={'h4'} style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span style={{ marginRight: '10px' }}>Average occupation rate on</span>
                    <CommonDropdownButton
                        title={'Select day'}
                        dropDownChild={daysOfWeek.find((day) => day.value === selectedDay)?.label || 'Select a day'}
                    >
                        {daysOfWeek.map((day) => (
                            <Dropdown.Item key={day.value} onClick={() => handleDayChange(day.value)}>
                                {day.label}
                            </Dropdown.Item>
                        ))}
                    </CommonDropdownButton>
                </div>
            </h3>
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                    data={props.avgOccupation.map((avg) => {
                        return {
                            name: parseTime(avg.time),
                            average: avg.average,
                            current: props.currentTime == parseTime(avg.time) ? props.currentOccupation : null
                        };
                    })}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, props.maxOccupation]} />
                    <Legend
                        formatter={(value) => {
                            switch (value) {
                                case 'average':
                                    return 'Average charging stations occupied';
                                case 'current':
                                    return 'Current occupation';
                                default:
                                    return value;
                            }
                        }}
                    />
                    <Bar dataKey="average" fill="var(--bs-xploregroup-violet)" />
                    <Line
                        hide={selectedDay !== moment().weekday()}
                        type={'monotone'}
                        dataKey="current"
                        stroke="var(--bs-xploregroup-yellow)"
                        fill={'var(--bs-xploregroup-yellow)'}
                    />
                    {selectedDay === moment().weekday() ? (
                        <ReferenceLine x={parseTime(props.currentAvg.time)} stroke={'var(--bs-xploregroup-yellow)'} />
                    ) : (
                        <></>
                    )}
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
}
