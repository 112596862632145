import { formatTo2Numbers } from '../service/DateTimeFunctions';

export default interface DateTime {
    hour: string;
    minute: string;
    day: string;
    month: string;
    year: string;
}

export class DateTimeBuilder {
    private dateTime: DateTime;

    constructor() {
        const now = new Date();
        this.dateTime = {
            hour: formatTo2Numbers(now.getHours().toString()),
            minute: formatTo2Numbers(now.getMinutes().toString()),
            day: formatTo2Numbers(now.getDate().toString()),
            month: formatTo2Numbers((now.getMonth() + 1).toString()),
            year: now.getFullYear().toString()
        };
    }

    withHour = (hour: string): DateTimeBuilder => {
        this.dateTime.hour = hour;
        return this;
    };

    withMinute = (minute: string): DateTimeBuilder => {
        this.dateTime.minute = minute;
        return this;
    };

    withDay = (day: string): DateTimeBuilder => {
        this.dateTime.day = day;
        return this;
    };

    withMonth = (month: string): DateTimeBuilder => {
        this.dateTime.month = month;
        return this;
    };

    withYear = (year: string): DateTimeBuilder => {
        this.dateTime.year = year;
        return this;
    };

    build = (): DateTime => {
        return this.dateTime;
    };
}
