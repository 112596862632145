import useCheckIfAdminOrManager from '../../../../hooks/useCheckIfAdminOrManager';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getCarBrandNames } from '../../../../service/Api';
import NavigationBar from '../../../shared/NavigationBar';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import Footer from '../../../shared/Footer';
import { useEffect, useState } from 'react';
import '../../../../styling/carInfoManagement.css';
import { FormSelect, InputGroup } from 'react-bootstrap';
import CarBrand from '../../../../model/car/CarBrand';
import CarInfoBrandManagement from './CarInfoBrandManagement';
import CreatedBrandsTable from './CreatedBrandsTable';
import CreatedModelsTable from './CreatedModelsTable';

export default function CarInfoManagementPage() {
    const isAdminOrManager = useCheckIfAdminOrManager();
    const navigate = useNavigate();
    if (!isAdminOrManager) {
        navigate('/dashboard');
    }

    const [carBrands, setCarBrands] = useState<CarBrand[]>([]);
    const [brandToManage, setBrandToManage] = useState<CarBrand | null>(null);

    const {
        data: carBrandsData,
        isLoading: isCarBrandsDataLoading,
        error: carBrandsDataError,
        refetch: refetchCarBrandsData
    } = useQuery('carBrandsForManagement', getCarBrandNames, {
        refetchOnWindowFocus: false
    });

    /**
     * Once the car brands have loaded in, perform some validation and put the data into state
     */
    useEffect(() => {
        if (isCarBrandsDataLoading) {
            return;
        }
        if (carBrandsData && carBrandsData.data !== undefined) {
            setCarBrands(carBrandsData.data);
            if (brandToManage != null && !carBrandsData.data.some((br: CarBrand) => br.id === brandToManage.id)) {
                setBrandToManage(null);
            }
        } else {
            setBrandToManage(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carBrandsData, isCarBrandsDataLoading]);

    if (isCarBrandsDataLoading) {
        return (
            <>
                <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                    <NavigationBar />
                    <div style={{ padding: '1em' }}>
                        <h1 className="h2">Manage cars</h1>
                        <LoadingSpinner />
                        <p style={{ textAlign: 'center' }}>Loading car brands ...</p>
                    </div>
                </div>
                <Footer />
            </>
        );
    } else if (carBrandsDataError) {
        return (
            <>
                <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                    <NavigationBar />
                    <div style={{ padding: '1em' }}>
                        <h1 className="h2">Manage cars</h1>
                        <p>Something went wrong fetching the car brands. Please try again later.</p>
                        <p>If this error persists, please contact the website maintainer.</p>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                <NavigationBar />
                <div className="carInfoMngmtPage">
                    <h1 className="h2">Manage car brands & models</h1>
                    <p>This page allows you to manage the car brands & models that are known by the system.</p>
                    <InputGroup style={{ minWidth: '40%', width: 'fit-content' }}>
                        <InputGroup.Text style={{ width: 'fit-content' }}>Select brand to manage:</InputGroup.Text>
                        <FormSelect
                            style={{ minWidth: 'fit-content', width: '20em' }}
                            value={brandToManage?.name ?? ''}
                            onChange={(e) => {
                                setBrandToManage(carBrands.find((brand) => brand.name === e.target.value) ?? null);
                            }}
                        >
                            <option>Select ...</option>
                            {carBrands.map((brand) => (
                                <option key={'manage-brand-' + brand.id}>{brand.name}</option>
                            ))}
                        </FormSelect>
                    </InputGroup>

                    <div style={{ padding: '2vh 0vw' }}>
                        {brandToManage == null ? (
                            <></>
                        ) : (
                            <CarInfoBrandManagement brandToManage={brandToManage} allBrands={carBrands} refetchAllBrands={refetchCarBrandsData} />
                        )}
                    </div>
                    <div>
                        <h3>Recently Created Brands</h3>
                        <CreatedBrandsTable />
                    </div>

                    <div>
                        <h3>Recently Created Models</h3>
                        <CreatedModelsTable />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
