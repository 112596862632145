import getStatusColours from '../../../../hooks/useGetStatusColour';
import { mapStatus } from '../../../../service/StatusMapper';
import InformationPopover from '../../../shared/InformationPopover';
import ChargingStation from '../../../../model/ChargingStation';
import moment, { now } from 'moment-timezone';
import { TIMEZONE } from '../../../shared/Timezone';

interface StatusProps {
    chargingStation: ChargingStation;
    isAdminOrManager: boolean;
}

export default function Status(props: StatusProps) {
    const status =
        props.chargingStation.comStatus === 'DOWN' && !props.isAdminOrManager ? props.chargingStation.comStatus : props.chargingStation.ocppStatus;

    const statusElement = (
        <div
            className="status center-grid-text"
            style={{
                border: '1px solid black',
                borderColor: getStatusColours(status).border,
                backgroundColor: getStatusColours(status).background
            }}
        >
            <p className="NoPaddingOrMargin">{mapStatus(status)}</p>
        </div>
    );

    if (!props.isAdminOrManager) {
        return statusElement;
    }

    return (
        <InformationPopover
            icon={statusElement}
            desktopOverLayPlacement={'bottom'}
            desktopPopoverStyle={{ width: 'fit-content' }}
            mobilePopoverStyle={{ width: 'fit-content' }}
            trigger={'click'}
        >
            {props.isAdminOrManager ? (
                <div className="status-admin-popover">
                    <p>
                        <span className={'status-admin-popover-key'}>Ocpp status: </span>
                        <span className={'status-admin-popover-value'}>{props.chargingStation.ocppStatus}</span>
                    </p>
                    <p>
                        <span className={'status-admin-popover-key'}>Com status: </span>
                        <span className={'status-admin-popover-value'}>{props.chargingStation.comStatus}</span>
                    </p>
                    <p>
                        <span className={'status-admin-popover-key'}>Service status: </span>
                        <span className={'status-admin-popover-value'}>{props.chargingStation.serviceStatus}</span>
                    </p>
                    <p>
                        <span className={'status-admin-popover-key'}>Last updated: </span>
                        <span className={'status-admin-popover-value'}>
                            {moment(props.chargingStation.lastUpdateTime).tz(TIMEZONE).from(now())} (
                            {moment(props.chargingStation.lastUpdateTime).tz(TIMEZONE).format('HH:mm DD/MM/YY')})
                        </span>
                    </p>
                </div>
            ) : (
                <></>
            )}
        </InformationPopover>
    );
}
