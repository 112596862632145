import '../../../styling/fuelcardSearch.css';
import ChargingStationLocation from '../../../model/ChargingStationLocation';
import useCheckIfAdminOrManagerForLocation from '../../../hooks/useCheckIfAdminOrManagerForLocation';
import { useQuery } from 'react-query';
import { getUserNotifCcPreferences, updateUserNotifCcPreferences } from '../../../service/Api';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { Form } from 'react-bootstrap';
import { FormEvent, useEffect, useState } from 'react';
import ApiError from '../../shared/ApiError';
import NotificationCcPreference from '../../../model/notification/NotificationCcPreference';
import NotificationType from '../../../model/NotificationType';
import InformationPopover from '../../shared/InformationPopover';

interface ManageCslNotificationCCPreferencesProps {
    csl: ChargingStationLocation;
}

export default function ManageCslNotificationCCPreferences(props: ManageCslNotificationCCPreferencesProps) {
    const isAdminOrManager = useCheckIfAdminOrManagerForLocation(props.csl.name);

    const [notifCcPreferences, setNotifCcPreferences] = useState<NotificationCcPreference[]>([]);
    const [notifCcUpdateError, setNotifCcUpdateError] = useState<unknown | undefined>(undefined);

    const {
        data: notifCcPrefData,
        isLoading: notifCcPrefLoading,
        error: notifCcPrefError,
        refetch: notifCcPrefRefetch
    } = useQuery(['cslNotifCcPref', props.csl.name], () => getUserNotifCcPreferences(props.csl.name), {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (!notifCcPrefLoading && !notifCcPrefError) {
            const fetchedData = notifCcPrefData?.data;
            if (Array.isArray(fetchedData)) {
                setNotifCcPreferences(fetchedData);
            }
        }
    }, [notifCcPrefLoading, notifCcPrefError, notifCcPrefData]);

    if (!isAdminOrManager) {
        return <></>;
    } else if (notifCcPrefLoading) {
        return (
            <div style={{ width: '100%' }}>
                <LoadingSpinner />
                <p style={{ width: '100%', textAlign: 'center' }}>Loading notification cc preferences for {props.csl.displayName}</p>
            </div>
        );
    } else if (notifCcPrefError) {
        return <ApiError error={notifCcPrefError} title={<>Failed to load notification cc preferences for {props.csl.displayName}</>} />;
    } else if (notifCcUpdateError) {
        return <ApiError error={notifCcUpdateError} title={<>Failed to update notification cc preferences for {props.csl.displayName}</>} />;
    }

    const doUpdate = async (e: FormEvent<HTMLInputElement>, notifCcPrefToUpdate: NotificationCcPreference) => {
        e.preventDefault();
        updateUserNotifCcPreferences({
            ...notifCcPrefToUpdate,
            ccEnabled: !notifCcPrefToUpdate.ccEnabled
        })
            .then(() => notifCcPrefRefetch())
            .catch((e) => {
                setNotifCcUpdateError(e);
            });
    };

    return (
        <div className="notificationMngmtItemHolder">
            <h2 className="h4">{props.csl.displayName} notification CC preferences</h2>
            {notifCcPreferences
                .sort((a, b) => (a.notification < b.notification ? -1 : 1))
                .map((pref) => (
                    <div className={'notificationMngmtItem'} key={pref.notification + pref.chargingStationLocationName + pref.userId}>
                        <div className={'notificationMngmtItemTitle'}>
                            {pref.notification === NotificationType.FINISHED_CHARGING ? 'Fully Charged' : ''}
                            {pref.notification === NotificationType.UNMOVED_EV ? 'Unmoved Vehicle reminder' : ''}
                        </div>

                        <div className={'notificationMngmtItemButton centerSelf'}>
                            <Form.Check type="switch" onChange={(e) => doUpdate(e, pref)} checked={pref.ccEnabled} />
                            <div>
                                <InformationPopover>
                                    <>
                                        <p hidden={pref.notification !== NotificationType.FINISHED_CHARGING}>
                                            This toggle will enable/disable whether you are in CC for the finished charging notifications.
                                        </p>
                                        <p hidden={pref.notification !== NotificationType.UNMOVED_EV}>
                                            This toggle will enable/disable whether you are in CC for the unmoved vehicle reminder notifications.
                                        </p>
                                    </>
                                </InformationPopover>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
}
