import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import ChargingStation from '../../../model/ChargingStation';
import NotificationToggleModalBody from './NotificationToggleModalBody';
import { NotificationSaveRequest } from '../../../model/NotificationSaveRequest';

interface IncidentNotificationToggleModalProps {
    show: boolean;
    onHide: () => void;
    handleToggleNotificationLocation: (stations: ChargingStation[], isMailEnabled: boolean, isPushEnabled: boolean) => Promise<void>;
    stations: ChargingStation[];
    isMailEnabled: boolean;
    isPushEnabled: boolean;
}

export default function IncidentNotificationToggleModal(props: IncidentNotificationToggleModalProps) {
    useEffect(() => {
        if (props.show) {
            reset({
                mailEnabled: !(props.isMailEnabled || props.isPushEnabled),
                pushEnabled: !(props.isMailEnabled || props.isPushEnabled)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, props.isMailEnabled, props.isPushEnabled]);

    const { register, handleSubmit, reset, watch } = useForm<NotificationSaveRequest>();

    const onSubmit = (data: NotificationSaveRequest) => {
        props.handleToggleNotificationLocation(props.stations, data.mailEnabled, data.pushEnabled);
        props.onHide();
    };

    function getNotificationTitleText() {
        return 'Incident';
    }

    return (
        <NotificationToggleModalBody
            titleText={getNotificationTitleText()}
            register={register}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            watch={watch}
            {...props}
        />
    );
}
