import '../../../styling/notificationToggles.css';
import { toggleNotification } from '../../../service/Api';
import NotificationType from '../../../model/NotificationType';
import { Form } from 'react-bootstrap';
import NotificationToggle from '../../../model/NotificationToggle';
import NotificationToggleProps from '../../../props/NotificationTogglesProps';
import UserNotificationToggleForm from './UserNotificationToggleForm';
import { useState } from 'react';
import useCheckIfAdminOrManagerForLocation from '../../../hooks/useCheckIfAdminOrManagerForLocation';
import AvailableStationConfirmModal from './AvailableStationConfirmModal';
import InformationPopover from '../../shared/InformationPopover';
import MngrNotificationToggleForm from './MngrNotificationToggleForm';
import LoadingSpinner from '../../shared/LoadingSpinner';
import ApiError from '../../shared/ApiError';

export default function NotificationTogglesLocation(props: NotificationToggleProps) {
    const isAdminOrManager = useCheckIfAdminOrManagerForLocation(props.location.name);
    const [showAvailableStationSetTimeModal, setShowAvailableStationSetTimeModal] = useState<'none' | 'confirmTime' | 'alreadyCharging'>('none');

    const handleToggleNotification = async (toggle: NotificationToggle) => {
        if (!(toggle.enabled || toggle.pushEnabled) && toggle.notificationType === NotificationType.AVAILABLE_STATION) {
            if (props.isUserCharging) {
                setShowAvailableStationSetTimeModal('alreadyCharging');
                return;
            } else {
                setShowAvailableStationSetTimeModal('confirmTime');
                return;
            }
        }

        if ((toggle.enabled || toggle.pushEnabled) && toggle.notificationType === NotificationType.AVAILABLE_STATION) {
            await doToggleNotification({ ...toggle, enabled: false, pushEnabled: false });
            return;
        }

        await doToggleNotification(toggle);
    };

    const doToggleNotification = async (toggle: NotificationToggle) => {
        try {
            await toggleNotification(toggle);
            props.refetchNotifications();
        } catch (error) {
            props.setTransactionMessage('Something went wrong, please try again later.');
            props.setShowMessageModal(true);
        }
    };

    if (!props.show) {
        return <></>;
    } else if (props.togglesLoading) {
        return (
            <div style={{ margin: '0 2em' }}>
                <h5>Notification Settings</h5>
                <LoadingSpinner style={{ marginTop: '1em' }} />
                <p style={{ width: '100%', textAlign: 'center', marginTop: '1em' }}>Loading notification settings...</p>
            </div>
        );
    } else if (props.notificationToggleError) {
        return (
            <div style={{ margin: '0 2em' }}>
                <ApiError error={props.notificationToggleError} />
            </div>
        );
    } else if (props.notificationToggleMgrError) {
        return (
            <div style={{ margin: '0 2em' }}>
                <ApiError error={props.notificationToggleMgrError} />
            </div>
        );
    }

    return (
        <>
            <AvailableStationConfirmModal
                availableStations={props.availableCharingStations}
                show={showAvailableStationSetTimeModal}
                setShowModal={setShowAvailableStationSetTimeModal}
                onConfirm={doToggleNotification}
                notification={props.notificationToggle?.data?.find((toggle: { notificationType: NotificationType; cslId: string }) => {
                    return toggle.notificationType === NotificationType.AVAILABLE_STATION && toggle.cslId === props.location.id;
                })}
            />
            <Form className="dashboardForm">
                <div className={isAdminOrManager ? 'toggleContainerAdmin' : 'toggleContainer'} style={{ paddingBottom: '0' }}>
                    <div className="title centerSelf">
                        <h5>Notification Settings</h5>
                    </div>
                    <div className="poolNotification centerSelf">Pool user ready for pickup</div>
                    <div className="available centerSelf">Available charging stations</div>
                    <div className="finished centerSelf">Finished charging</div>
                    <div className="poolNotificationToggle centerSelf">
                        <UserNotificationToggleForm
                            notification={props.notificationToggle?.data?.find((toggle: { notificationType: NotificationType; cslId: string }) => {
                                return toggle.notificationType === NotificationType.POOL_USER_READY_FOR_PICKUP && toggle.cslId === props.location.id;
                            })}
                            handleToggleNotification={handleToggleNotification}
                        />
                        <InformationPopover infoHeader="Pool user ready for pickup notification">
                            <p>
                                If you turn this notification on you'll receive an email as soon as a user that is in your pool is ready for pickup.
                            </p>
                            <p>This notification will turn itself off when you start charging or at night if you never started charging today.</p>
                        </InformationPopover>
                    </div>
                    <div className="availableToggle centerSelf">
                        <UserNotificationToggleForm
                            notification={props.notificationToggle?.data?.find((toggle: { notificationType: NotificationType; cslId: string }) => {
                                return toggle.notificationType === NotificationType.AVAILABLE_STATION && toggle.cslId === props.location.id;
                            })}
                            handleToggleNotification={handleToggleNotification}
                        />
                        <InformationPopover infoHeader="Available station notification & reminder">
                            <p>
                                If you turn this notification on you'll receive an email as soon as there is a station available. This notification
                                will then automatically turn off. You will also receive the option to turn on an hourly reminder that will
                                automatically turn itself off at a given time.
                            </p>
                            <p>This notification will also turn itself off every night.</p>
                        </InformationPopover>
                    </div>
                    <div className="finishedToggle centerSelf">
                        <UserNotificationToggleForm
                            notification={props.notificationToggle?.data?.find((toggle: { notificationType: NotificationType; cslId: string }) => {
                                return toggle.notificationType === NotificationType.FINISHED_CHARGING && toggle.cslId === props.location.id;
                            })}
                            handleToggleNotification={handleToggleNotification}
                        />
                        <InformationPopover infoHeader="Finished charging notification">
                            <p>If you turn this notification on you'll receive an email as soon as your car has finished charging.</p>
                            <p>
                                You will receive a notification if you do not move your car for more than 2 hours after it has finished charging if
                                there are few charging stations available. This cannot be turned off.
                            </p>
                        </InformationPopover>
                    </div>
                    {isAdminOrManager && (
                        <>
                            <div className="allUnknownFcToggleDesc centerSelf">Unknown fuelcard (all charging stations)</div>
                            <div className="incidentDescription centerSelf">Station down (all charging stations)</div>
                            <div className="blockedFcToggleDesc centerSelf">Blocked fuelcard</div>
                            <div className="allUnknownFcToggle centerSelf">
                                <MngrNotificationToggleForm {...props} types={[NotificationType.UNKNOWN_FUELCARD]} />
                                <InformationPopover infoHeader="Unknown fuelcard toggle">
                                    <p>
                                        This toggle will enable/disable the unknown fuelcard notifications toggles of all the individual stations. You
                                        can also configure these manually per station.
                                    </p>
                                </InformationPopover>
                            </div>
                            <div className="incindentToggle centerSelf">
                                <MngrNotificationToggleForm {...props} types={[NotificationType.STATION_DOWN, NotificationType.AVAILABLE_STATION]} />
                                <InformationPopover infoHeader="Station down toggle">
                                    <p>
                                        This toggle will enable/disable the station down and station down reminder notifications toggles of all the
                                        individual stations. You can also configure these manually per station.
                                    </p>
                                </InformationPopover>
                            </div>
                            <div className="blockedFcToggle centerSelf">
                                <UserNotificationToggleForm
                                    notification={props.notificationToggle?.data?.find(
                                        (toggle: { notificationType: NotificationType; cslId: string }) => {
                                            return (
                                                toggle.notificationType === NotificationType.BLOCKED_FUELCARD && toggle.cslId === props.location.id
                                            );
                                        }
                                    )}
                                    handleToggleNotification={handleToggleNotification}
                                />
                                <InformationPopover infoHeader="Blocked fuelcard notification">
                                    <p>
                                        If you turn this notification on you'll receive an email if someone with a blocked fuelcard attempts to start
                                        charging.
                                    </p>
                                </InformationPopover>
                            </div>
                        </>
                    )}
                </div>
            </Form>
        </>
    );
}
