import { useForm } from 'react-hook-form';
import NotificationToggle from '../../../model/NotificationToggle';
import NotificationType from '../../../model/NotificationType';
import { useState, useEffect } from 'react';
import NotificationToggleModalBody from './NotificationToggleModalBody';
import { NotificationSaveRequest } from '../../../model/NotificationSaveRequest';

interface NotificationToggleModalProps {
    show: boolean;
    onHide: () => void;
    notification: NotificationToggle;
    handleToggleNotification: (toggle: NotificationToggle) => Promise<void>;
}

export default function NotificationToggleModal(props: NotificationToggleModalProps) {
    const [notificationToggle, setNotificationToggle] = useState<NotificationToggle>(props.notification);

    useEffect(() => {
        if (props.show) {
            setNotificationToggle(props.notification);
            reset({
                mailEnabled: !(props.notification.enabled || props.notification.pushEnabled),
                pushEnabled: !(props.notification.enabled || props.notification.pushEnabled)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, props.notification]);

    const { register, handleSubmit, reset, watch } = useForm<NotificationSaveRequest>();

    const onSubmit = (data: NotificationSaveRequest) => {
        const updatedNotification = {
            ...notificationToggle,
            enabled: data.mailEnabled,
            pushEnabled: data.pushEnabled
        };

        props.handleToggleNotification(updatedNotification);
        props.onHide();
        setNotificationToggle(updatedNotification);
    };

    function getNotificationTitleText() {
        switch (props.notification.notificationType) {
            case NotificationType.FINISHED_CHARGING:
                return 'Finished Charging';
            case NotificationType.POOL_USER_READY_FOR_PICKUP:
                return 'Pool User Ready For Pickup';
            case NotificationType.BLOCKED_FUELCARD:
                return 'Blocked Fuelcard';
            default:
                return '';
        }
    }

    return (
        <NotificationToggleModalBody
            titleText={getNotificationTitleText()}
            register={register}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            show={props.show}
            onHide={props.onHide}
            watch={watch}
        />
    );
}
