import PageTemplate from '../../../shared/PageTemplate';
import { useCallback, useEffect, useRef, useState } from 'react';
import UserDto from '../../../../model/user/UserDto';
import Page from '../../../../model/Page';
import { SortType } from '../../../../model/enum/SortType';
import useCheckIfAdminOrManager from '../../../../hooks/useCheckIfAdminOrManager';
import { useNavigate } from 'react-router-dom';
import { searchInactiveUsers } from '../../../../service/Api';
import { AxiosResponse, isAxiosError } from 'axios';
import ApiError from '../../../shared/ApiError';
import { Button, ButtonGroup, Form, InputGroup, Table } from 'react-bootstrap';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import PaginationButtons from '../../../shared/PaginationButtons';
import moment from 'moment-timezone';
import InactiveUserDetailsModal from './InactiveUserDetailsModal';
import TableHeader from '../../../search/TableHeader';
import '../../../../styling/inactiveUserSearchPage.css';
import DeleteInactiveUserModal from './DeleteInactiveUserModal';
import InformationPopover from '../../../shared/InformationPopover';

interface UserWithFuelcardLastUsed {
    user: UserDto;
    fuelcardLastUsed: Date;
}

export default function InactiveUserSearchPage() {
    const defaultSort = 'lastLogin';

    const isAdminOrManager = useCheckIfAdminOrManager();
    const navigate = useNavigate();

    const [searchState, setSearchState] = useState<'initial' | 'loading' | 'success' | 'error'>('initial');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchDate, setSearchDate] = useState<string>(moment().subtract(3, 'months').format('DD/MM/YYYY'));
    const [searchResult, setSearchResult] = useState<UserWithFuelcardLastUsed[]>([]);
    const [page, setPage] = useState<Page>({
        currentPage: 0,
        totalPages: 0,
        isEmpty: true
    });
    const [sort, setSort] = useState<{ field: string; direction: SortType }>({
        field: defaultSort,
        direction: SortType.ASC
    });
    const [searchError, setSearchError] = useState<unknown | undefined>(undefined);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const [userDetailsModal, setUserDetailsModal] = useState<{ show: boolean; user: UserDto | null }>({
        show: false,
        user: null
    });
    const [userDeleteModal, setUserDeleteModal] = useState<{ show: boolean; user: UserDto | null }>({
        show: false,
        user: null
    });

    useEffect(() => {
        if (!isAdminOrManager) {
            navigate('/dashboard');
        }
    }, [isAdminOrManager, navigate]);

    const handleSearchChange = (newSearchQuery: string, newSearchDate: string) => {
        if (newSearchQuery != searchQuery) {
            setSearchQuery(newSearchQuery);
        }
        if (newSearchDate != searchDate) {
            setSearchDate(newSearchDate);
        }
        debouncedSearch(newSearchQuery, newSearchDate);
    };

    const isDateValid = (date: string) => {
        return moment(date, 'DD/MM/YYYY', true).isValid();
    };

    const handleSuccessfulResponse = useCallback((resp: AxiosResponse<any, any>) => {
        setSearchResult(resp.data.content);
        setPage({
            currentPage: resp.data.number,
            totalPages: resp.data.totalPages,
            isEmpty: resp.data.totalElements === 0
        });
        if (resp.data.pageRequest && resp.data.pageRequest.sort) {
            setSort({
                field: resp.data.pageRequest.sort.property,
                direction: resp.data.pageRequest.sort.direction === 'ASC' ? SortType.ASC : SortType.DESC
            });
        } else {
            setSort({
                field: defaultSort,
                direction: SortType.ASC
            });
        }
        setSearchState('success');
    }, []);

    const handleErrorResponse = useCallback(
        (e: any) => {
            if (isAxiosError(e) && (e.response?.status === 403 || e.response?.status === 401)) {
                navigate('/login');
            } else {
                setSearchError(e);
            }
            setSearchState('error');
        },
        [navigate]
    );

    const doSearch = useCallback(
        (
            query: string,
            date: string,
            localPage: Page,
            localSort: {
                field: string;
                direction: SortType;
            }
        ) => {
            if (!isDateValid(date)) {
                return;
            }
            setSearchState('loading');

            const isNewSearch = query !== searchQuery;
            searchInactiveUsers(
                query,
                date,
                isNewSearch ? 0 : localPage.currentPage,
                localSort.field,
                localSort.direction === SortType.DESC ? 'DESC' : 'ASC'
            )
                .then(handleSuccessfulResponse)
                .catch(handleErrorResponse);
        },
        [handleErrorResponse, handleSuccessfulResponse, searchQuery]
    );

    const debouncedSearch = useCallback(
        (query: string, date: string) => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            debounceTimeout.current = setTimeout(() => {
                doSearch(query, date, page, sort);
            }, 500);
        },
        [doSearch, page, sort]
    );

    const handleSortChange = (field: string) => {
        let newSortType: SortType;
        if (sort.field === field) {
            switch (sort.direction) {
                case SortType.ASC:
                    newSortType = SortType.DESC;
                    break;
                case SortType.DESC:
                    newSortType = SortType.NO_SORT;
                    break;
                case SortType.NO_SORT:
                default:
                    newSortType = SortType.ASC;
                    break;
            }
        } else {
            newSortType = SortType.ASC;
        }
        if (newSortType === SortType.NO_SORT) {
            field = '';
        }

        doSearch(searchQuery, searchDate, page, { field: field, direction: newSortType });
    };

    if (searchState === 'initial') {
        doSearch('', searchDate, page, sort);
    }

    if (searchState === 'error' || searchError !== undefined) {
        return (
            <PageTemplate className={'p-2'}>
                <ApiError error={searchError} title="Something went wrong while loading inactive users" />
            </PageTemplate>
        );
    }

    if (searchState === 'loading') {
        return (
            <PageTemplate className={'p-2'}>
                <LoadingSpinner />
                <p style={{ width: '100%', textAlign: 'center' }}>Loading inactive users ...</p>
            </PageTemplate>
        );
    }

    return (
        <PageTemplate className={'p-4 pt-2 inactiveUserSearchPage'}>
            {userDetailsModal.user != null ? (
                <>
                    <InactiveUserDetailsModal
                        userData={userDetailsModal.user}
                        show={userDetailsModal.show}
                        setShow={(show) => {
                            if (!show) {
                                setUserDetailsModal({ show: false, user: null });
                            }
                        }}
                    />
                </>
            ) : (
                <></>
            )}
            {userDeleteModal.user != null ? (
                <>
                    <DeleteInactiveUserModal
                        userData={userDeleteModal.user}
                        show={userDeleteModal.show}
                        setShow={(show) => {
                            if (!show) {
                                setUserDeleteModal({ show: false, user: null });
                                doSearch(searchQuery, searchDate, page, sort);
                            }
                        }}
                    />
                </>
            ) : (
                <></>
            )}
            <h1 hidden={searchQuery !== ''}>Inactive user page</h1>

            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    doSearch(searchQuery, searchDate, page, sort);
                }}
                style={{ width: '100%', margin: '0', padding: '0' }}
            >
                <InputGroup hasValidation>
                    <Form.Control
                        placeholder="Search term"
                        style={{ width: 'calc(100% - 8em - 8em)' }}
                        value={searchQuery}
                        autoFocus
                        onChange={(e) => handleSearchChange(e.target.value ?? '', searchDate)}
                        onPaste={(e) => {
                            doSearch(e.clipboardData.getData('Text'), searchQuery, page, sort);
                        }}
                    />
                    <InputGroup.Text style={{ width: '8em' }}>Inactive since: </InputGroup.Text>
                    <Form.Control
                        placeholder="DD/MM/YYYY"
                        style={{ width: '8em' }}
                        value={searchDate}
                        onChange={(e) => handleSearchChange(searchQuery, e.target.value ?? '')}
                        isInvalid={!isDateValid(searchDate)}
                    />
                    <Form.Control.Feedback type="invalid">Please ensure the date is of format DD/MM/YYYY and is a valid date.</Form.Control.Feedback>
                </InputGroup>
            </Form>
            <p className="small-gray-text">This page will only list non-xploregroup users. You cannot use this page to look up xploregroup users.</p>

            {searchState === 'success' && searchResult.length > 0 ? (
                <>
                    <Table striped bordered className={'inactiveUserSearchResultTable'}>
                        <thead>
                            <tr>
                                <TableHeader
                                    title="Firstname"
                                    fieldName="firstName"
                                    currentSortField={sort.field}
                                    defaultSort={defaultSort}
                                    currentSortType={sort.direction}
                                    onSortChange={handleSortChange}
                                />
                                <TableHeader
                                    title="Lastname"
                                    fieldName="lastName"
                                    currentSortField={sort.field}
                                    defaultSort={defaultSort}
                                    currentSortType={sort.direction}
                                    onSortChange={handleSortChange}
                                />
                                <TableHeader
                                    title="Contact/company email"
                                    fieldName="contactEmail"
                                    currentSortField={sort.field}
                                    defaultSort={defaultSort}
                                    currentSortType={sort.direction}
                                    onSortChange={handleSortChange}
                                />
                                <TableHeader
                                    title="Type"
                                    fieldName="userType"
                                    currentSortField={sort.field}
                                    defaultSort={defaultSort}
                                    currentSortType={sort.direction}
                                    onSortChange={handleSortChange}
                                />
                                <TableHeader
                                    title="Last login"
                                    fieldName="lastLogin"
                                    currentSortField={sort.field}
                                    defaultSort={defaultSort}
                                    currentSortType={sort.direction}
                                    onSortChange={handleSortChange}
                                />
                                <th>
                                    Fuelcard last usage
                                    <InformationPopover desktopOverLayPlacement={'bottom'} mobileOverLayPlacement={'bottom'}>
                                        <p>The last time any fuelcard linked to this user was used.</p>
                                    </InformationPopover>
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResult.map((u) => {
                                return (
                                    <tr key={u.user.id}>
                                        <td>{u.user.firstName}</td>
                                        <td>{u.user.lastName}</td>
                                        <td>
                                            <p>{u.user.contactEmail}</p>
                                            <p className="small-gray-text">
                                                {u.user.primaryEmail == null ? 'No cronos/primary email' : u.user.primaryEmail}
                                            </p>
                                        </td>
                                        <td>{u.user.userType}</td>
                                        <td>
                                            <p>{moment(u.user.lastLogin).from(moment())}</p>
                                            <p className="small-gray-text">{moment(u.user.lastLogin).format('DD/MM/YY HH:mm')}</p>
                                        </td>
                                        <td>
                                            <p>
                                                {u.fuelcardLastUsed === undefined ? 'No linked fuelcards' : moment(u.fuelcardLastUsed).from(moment())}
                                            </p>
                                            <p className="small-gray-text">
                                                {u.fuelcardLastUsed === undefined ? '' : moment(u.fuelcardLastUsed).format('DD/MM/YY HH:mm')}
                                            </p>
                                        </td>
                                        <td>
                                            <ButtonGroup>
                                                <Button variant="outline-info" onClick={() => setUserDetailsModal({ user: u.user, show: true })}>
                                                    Details
                                                </Button>
                                                <Button variant="outline-danger" onClick={() => setUserDeleteModal({ user: u.user, show: true })}>
                                                    Delete
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <PaginationButtons setPage={(newPage) => doSearch(searchQuery, searchDate, newPage, sort)} page={page} changingPage={false} />
                </>
            ) : (
                <></>
            )}
            {searchState === 'success' && searchResult.length === 0 ? <p>No results</p> : <></>}
        </PageTemplate>
    );
}
