import Modal from 'react-bootstrap/Modal';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import NotificationToggle from '../../../model/NotificationToggle';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { formatTo2Numbers, formatToIsoString } from '../../../service/DateTimeFunctions';
import { DateTimeBuilder } from '../../../model/DateTime';
import InformationPopover from '../../shared/InformationPopover';
import { useForm } from 'react-hook-form';
import { NotificationSaveRequest } from '../../../model/NotificationSaveRequest';

interface AvailableStationConfirmProps {
    show: 'none' | 'confirmTime' | 'alreadyCharging';
    availableStations: number[];
    setShowModal: Dispatch<SetStateAction<'none' | 'confirmTime' | 'alreadyCharging'>>;
    onConfirm: Dispatch<NotificationToggle>;
    notification: NotificationToggle;
}

export default function AvailableStationConfirmModal(props: AvailableStationConfirmProps) {
    const [showReminderTime, setShowReminderTime] = useState<boolean>(false);
    const [reminderTime, setReminderTime] = useState<string>('');
    const [reminderTimeInvalid, setReminderTimeInvalid] = useState<boolean>(false);
    const { register, getValues, watch } = useForm<NotificationSaveRequest>({
        defaultValues: {
            mailEnabled: true,
            pushEnabled: true
        }
    });

    const watchMailEnabled = watch('mailEnabled', false);
    const watchPushEnabled = watch('pushEnabled', false);

    useEffect(() => {
        if (reminderTime === '') {
            if (reminderTimeInvalid) {
                setReminderTimeInvalid(false);
            }
            return;
        }
        const parsedTime = parseInt(reminderTime);
        const isInvalid = parsedTime <= new Date().getHours() || parsedTime >= 24;

        if (isInvalid !== reminderTimeInvalid) {
            setReminderTimeInvalid(isInvalid);
        }
    }, [reminderTime, reminderTimeInvalid]);

    const handleClose = () => {
        props.setShowModal('none');
        setShowReminderTime(false);
        setReminderTime('');
    };

    const handleConfirm = () => {
        handleClose();

        if (showReminderTime) {
            const cleanedReminderTime = reminderTime === '' ? '17' : formatTo2Numbers(reminderTime);
            props.notification.notifyUntil = formatToIsoString(new DateTimeBuilder().withHour(cleanedReminderTime).build());
        } else {
            props.notification.notifyUntil = undefined;
        }
        props.onConfirm({
            ...props.notification,
            enabled: getValues('mailEnabled'),
            pushEnabled: getValues('pushEnabled')
        });
    };

    const handleReminderTimeChange = (newReminderTime: string) => {
        const cleaned = newReminderTime.replace(/\D/g, '');
        setReminderTime(cleaned);
    };

    const getBody = () => {
        if (props.availableStations.length > 0) {
            return (
                <>
                    <p>
                        {' '}
                        The following station{props.availableStations.length === 1 ? ' is' : 's are'} available: {props.availableStations.join(', ')}
                    </p>
                    <p>Do you wish to enable the available charging stations notification regardless?</p>
                </>
            );
        } else if (props.show === 'alreadyCharging') {
            return <p>You are already charging at the moment, do you wish to enable the available charging stations notification regardless?</p>;
        } else {
            return <p>You will receive a notification as soon as a spot becomes available.</p>;
        }
    };

    return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={props.show !== 'none'} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Confirm enabling available charging stations notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getBody()}
                <Form>
                    <Row className="justify-content-center">
                        <Col xs="auto">
                            <Form.Group className="mb-3 d-flex align-items-center">
                                <Form.Label className="mb-0 me-2">Email:</Form.Label>
                                <Form.Check type="switch" className="switch-toggle" {...register('mailEnabled')} />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group className="mb-3 d-flex align-items-center">
                                <Form.Label className="mb-0 me-2">Push:</Form.Label>
                                <Form.Check type="switch" className="switch-toggle" {...register('pushEnabled')} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <p>
                    <Form.Switch
                        inline
                        reverse
                        type="checkbox"
                        label={
                            <div style={{ textAlign: 'center' }}>
                                I would like to receive hourly reminders about free spots.
                                <InformationPopover infoHeader="Hourly Reminder" desktopOverLayPlacement={'bottom'} mobileOverLayPlacement={'bottom'}>
                                    <p>
                                        By enabling this hourly reminder you will, in addition to the initial notification you receive when a spot
                                        becomes available, receive an hourly notification with an overview of the available spots. This reminder will
                                        disable itself if you start charging or when the time you can specify below has passed.
                                    </p>
                                </InformationPopover>
                            </div>
                        }
                        checked={showReminderTime}
                        onChange={(e) => setShowReminderTime(e.target.checked)}
                        autoFocus
                    />
                </p>
                <div style={showReminderTime ? {} : { display: 'none' }}>
                    <InputGroup>
                        <InputGroup.Text>I would like to be reminded until:</InputGroup.Text>
                        <Form.Control
                            placeholder="17"
                            maxLength={2}
                            value={reminderTime}
                            type={'numeric'}
                            onChange={(e) => handleReminderTimeChange(e.target.value)}
                        />
                        <InputGroup.Text>:00</InputGroup.Text>
                    </InputGroup>
                    <p style={reminderTimeInvalid ? { color: 'red' } : { display: 'none' }}>
                        Please enter a valid hour. (Any value between {formatTo2Numbers((new Date().getHours() + 1).toString())} and 24)
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} style={{ maxWidth: '45%' }}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={handleConfirm}
                    style={{ maxWidth: '45%' }}
                    disabled={reminderTimeInvalid || (!watchMailEnabled && !watchPushEnabled)}
                >
                    Enable
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
