const profiles = {
    dev: 'http://localhost:8080',
    staging: 'https://fx8p3vwnk9.eu-west-1.awsapprunner.com',
    prod: 'https://792bs9vnvp.eu-west-1.awsapprunner.com'
};

export let API_URL = profiles.dev;
export let DOCUMENT_TITLE = 'CCN 2.0 DEV';

const setProfile = (profile) => {
    if (profiles[profile]) {
        API_URL = profiles[profile];
        if (profiles[profile] === profiles.prod) {
            DOCUMENT_TITLE = 'CCN 2.0';
        } else {
            DOCUMENT_TITLE = 'CCN 2.0 ' + profile.toUpperCase();
        }
    } else {
        console.error(`Profile '${profile}' not found.`);
    }
};

// If the environment variable REACT_APP_PROFILE is set, use it
if (process.env.REACT_APP_PROFILE) {
    setProfile(process.env.REACT_APP_PROFILE);
}
