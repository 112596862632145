import { Dropdown } from 'react-bootstrap';
import { ReactNode } from 'react';
import '../../styling/commonDropDownButton.css';

interface CommonDropdownButtonProps {
    title: string;
    dropDownClassName?: string;
    dropDownChild: ReactNode;
    children: ReactNode;
}

export default function CommonDropdownButton(props: CommonDropdownButtonProps) {
    return (
        <Dropdown title={props.title} className={props.dropDownClassName}>
            <Dropdown.Toggle variant="outline-dark" size="sm" className={'common-dropdown-toggle'}>
                {props.dropDownChild}
            </Dropdown.Toggle>
            <Dropdown.Menu>{props.children}</Dropdown.Menu>
        </Dropdown>
    );
}
