import { Button, Modal } from 'react-bootstrap';
import { acceptEula } from '../../service/Api';
import Eula from '../../model/Eula';
import { useState } from 'react';
import UserLogin from '../../model/user/UserLogin';

interface NewEulaModalProps {
    show: boolean;
    onHide: () => void;
    eula: Eula;
    userInfo: UserLogin;
}

export default function NewEulaModal(props: NewEulaModalProps) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const onClickAccept = async () => {
        try {
            await acceptEula(props.userInfo.id, props.eula.id);
            props.onHide();
            props.userInfo.newEula = undefined;
            localStorage.setItem('userInfo', JSON.stringify(props.userInfo));
        } catch (error) {
            console.error('Error accepting EULA.');
        }
    };

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Updated Terms and Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Our terms and conditions have recently been updated. Please take a moment to review them carefully by clicking{' '}
                    <a href={props.eula.url} onClick={() => setIsButtonDisabled(false)} target="_blank" rel="noreferrer">
                        here
                    </a>
                    .
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button style={{ width: '100%' }} disabled={isButtonDisabled} variant="primary" onClick={onClickAccept}>
                        I accept these new terms and conditions
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
