import { useFormContext } from 'react-hook-form';
import CommonFuelcardFormFieldProps from '../../../props/CommonFuelcardFormFieldProps';
import FuelCardFormFieldTextBox from './FuelcardFormFieldTextBox';
import FuelcardFormFieldCheckBox from './FuelcardFormFieldCheckBox';
import { ClipboardEvent, CSSProperties, ReactNode } from 'react';

interface FuelcardFormFieldProps extends CommonFuelcardFormFieldProps {
    value: string | boolean; // Allow boolean for checkboxes
    termsCheckbox?: boolean; // prop to indicate whether it's a terms and conditions checkbox
    scanText?: string;
    messageTypeNfc?: 'success' | 'error' | 'info' | null;
    children?: ReactNode;
    autoFocus?: boolean;
    autoComplete: string;
    onBlur?: () => void;
    onPaste?: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
    minLength?: number;
    maxLength?: number;
    style?: CSSProperties;
}

export default function FuelcardFormField(props: FuelcardFormFieldProps) {
    const { formState } = useFormContext();

    return (
        <div className="form-field-wrapper" style={props.style}>
            <div className="form-field">
                {props.termsCheckbox ? (
                    <FuelcardFormFieldCheckBox {...props} value={props.value as boolean}>
                        {props.children}
                    </FuelcardFormFieldCheckBox>
                ) : (
                    <FuelCardFormFieldTextBox {...props} value={props.value as string} autoFocus={props.autoFocus ? props.autoFocus : false} />
                )}
            </div>
            <span className="validation-message">
                {props.name === 'serialNumber' && (
                    <div
                        style={
                            props.messageTypeNfc === 'error'
                                ? { color: 'red' }
                                : props.messageTypeNfc === 'success'
                                  ? { color: '#155724' }
                                  : { color: '#004085' }
                        }
                    >
                        {props.scanText}
                    </div>
                )}
                <div>{formState.errors[props.name]?.message as React.ReactNode}</div>
            </span>
        </div>
    );
}
