import NotificationToggleMgr from '../../../../model/NotificationToggleMgr';
import { Form } from 'react-bootstrap';
import { toggleNotificationMgr } from '../../../../service/Api';
import CommonDashboardProps from '../../../../props/CommonDashboardProps';
import NotificationType from '../../../../model/NotificationType';
import SetReminderTimeModal from './SetReminderTimeModal';
import { useState } from 'react';
import CsNotificationToggleModal from './CsNotificationToggleModal';

interface NotificationToggleProps extends CommonDashboardProps {
    csKey: string;
    notificationType: string;
    disabled?: boolean;
}

export default function NotificationToggle(props: NotificationToggleProps) {
    const [showReminderTimeModal, setShowReminderTimeModal] = useState(false);
    const [showNotificationToggleModal, setShowNotificationToggleModal] = useState(false);

    const toggleNotification = (toggle: NotificationToggleMgr) => {
        if (toggle.notificationType === NotificationType.STATION_DOWN_REMINDER) {
            if (!toggle.enabled) {
                setShowReminderTimeModal(true);
                return;
            } else {
                handleToggleNotificationMgr(toggle);
                return;
            }
        }
        if (toggle.enabled || toggle.pushEnabled) {
            handleToggleNotificationMgr({ ...toggle, enabled: false, pushEnabled: false });
        } else {
            setShowNotificationToggleModal(true);
        }
    };

    const handleToggleNotificationMgr = async (toggle: NotificationToggleMgr) => {
        try {
            if (toggle.notificationType === NotificationType.STATION_DOWN_REMINDER) {
                toggle.enabled = !toggle.enabled;
            }
            await toggleNotificationMgr(toggle);
            props.refetchNotificationsMgr();
        } catch (error) {
            props.setTransactionMessage('Something went wrong, please try again later.');
            props.setShowMessageModal(true);
        }
    };

    const isToggleForThisChargingStationAndNotificationType = (notificationMgr: NotificationToggleMgr) => {
        return notificationMgr.chargingStation.key === props.csKey && notificationMgr.notificationType === props.notificationType;
    };

    return props.notificationToggleMgr?.data
        ?.filter(isToggleForThisChargingStationAndNotificationType)
        .map((notificationMgr: NotificationToggleMgr) => (
            <div key={props.csKey + ' - ' + notificationMgr.notificationType}>
                <CsNotificationToggleModal
                    key={notificationMgr.notificationType + 'modal'}
                    show={showNotificationToggleModal}
                    onHide={() => setShowNotificationToggleModal(false)}
                    handleToggleNotification={handleToggleNotificationMgr}
                    notification={notificationMgr}
                />
                <Form.Check
                    type="switch"
                    id={notificationMgr.notificationType}
                    onChange={() => toggleNotification(notificationMgr)}
                    checked={notificationMgr.enabled || notificationMgr.pushEnabled}
                    disabled={props.disabled === true}
                />
                <SetReminderTimeModal
                    show={showReminderTimeModal}
                    setShow={setShowReminderTimeModal}
                    toggle={notificationMgr}
                    doSetTime={handleToggleNotificationMgr}
                />
            </div>
        ));
}
