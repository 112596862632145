import ChargingStation from '../../model/ChargingStation';
import StatusColours from '../../model/StatusColours';
import ChargingTime from '../dashboard/dashboardIslands/dashboardItemValues/ChargingTime';
import CsNumber from '../dashboard/dashboardIslands/dashboardItemValues/CsNumber';
import Kwh from '../dashboard/dashboardIslands/dashboardItemValues/Kwh';
import OccupationTime from '../dashboard/dashboardIslands/dashboardItemValues/OccupationTime';
import Initials from './tvdashboardItemValues/Initials';
import Status from './tvdashboardItemValues/Status';

interface DashboarItemProps {
    chargingStation: ChargingStation;
}

export default function DashboardItem(props: DashboarItemProps) {
    // remade function so the colours can easily be changed if needed
    function getStatusColours(status: string): StatusColours {
        switch (status) {
            case 'SUSPENDED_EV':
                return { border: 'rgba(255, 211, 47, 0.6)', background: 'rgba(255, 211, 47, 0.30)' };
            case 'AVAILABLE':
                return { border: 'rgba(0, 159, 123, 0.25)', background: 'rgba(0, 159, 123, 0.20)' };
            case 'CHARGING':
                return { border: 'rgba(130, 111, 175, 0.40)', background: 'rgba(130, 111, 175, 0.30)' };
            case 'DOWN':
            case 'FAULTED':
                return { border: 'rgba(255, 93, 104, 0.25)', background: 'rgba(255, 93, 104, 0.20)' };
            default:
                return { border: 'rgba(64, 64, 75, 0.4)', background: 'rgba(64, 64, 75, 0.3)' };
        }
    }

    return (
        <>
            <div
                className={`dashboard-grid-item-container user`}
                style={{
                    borderColor: getStatusColours(props.chargingStation.comStatus === 'DOWN' ? 'DOWN' : props.chargingStation.ocppStatus).border
                }}
            >
                <div className="userContainer">
                    <CsNumber {...props} />
                    <Initials initials={props.chargingStation.initials} />
                    <Status
                        getStatusColours={getStatusColours}
                        status={props.chargingStation.comStatus === 'DOWN' ? props.chargingStation.comStatus : props.chargingStation.ocppStatus}
                    />
                    <Kwh kWh={props.chargingStation.kWh} />
                    <ChargingTime chargingTime={props.chargingStation.chargingTime} />
                    <OccupationTime
                        isAdminOrManager={false}
                        occupationTime={props.chargingStation.occupationTime}
                        chargingTime={props.chargingStation.chargingTime}
                        status={props.chargingStation.ocppStatus}
                    />
                </div>
            </div>
        </>
    );
}
