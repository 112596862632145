import React, { useEffect, useState } from 'react';
import './App.css';
import FuelCardForm from './pages/fuelcard/registration/FuelcardForm';
import Dashboard from './pages/dashboard/Dashboard';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './pages/login/LoginPage';
import { DOCUMENT_TITLE } from './config';
import SearchFuelcardPage from './pages/search/SearchFuelcardPage';
import FaqPage from './pages/faq/FaqPage';
import AnnouncementManagementPage from './pages/management/announcement/AnnouncementManagementPage';
import CarInfoManagementPage from './pages/management/car/carinfo/CarInfoManagementPage';
import { isSupported, onMessage } from 'firebase/messaging';
import MyPoolPage from './pages/pool/MyPoolPage';
import NotFoundPage from './pages/notfound/NotFoundPage';
import CarPage from './pages/car/CarPage';
import UserCarManagementPage from './pages/management/car/usercar/UserCarManagementPage';
import MessageModal from './pages/dashboard/MessageModal';
import { initializeTheme } from './service/DarkThemeFunctions';
import { isOnMobile } from './firebase/firebaseUtils';
import TvDashboard from './pages/tvdashboard/TvDashboard';
import WallOfFame from './pages/leaderboard/WallOfFame';
import FuelcardPage from './pages/fuelcard/page/FuelcardPage';
import NotificationManagementPage from './pages/management/notification/NotificationManagementPage';
import InactiveUserSearchPage from './pages/management/user/inactive/InactiveUserSearchPage';
import ChargingHistoryPage from './pages/charginghistory/ChargingHistoryPage';

const queryClient = new QueryClient();

function App() {
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        (async () => {
            const hasFirebaseMessagingSupport = await isSupported();
            if (hasFirebaseMessagingSupport) {
                const { requestForToken } = await import('./firebase/firebaseConfig');
                await requestForToken();
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const hasFirebaseMessagingSupport = await isSupported();
            if (hasFirebaseMessagingSupport) {
                const { messaging } = await import('./firebase/firebaseConfig');

                const unsubscribe = onMessage(messaging, (payload) => {
                    const notificationTitle = payload.data?.title || 'New Notification';
                    const notificationBody = payload.data?.body || 'You have a new notification.';
                    const isMobileOnly = payload.data?.isMobileOnly || 'false';

                    const showNotification = (notificationTitle: string, notificationBody: string) => {
                        setMessage(notificationBody);
                        setTitle(notificationTitle);
                        setShowMessageModal(true);
                    };

                    if (isMobileOnly === 'true') {
                        if (isOnMobile()) {
                            showNotification(notificationTitle, notificationBody);
                        }
                    } else {
                        showNotification(notificationTitle, notificationBody);
                    }
                });

                return () => {
                    unsubscribe();
                };
            }
        })();
    }, []);

    useEffect(() => {
        document.title = DOCUMENT_TITLE;
    }, []);

    initializeTheme();

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <Routes>
                    <Route path="/register" element={<FuelCardForm />} />
                    <Route path="/registration" element={<FuelCardForm />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/tvdashboard" element={<TvDashboard />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/walloffame" element={<WallOfFame />} />
                    <Route path="/fuelcard/search" element={<SearchFuelcardPage />} />
                    <Route path="/faq" element={<FaqPage />} />
                    <Route path="/management/announcement" element={<AnnouncementManagementPage />} />
                    <Route path="/management/notification" element={<NotificationManagementPage />} />
                    <Route path="/management/car/carinfo" element={<CarInfoManagementPage />} />
                    <Route path="/management/car/usercar" element={<UserCarManagementPage />} />
                    <Route path="/management/user/inactive" element={<InactiveUserSearchPage />} />
                    <Route path="/pool" element={<MyPoolPage />} />
                    <Route path="/car" element={<CarPage />} />
                    <Route path="/fuelcard" element={<FuelcardPage />} />
                    <Route path="/charginghistory" element={<ChargingHistoryPage />} />
                    <Route path="/*" element={<NotFoundPage />} />
                </Routes>
            </Router>
            <MessageModal show={showMessageModal} onHide={() => setShowMessageModal(false)} message={message} title={title} />
        </QueryClientProvider>
    );
}

export default App;
