import { useNavigate } from 'react-router-dom';
import NavigationBar from '../shared/NavigationBar';
import Footer from '../shared/Footer';
import { Button } from 'react-bootstrap';

export default function NotFoundPage() {
    const navigate = useNavigate();

    return (
        <>
            <div style={{ minHeight: 'var(--bs-content-min-heigth)' }} className={'d-flex flex-column'}>
                <NavigationBar />
                <div
                    style={{ padding: '0 4vw', textAlign: 'center' }}
                    className="d-flex flex-column align-items-center justify-content-center flex-grow-1"
                >
                    <h1>Page not found</h1>
                    <p>
                        The requested page <span style={{ fontStyle: 'italic' }}>"{window.location.pathname}"</span> does not exist.
                    </p>
                    <p>If you believe this page should exist, please contact the website administrator.</p>
                    <Button variant="primary" style={{ width: '20em', maxWidth: '95vw' }} onClick={() => navigate('/')}>
                        Go back to the dashboard
                    </Button>
                </div>
            </div>
            <Footer />
        </>
    );
}
