import { SortType } from '../../model/enum/SortType';
import NoSortIcon from './svg/NoSortIcon';
import SortUpIcon from './svg/SortUpIcon';
import SortDownIcon from './svg/SortDownIcon';

interface TableHeaderProps {
    title: string;
    fieldName: string;
    currentSortField: string;
    currentSortType: SortType;
    onSortChange: (field: string) => void;
    defaultSort?: string;
}

export default function TableHeader(props: TableHeaderProps) {
    const defaultSort = props.defaultSort ?? 'firstName';

    const getSortIcon = () => {
        if (props.fieldName === props.currentSortField) {
            switch (props.currentSortType) {
                case SortType.NO_SORT:
                    if (props.currentSortField === defaultSort) {
                        return <SortUpIcon />;
                    } else {
                        return <NoSortIcon />;
                    }
                case SortType.ASC:
                    return <SortUpIcon />;
                case SortType.DESC:
                    return <SortDownIcon />;
            }
        }
        return <NoSortIcon />;
    };

    return (
        <th>
            {props.title}{' '}
            <span style={{ cursor: 'pointer' }} onClick={() => props.onSortChange(props.fieldName)}>
                {getSortIcon()}
            </span>
        </th>
    );
}
