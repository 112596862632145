import CommonFuelcardFormFieldProps from '../../../props/CommonFuelcardFormFieldProps';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { ClipboardEvent, useState } from 'react';
import FuelcardFormData from '../../../model/FuelcardFormData';
import Eye from '../../shared/icon/Eye';
import EyeSlash from '../../shared/icon/EyeSlash';

interface FuelCardFormFieldTextBoxProps extends CommonFuelcardFormFieldProps {
    value: string;
    autoFocus: boolean;
    autoComplete: string;
    onBlur?: () => void;
    onPaste?: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
    maxLength?: number;
}

export default function FuelCardFormFieldTextBox(props: FuelCardFormFieldTextBoxProps) {
    const [inputType, setInputType] = useState<string>(props.type);

    const toggleInputType = () => {
        if (inputType !== 'password') {
            setInputType('password');
        } else {
            setInputType('text');
        }
    };

    const getClassNames = () => {
        let classNames = 'form-control';

        if (props.name === 'serialNumber') {
            classNames += ' serialNumber';
        }
        if (props.readOnly) {
            classNames += ' disabled';
        }

        return classNames;
    };

    return (
        <div className="form-group row">
            <InputGroup>
                <InputGroup.Text className="fuelcardForm-input-pretext">{props.label}:</InputGroup.Text>
                <FormControl
                    className={getClassNames()}
                    type={inputType}
                    autoComplete={props.autoComplete}
                    {...props.register(props.name as keyof FuelcardFormData, props.registerOptions)}
                    value={props.value}
                    onChange={props.onChange}
                    readOnly={props.readOnly}
                    placeholder={props.placeHolder}
                    maxLength={props.maxLength === undefined ? 50 : props.maxLength}
                    autoFocus={props.autoFocus}
                    onBlur={props.onBlur}
                    onPaste={props.onPaste}
                />
                <Button
                    hidden={props.type !== 'password'}
                    variant="outline-secondary"
                    onClick={toggleInputType}
                    className="fuelcardForm-input-postText"
                >
                    <Eye hidden={inputType !== 'password'} className="fuelcardForm-input-pwEye" alt="Hide password" />
                    <EyeSlash hidden={inputType === 'password'} className="fuelcardForm-input-pwEye" alt="Show password" />
                </Button>
            </InputGroup>
        </div>
    );
}
