import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Container, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getFuelCardsOfUser, deleteUserFuelcard } from '../../../service/Api';
import UserFuelcard from '../../../model/fuelcard/UserFuelcard';
import NavigationBar from '../../shared/NavigationBar';
import LoadingSpinner from '../../shared/LoadingSpinner';
import FuelcardTable from './FuelcardTable';
import FuelcardModals from './FuelcardModals';

interface FuelcardState {
    editMode: boolean;
    showDeleteModal: boolean;
    validationMessage: string;
}

export default function FuelcardPage() {
    const navigate = useNavigate();
    const {
        data: fuelcardData,
        isLoading: isLoadingFuelcards,
        refetch
    } = useQuery<UserFuelcard[]>('userFuelcards', getFuelCardsOfUser, { refetchOnWindowFocus: false });

    const [state, setState] = useState<{ [key: string]: FuelcardState }>({});
    const [updatedDisplayNames, setUpdatedDisplayNames] = useState<{ [key: string]: string }>({});

    const deleteFuelcard = async (id: string) => {
        try {
            await deleteUserFuelcard(id);
            refetch();
        } catch (error) {
            console.error(error);
        }
        setState((prevState) => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                showDeleteModal: false
            }
        }));
    };

    if (isLoadingFuelcards) {
        return (
            <div style={{ minHeight: 'var(--bs-content-min-height)' }}>
                <NavigationBar />
                <div style={{ marginTop: '5rem', textAlign: 'center' }}>
                    <LoadingSpinner />
                    <p style={{ marginTop: '2vh' }}>Loading your car ...</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <NavigationBar />
            <Container>
                <h2 style={{ marginTop: '1rem', marginBottom: '1rem', textAlign: 'center' }}>My Fuelcards</h2>
                {fuelcardData?.length ? (
                    <FuelcardTable
                        fuelcardData={fuelcardData}
                        state={state}
                        setState={setState}
                        updatedDisplayNames={updatedDisplayNames}
                        setUpdatedDisplayNames={setUpdatedDisplayNames}
                        refetch={refetch}
                    />
                ) : (
                    <div className="text-center mt-4">
                        <p>It looks like you have no registered fuelcards yet. Please click the button to register your first fuelcard.</p>
                    </div>
                )}
                <Row className="justify-content-center mt-3 mb-3">
                    <Button variant="success" size="sm" style={{ width: '150px' }} onClick={() => navigate('/registration')}>
                        Add New Fuelcard
                    </Button>
                </Row>
            </Container>
            <FuelcardModals fuelcardData={fuelcardData} state={state} setState={setState} deleteFuelcard={deleteFuelcard} />
        </>
    );
}
