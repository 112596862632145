import NotificationToggle from '../../../model/NotificationToggle';
import { Form } from 'react-bootstrap';
import NotificationToggleModal from './NotificationToggleModal';
import { useState } from 'react';
import NotificationType from '../../../model/NotificationType';

interface UserNotificationToggleFormProps {
    notification: NotificationToggle;
    handleToggleNotification: (toggle: NotificationToggle) => Promise<void>;
}

export default function UserNotificationToggleForm(props: UserNotificationToggleFormProps) {
    const [showNotificationToggleModal, setShowNotificationToggleModal] = useState(false);

    const handleToggleChange = () => {
        if (props.notification.notificationType === NotificationType.AVAILABLE_STATION) {
            props.handleToggleNotification(props.notification);
        } else {
            if (props.notification.enabled || props.notification.pushEnabled) {
                props.handleToggleNotification({ ...props.notification, enabled: false, pushEnabled: false });
            } else {
                setShowNotificationToggleModal(true);
            }
        }
    };

    return (
        <>
            <NotificationToggleModal
                key={props.notification.notificationType + 'modal'}
                show={showNotificationToggleModal}
                onHide={() => setShowNotificationToggleModal(false)}
                {...props}
            />
            <Form.Check
                style={{ marginLeft: '10%' }}
                key={props.notification.notificationType}
                type="switch"
                id={props.notification.notificationType}
                onChange={handleToggleChange}
                checked={props.notification.enabled || props.notification.pushEnabled}
            />
        </>
    );
}
