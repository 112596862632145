import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import StartTransactionModalProps from '../../../props/StartTransactionModalProps';
import { startTransaction } from '../../../service/Api';
import { useState } from 'react';
import SelectFuelCardList from './SelectFuelCardList';
import { Link } from 'react-router-dom';

export default function StartTransactionModal(props: StartTransactionModalProps) {
    const [selectedFuelCardId, setSelectedFuelCardId] = useState('');
    const [searchText, setSearchText] = useState('');

    const handleStartTransaction = async () => {
        try {
            props.onHide();
            await startTransaction(props.location, props.chargingStation.nr, selectedFuelCardId.trim());
        } catch (error) {
            props.setTransactionMessage('Could not start transaction, something went wrong.');
            props.onHide();
            props.setShowMessage(true);
        } finally {
            setSelectedFuelCardId('');
        }
    };

    function handleClose(): void {
        props.setShowStartTransactionModal(false);
        setSelectedFuelCardId('');
    }

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Start a charging session for station #{props.chargingStation.nr}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="startTransactionModalBody">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ margin: '0', textAlign: 'left' }}>Fuelcard owner</p>
                        <Link
                            to={{ pathname: '/fuelcard/search' }}
                            state={{ searchText: searchText, location: props.location, displayName: props.displayName, nr: props.chargingStation.nr }}
                            style={{ margin: '0', textAlign: 'right' }}
                        >
                            Use search page
                        </Link>
                    </div>
                    <div style={{ margin: '0', textAlign: 'left' }}>
                        <SelectFuelCardList setSelectedFuelCard={setSelectedFuelCardId} setSearchText={setSearchText} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button className="startTransactionButton" disabled={!(selectedFuelCardId.length > 0)} onClick={handleStartTransaction}>
                    Start Transaction
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
