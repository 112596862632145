import { InputGroup } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { SingleValue } from 'react-select';
import { RegisterOptions, useFormContext, UseFormRegister } from 'react-hook-form';
import FuelcardFormData from '../../../model/FuelcardFormData';

interface FuelCardFormFieldSelectProps {
    name: string;
    label: string;
    placeholder: string;
    value: string;
    setValue: (brandName: string) => void;
    possibleValues: string[];
    possibleValuesLoading: boolean;
    registerOptions?: RegisterOptions;
    register: UseFormRegister<FuelcardFormData>;
}

interface Option {
    value: string;
    label: string;
}

export default function FuelCardFormFieldSelect(props: FuelCardFormFieldSelectProps) {
    const { formState } = useFormContext();

    const customFilterOption = (option: FilterOptionOption<Option>, inputValue: string) => {
        return option.value.toLowerCase().includes(inputValue.toLowerCase());
    };

    const getSelectOptions = (possibleVals: string[] | undefined) => {
        if (!possibleVals) {
            return [];
        }
        return possibleVals.map((value) => {
            return { value: value, label: value };
        });
    };

    const mapToOption = (value: string | null | undefined): Option | null => {
        if (!value) return null;
        return { value: value, label: value };
    };

    return (
        <div className="form-field-wrapper">
            <InputGroup>
                <InputGroup.Text className="fuelcardForm-input-pretext">{props.label}:</InputGroup.Text>
                <CreatableSelect
                    classNamePrefix="react-select"
                    placeholder={props.placeholder}
                    className="selectDropdown"
                    isClearable
                    options={getSelectOptions(props.possibleValues)}
                    filterOption={customFilterOption}
                    isLoading={props.possibleValuesLoading}
                    onChange={(newVal: SingleValue<any>) => props.setValue(newVal ? newVal.value : '')}
                    // @ts-ignore
                    value={mapToOption(props.value)}
                    menuPlacement="auto"
                />
            </InputGroup>
            <input style={{ display: 'none' }} value={props.value} {...props.register(props.name as keyof FuelcardFormData, props.registerOptions)} />
            <span className="validation-message">
                <div>{formState.errors[props.name]?.message as React.ReactNode}</div>
            </span>
        </div>
    );
}
