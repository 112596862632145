import DateTime from '../model/DateTime';

const formatToIsoString = (toFormat: DateTime): string => {
    const month = formatTo2Numbers(toFormat.month);
    const day = formatTo2Numbers(toFormat.day);
    const hour = formatTo2Numbers(toFormat.hour);
    const minute = formatTo2Numbers(toFormat.minute);
    const timeZone = formatTo2Numbers((-(new Date().getTimezoneOffset() / 60)).toString());

    return `${toFormat.year}-${month}-${day}T${hour}:${minute}:00+${timeZone}:00`;
};

const formatTo2Numbers = (input: string): string => {
    if (input.length > 1) {
        return input;
    } else if (input.length > 0) {
        return '0' + input;
    } else {
        return '00';
    }
};

export { formatToIsoString, formatTo2Numbers };
