import { ChangeEvent } from 'react';
import FuelcardFormField from '../FuelcardFormField';
import FuelcardFormData from '../../../../model/FuelcardFormData';
import { SetValueConfig, UseFormReturn } from 'react-hook-form';

interface UserFoundInCronosCentralInfoProps {
    fuelcard: FuelcardFormData;
    isAdminOrManager: boolean;
    handleChange: <K extends keyof FuelcardFormData>(
        name: K,
        value: FuelcardFormData[K],
        setValue: (name: K, value: FuelcardFormData[K], options?: Partial<SetValueConfig>) => void
    ) => void;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
}

export default function UserFoundInCronosCentralInfo(props: UserFoundInCronosCentralInfoProps) {
    return (
        <>
            <p>User not found in our own database but was found in cronos central:</p>

            <p className={'uar-user-preview'}>
                {props.fuelcard.firstName} {props.fuelcard.lastName} ({props.fuelcard.contactEmail} {props.fuelcard.primaryEmail})
            </p>
            <p className={'uar-car-previs'}>
                {props.fuelcard.model} {props.fuelcard.brand} {props.fuelcard.licensePlate}
            </p>

            <FuelcardFormField
                label="Mobile"
                type="text"
                name="mobile"
                autoComplete="tel"
                value={props.fuelcard.mobile}
                readOnly={!props.isAdminOrManager}
                onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange('mobile', e.target.value, props.methods.setValue)}
                register={props.methods.register}
                registerOptions={{
                    required: 'Mobile is required.',
                    pattern: {
                        value: /^\+?\d{9,13}$/,
                        message: 'Valid formats: "0468330850" or "+32468330850" or "0032468330850"'
                    }
                }}
            />
        </>
    );
}
