import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../config';

const axiosInstance = axios.create({
    baseURL: API_URL
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        const appVersion = process.env.REACT_APP_VERSION;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers['App-Version'] = appVersion;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        try {
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data;
                if (!(typeof errorMessage !== 'string')) {
                    return Promise.reject(error);
                }

                if (errorMessage.includes('Version Mismatch')) {
                    // Clear browser cache and reload
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    caches.keys().then((names) => {
                        names.forEach((name) => caches.delete(name));
                    });
                    window.location.href = '/login';
                }
            }
            if (error.response && error.response.status === 401) {
                const problemDetail = error.response.data;
                if (problemDetail.type.includes('InsufficientAuthenticationException')) {
                    window.location.href = '/login';
                }
            }
        } catch (e) {
            console.debug(e);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
