import React from 'react';
import { Table } from 'react-bootstrap';
import UserFuelcard from '../../../model/fuelcard/UserFuelcard';
import FuelcardRow from './FuelcardRow';
import { updateFuelcardDisplayName } from '../../../service/Api';
import FuelcardState from './FuelcardState';
import InformationPopover from '../../shared/InformationPopover';

interface FuelcardTableProps {
    fuelcardData: UserFuelcard[] | undefined;
    state: { [key: string]: FuelcardState };
    setState: React.Dispatch<React.SetStateAction<{ [key: string]: FuelcardState }>>;
    updatedDisplayNames: { [key: string]: string };
    setUpdatedDisplayNames: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    refetch: () => void;
}

export default function FuelcardTable({ fuelcardData, state, setState, updatedDisplayNames, setUpdatedDisplayNames, refetch }: FuelcardTableProps) {
    const handleInputChange = (id: string, value: string, originalName: string) => {
        const regex = /^[a-zA-Z0-9\-_/ ']*$/;

        if (value === originalName) {
            setState((prevState) => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    validationMessage: ''
                }
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    validationMessage:
                        value.match(regex) && value.length <= 255
                            ? ''
                            : "Fuelcard display name should only contain a-z, A-Z, 0-9, /, -, _, ' ' and be 1-255 characters long."
                }
            }));
        }

        if (value.match(regex) && value.length <= 255) {
            setUpdatedDisplayNames((prevState) => ({
                ...prevState,
                [id]: value
            }));
        }
    };

    const saveDisplayName = async (fuelcard: UserFuelcard) => {
        const updatedName = updatedDisplayNames[fuelcard.fuelcardId];
        if (updatedName === undefined) {
            setState((prevState) => ({
                ...prevState,
                [fuelcard.fuelcardId]: {
                    ...prevState[fuelcard.fuelcardId],
                    editMode: false,
                    validationMessage: ''
                }
            }));
            return;
        }
        if (updatedName && updatedName.length > 0) {
            try {
                setState((prevState) => ({
                    ...prevState,
                    [fuelcard.fuelcardId]: {
                        ...prevState[fuelcard.fuelcardId],
                        editMode: false,
                        validationMessage: ''
                    }
                }));
                fuelcard.displayName = updatedName;
                await updateFuelcardDisplayName(fuelcard);
                refetch();
            } catch (error) {
                console.error(error);
            }
        } else {
            setState((prevState) => ({
                ...prevState,
                [fuelcard.fuelcardId]: {
                    ...prevState[fuelcard.fuelcardId],
                    validationMessage: "Fuelcard display name should only contain the following chars: a-z, A-Z, 0-9, /, -, _, ' '"
                }
            }));
        }
    };

    return (
        <Table striped bordered hover responsive style={{ overflow: 'hidden' }}>
            <thead>
                <tr>
                    <th style={{ minWidth: '100px' }}>Display Name</th>
                    <th style={{ width: '200px' }}>
                        Last Used
                        <InformationPopover infoHeader="Last Used" desktopOverLayPlacement="left" mobileOverLayPlacement="bottom">
                            <p>
                                This is only updated when you use your fuelcard at one of the office charging locations. When this fuelcard has not
                                been used yet, the creation date will be shown.
                            </p>
                        </InformationPopover>
                    </th>
                    <th style={{ width: '200px' }}>Actions</th>
                </tr>
            </thead>
            <tbody>
                {fuelcardData?.map((fuelcard: UserFuelcard) => (
                    <FuelcardRow
                        key={fuelcard.fuelcardId}
                        fuelcard={fuelcard}
                        state={state}
                        setState={setState}
                        updatedDisplayNames={updatedDisplayNames}
                        handleInputChange={handleInputChange}
                        saveDisplayName={saveDisplayName}
                    />
                ))}
            </tbody>
        </Table>
    );
}
