import { ReactElement, useState } from 'react';
import { isAxiosError } from 'axios';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import '../../styling/ApiError.css';
import Copy from './icon/Copy';
import ToastMessage from './ToastMessage';
import FeedbackOffCanvas from '../feedback/FeedbackOffCanvas';
import ChatLeftDots from './icon/ChatLeftDots';
import LoginRedirect from '../login/LoginRedirect';

interface ApiErrorProps {
    error: unknown;
    title?: ReactElement | string;
    message?: ReactElement;
}

export default function ApiError(props: ApiErrorProps) {
    const [submittingFeedback, setSubmittingFeedback] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    let message = props.message;
    if (message === undefined) {
        message = <p>Something unexpected went wrong, please try again. If this error persists, please contact the website maintainer.</p>;
    }

    let detail = <></>;

    if (isAxiosError(props.error) && props.error.response != null && props.error.response.data != null) {
        const errorResponse = props.error.response.data;

        if (errorResponse.status === 401) {
            return <LoginRedirect />;
        }

        const errorDetails = `Status: ${errorResponse.status},
                Type: ${errorResponse.type},
                Detail: ${errorResponse.detail},
                Trace: ${errorResponse.instance}`;

        const doCopy = (text: string) => {
            navigator.clipboard.writeText(text);
            setShowToast(true);
        };

        detail = (
            <>
                <hr />
                <p>Please provide the following details when contacting us:</p>
                {errorResponse.status ? (
                    <p className="error-detail">
                        <span style={{ fontWeight: 'bolder' }}>Status:</span> <span style={{ fontStyle: 'italic' }}>{errorResponse.status}</span>
                    </p>
                ) : (
                    <></>
                )}
                {errorResponse.type ? (
                    <p className="error-detail">
                        <span style={{ fontWeight: 'bolder' }}>Type:</span> <span style={{ fontStyle: 'italic' }}>{errorResponse.type}</span>
                    </p>
                ) : (
                    <></>
                )}
                {errorResponse.detail ? (
                    <p className="error-detail">
                        <span style={{ fontWeight: 'bolder' }}>Detail:</span> <span style={{ fontStyle: 'italic' }}>{errorResponse.detail}</span>
                    </p>
                ) : (
                    <></>
                )}
                {errorResponse.instance ? (
                    <p className="error-detail">
                        <span style={{ fontWeight: 'bolder' }}>Trace id:</span> <span style={{ fontStyle: 'italic' }}>{errorResponse.instance}</span>
                    </p>
                ) : (
                    <></>
                )}

                <ButtonGroup style={{ marginTop: '1em', width: 'fit-content' }}>
                    <Button style={{ width: 'fit-content' }} variant={'outline-secondary'} onClick={() => doCopy(errorDetails)}>
                        Copy to clipboard <Copy />
                    </Button>
                    <Button style={{ width: 'fit-content' }} variant={'outline-primary'} onClick={() => setSubmittingFeedback(true)}>
                        <ChatLeftDots /> Submit feedback
                    </Button>
                </ButtonGroup>

                <FeedbackOffCanvas show={submittingFeedback} setShow={setSubmittingFeedback} errorDetails={errorDetails} />
            </>
        );
    }

    return (
        <>
            <ToastMessage message="Copied error details to clipboard." showToastMessage={showToast} setShowToastMessage={setShowToast} />
            <Alert variant="danger" className="api-error-holder">
                <Alert.Heading> {props.title ?? <>Something went wrong</>}</Alert.Heading>
                {message}
                {detail}
            </Alert>
        </>
    );
}
