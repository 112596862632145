import { Button, FormControl, InputGroup } from 'react-bootstrap';
import FuelcardFormField from '../FuelcardFormField';
import { ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { getCarBrandNames, getCarByLicensePlate, getCarModelNames, getUserById } from '../../../../service/Api';
import FuelcardFormData from '../../../../model/FuelcardFormData';
import { SetValueConfig, UseFormReturn } from 'react-hook-form';
import FuelCardFormFieldSelect from '../FuelCardFormFieldSelect';
import { AxiosResponse } from 'axios';
import { MngrUserRegistrationState } from '../../../../model/MngrUserRegistrationState';
import Car from '../../../../model/car/Car';
import UserDto from '../../../../model/user/UserDto';
import { Variant } from 'react-bootstrap/types';
import licensePlateRegex from '../../../../model/LicensePlateRegex';

interface CarRegistrationProps {
    fuelcard: FuelcardFormData;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
    handleChange: <K extends keyof FuelcardFormData>(
        name: K,
        value: FuelcardFormData[K],
        setValue: (name: K, value: FuelcardFormData[K], options?: Partial<SetValueConfig>) => void
    ) => void;
    userCar: AxiosResponse<any, any> | undefined;
    mngrUserRegistrationState: MngrUserRegistrationState;
    setFuelCard: (value: React.SetStateAction<FuelcardFormData>) => void;
    setShowOtherUserCarModal: React.Dispatch<React.SetStateAction<boolean>>;
    userLoginId: number;
    isAdminOrManager: boolean;
    setToast: React.Dispatch<React.SetStateAction<{ message: string; bg?: Variant; autoHide: boolean; title?: string }>>;
    setUsePrefilledCar: React.Dispatch<React.SetStateAction<boolean | null>>;
    usePrefilledCar: boolean | null;
}

export default function CarRegistration(props: CarRegistrationProps) {
    const { data: carBrands, isLoading: isLoadingCarBrands } = useQuery('carBrandNames', () => getCarBrandNames(), { refetchOnWindowFocus: false });

    const { data: carModels, isLoading: isLoadingCarModels } = useQuery(
        ['carModelNames', props.fuelcard.brand],
        () => getCarModelNames(props.fuelcard.brand),
        {
            refetchOnWindowFocus: false,
            enabled: props.fuelcard.brand != null && props.fuelcard.brand !== ''
        }
    );

    const onBlurLicensePlate = async () => {
        try {
            if (props.fuelcard.licensePlate === '' || !licensePlateRegex.test(props.fuelcard.licensePlate)) {
                return;
            }
            const carResp = await getCarByLicensePlate(props.fuelcard.licensePlate);
            const car: Car = carResp?.data;

            if (props.isAdminOrManager) {
                const userResp = await getUserById(car.userId);
                const user: UserDto = userResp?.data;

                if (user.contactEmail.toLowerCase() !== props.fuelcard.contactEmail.toLowerCase()) {
                    props.setToast({
                        message: `The license plate you have entered belongs to ${user.firstName} ${user.lastName}, not to ${props.fuelcard.firstName} ${props.fuelcard.lastName}.  If you want to assign ${props.fuelcard.licensePlate} car to ${props.fuelcard.firstName}, you need to delete it first via the fuelcard search page -> user car management.`,
                        autoHide: false,
                        bg: 'warning'
                    });
                }
            } else {
                if (car != null && car.userId != null && car.userId !== props.userLoginId && !car.reassignable) {
                    props.setShowOtherUserCarModal(true);
                    return;
                }
            }

            if (car && car.brand && car.model) {
                props.methods.setValue('brand', car.brand);
                props.setFuelCard((prevState) => ({
                    ...prevState,
                    brand: car.brand
                }));

                const modelsResponse = await getCarModelNames(car.brand);
                const models = modelsResponse.data.map((model: any) => model.name);

                if (models.includes(car.model)) {
                    props.methods.setValue('model', car.model);
                    props.setFuelCard((prevState) => ({
                        ...prevState,
                        model: car.model
                    }));
                }
            }
        } catch (error) {
            console.error('Unable to find brand or model for given license plate.');
        }
    };

    return (
        <>
            {props.usePrefilledCar === true ? (
                <>
                    <InputGroup>
                        <InputGroup.Text className="fuelcardForm-input-pretext">Car:</InputGroup.Text>
                        <FormControl
                            className={'form-control serialNumber'}
                            type={'text'}
                            autoComplete={'off'}
                            value={props.userCar?.data?.brand + ' ' + props.userCar?.data?.model + ' (' + props.userCar?.data?.licensePlate + ')'}
                            readOnly
                            disabled
                        />
                        <Button variant="outline-secondary" onClick={() => props.setUsePrefilledCar(false)} className="fuelcardForm-input-postText">
                            Change
                        </Button>
                    </InputGroup>
                </>
            ) : (
                <></>
            )}
            {props.usePrefilledCar === false ||
            props.mngrUserRegistrationState === 'newCronos' ||
            props.mngrUserRegistrationState === 'newExternal' ||
            props.mngrUserRegistrationState === 'newXploregroup' ||
            props.mngrUserRegistrationState === 'alreadyRegisteredWithoutCar' ? (
                <>
                    <FuelcardFormField
                        label="License Plate"
                        placeHolder="1-ABC-123"
                        type="text"
                        name="licensePlate"
                        value={props.fuelcard.licensePlate}
                        autoComplete="off"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            props.handleChange('licensePlate', e.target.value.toUpperCase(), props.methods.setValue)
                        }
                        onBlur={onBlurLicensePlate}
                        register={props.methods.register}
                        registerOptions={{
                            required: 'License plate is required.',
                            pattern: {
                                value: licensePlateRegex,
                                message:
                                    "Please enter a valid Belgian or Dutch license plate. Valid Belgian formats: ABC-123, 1-ABC-123, Z-ABC-123. Valid Dutch formats: A-123-BC, ABC-12-D. ('-', ' ', and '.' are accepted separators)"
                            }
                        }}
                    />
                    <FuelCardFormFieldSelect
                        label="Brand"
                        name="brand"
                        placeholder="Select the brand of your car ..."
                        value={props.fuelcard.brand}
                        setValue={(newVal) => props.handleChange('brand', newVal, props.methods.setValue)}
                        possibleValues={carBrands?.data.map((brand: any) => brand.name)}
                        possibleValuesLoading={isLoadingCarBrands}
                        register={props.methods.register}
                        registerOptions={{
                            required: 'Brand is required.',
                            maxLength: 50,
                            pattern: {
                                value: /^[\p{L}0-9 .\-+#]+$/u,
                                message: 'Brand may only contain letters, numbers, spaces, periods, hyphens, plus signs, and hashes'
                            }
                        }}
                    />
                    <FuelCardFormFieldSelect
                        label="Model"
                        name="model"
                        placeholder={'Select the model of your ' + (props.fuelcard.brand !== '' ? props.fuelcard.brand : 'car') + ' ...'}
                        value={props.fuelcard.model}
                        setValue={(newVal) => props.handleChange('model', newVal, props.methods.setValue)}
                        possibleValues={carModels?.data.map((model: any) => model.name)}
                        possibleValuesLoading={isLoadingCarModels}
                        register={props.methods.register}
                        registerOptions={{
                            required: 'Model is required.',
                            maxLength: 50,
                            pattern: {
                                value: /^[\p{L}0-9 .\-+#]+$/u,
                                message: 'Model may only contain letters, numbers, spaces, periods, hyphens, plus signs, and hashes'
                            }
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
