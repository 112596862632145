import { Alert, FormSelect } from 'react-bootstrap';
import { SetValueConfig, UseFormReturn } from 'react-hook-form';
import FuelcardFormData from '../../../../model/FuelcardFormData';
import { MngrUserRegistrationState } from '../../../../model/MngrUserRegistrationState';

interface UserNotFoundProps {
    mngrUserRegistrationState: MngrUserRegistrationState;
    handleChange: <K extends keyof FuelcardFormData>(
        name: K,
        value: FuelcardFormData[K],
        setValue: (name: K, value: FuelcardFormData[K], options?: Partial<SetValueConfig>) => void
    ) => void;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
    setMngrUserRegistrationSearchQuery: (value: React.SetStateAction<string>) => void;
    setFuelCard: (value: React.SetStateAction<FuelcardFormData>) => void;
    fuelcard: FuelcardFormData;
    setMngrUserRegistrationState: React.Dispatch<React.SetStateAction<MngrUserRegistrationState>>;
    mngrUserRegistrationSearchQuery: string;
}

export default function UserNotFound(props: UserNotFoundProps) {
    const handleChangeUserUnknownState = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newState = e.target.value as 'newUnknown' | 'newCronos' | 'newExternal';
        props.setMngrUserRegistrationState(newState);
        if (newState === 'newCronos') {
            if (props.mngrUserRegistrationSearchQuery.toLowerCase().includes('@cronos.be')) {
                props.handleChange('primaryEmail', props.mngrUserRegistrationSearchQuery, props.methods.setValue);
            } else if (!props.mngrUserRegistrationSearchQuery.includes('@')) {
                props.handleChange('primaryEmail', props.mngrUserRegistrationSearchQuery + '@cronos.be', props.methods.setValue);
            } else {
                props.handleChange('contactEmail', props.mngrUserRegistrationSearchQuery, props.methods.setValue);
            }
        } else if (newState === 'newExternal') {
            props.setFuelCard({
                ...props.fuelcard,
                contactEmail: props.mngrUserRegistrationSearchQuery,
                primaryEmail: props.mngrUserRegistrationSearchQuery
            });
            props.methods.setValue('contactEmail', props.mngrUserRegistrationSearchQuery);
            props.methods.setValue('primaryEmail', props.mngrUserRegistrationSearchQuery);
        } else {
            props.setFuelCard({
                ...props.fuelcard,
                contactEmail: '',
                primaryEmail: ''
            });
            props.methods.setValue('contactEmail', '');
            props.methods.setValue('primaryEmail', '');
        }
    };

    return (
        <>
            <p>User not found in our database or Cronos Central.</p>
            <Alert variant={'warning'}>
                If the user you are trying to register is part of Xploregroup, you have entered the wrong search term. Ask the user for their cronos
                username (e.g. doejo@cronos.be for John.Doe@xploregroup.be) and use that to search.
            </Alert>
            <p>
                If the user you are trying to register is part of Cronos but not Xploregroup or is an external user, you can continue to register them
                below:
            </p>
            <FormSelect
                value={
                    props.mngrUserRegistrationState === 'newCronos' || props.mngrUserRegistrationState === 'newExternal'
                        ? props.mngrUserRegistrationState
                        : 'newUnknown'
                }
                onChange={handleChangeUserUnknownState}
                style={{ marginBottom: '1rem' }}
            >
                <option value={'newUnknown'}>Select what type of user you are trying to register</option>
                <option value={'newCronos'}>Cronos employee, not part of Xploregroup</option>
                <option value={'newExternal'}>External user, not part of Cronos</option>
            </FormSelect>
        </>
    );
}
