import { Alert } from 'react-bootstrap';
import FuelcardFormField from '../FuelcardFormField';
import InformationPopover from '../../../shared/InformationPopover';
import { ChangeEvent } from 'react';
import FuelcardFormData from '../../../../model/FuelcardFormData';
import { SetValueConfig, UseFormReturn } from 'react-hook-form';

interface CronosEmployeeEmailFieldsProps {
    fuelcard: FuelcardFormData;
    isAdminOrManager: boolean;
    handleChange: <K extends keyof FuelcardFormData>(
        name: K,
        value: FuelcardFormData[K],
        setValue: (name: K, value: FuelcardFormData[K], options?: Partial<SetValueConfig>) => void
    ) => void;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
}

export default function CronosEmployeeEmailFields(props: CronosEmployeeEmailFieldsProps) {
    return (
        <>
            <div hidden={!props.isAdminOrManager}>
                <Alert variant={'info'} style={{ fontSize: 'small', padding: '0.5em' }}>
                    Non-Xploregroup Cronos employees will be sent an email when you submit this form instructing them to log in to the application
                    within 7 days. If they fail to do this, their account, the fuelcards linked to it, and the car linked to it will be deleted.
                </Alert>
                <FuelcardFormField
                    label={
                        <>
                            Company Email
                            <InformationPopover infoHeader="Company Email">
                                <p>The company email address of the user (e.g. John.Doe@xploregroup.be)</p>
                            </InformationPopover>
                        </>
                    }
                    type="text"
                    name="contactEmail"
                    autoComplete="email"
                    value={props.fuelcard.contactEmail}
                    readOnly={!props.isAdminOrManager}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange('contactEmail', e.target.value, props.methods.setValue)}
                    register={props.methods.register}
                    registerOptions={{
                        required: 'Contact email is required.',
                        pattern: {
                            value: /.+@.+\.+/,
                            message: 'Email should contain a @ and a .'
                        }
                    }}
                />

                <FuelcardFormField
                    label={
                        <>
                            Cronos Email
                            <InformationPopover infoHeader="Cronos Email">
                                <p>The cronos email address of the user (e.g. doejo@cronos.be)</p>
                            </InformationPopover>
                        </>
                    }
                    type="text"
                    name="primaryEmail"
                    autoComplete="email"
                    value={props.fuelcard.primaryEmail}
                    readOnly={!props.isAdminOrManager}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange('primaryEmail', e.target.value, props.methods.setValue)}
                    register={props.methods.register}
                    registerOptions={{
                        required: 'Cronos email is required.',
                        pattern: {
                            value: /.+@.+\.+/,
                            message: 'Email should contain a @ and a .'
                        }
                    }}
                />
            </div>
        </>
    );
}
