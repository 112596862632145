const localStorageKey = 'theme';

type Theme = 'light' | 'dark' | 'auto';

const getSavedTheme = (): Theme => {
    return (localStorage.getItem(localStorageKey) as Theme) ?? 'auto';
};

const saveTheme = (theme: Theme) => {
    localStorage.setItem(localStorageKey, theme);
};

const setTheme = (theme: Theme) => {
    saveTheme(theme);

    if (theme === 'auto') {
        theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    document.documentElement.setAttribute('data-bs-theme', theme);
};

const initializeTheme = () => {
    setTheme(getSavedTheme());
};

export { setTheme, getSavedTheme, saveTheme, initializeTheme };
