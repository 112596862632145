import '../../styling/spinner.css';

interface LoadingSpinnerProps {
    style?: object;
}

export default function LoadingSpinner(props: LoadingSpinnerProps) {
    return (
        <div className="logo-container" style={{ minHeight: '100px', minWidth: '115px', ...props.style }}>
            <img src="/logo_yellow.png" alt="xplore logo" className="logo-side logo-side-1" />
            <img src="/logo_green.png" alt="xplore logo" className="logo-side logo-side-2" />
            <img src="/logo_red.png" alt="xplore logo" className="logo-side logo-side-3" />
            <img src="/logo_purple.png" alt="xplore logo" className="logo-side logo-side-4" />
        </div>
    );
}
