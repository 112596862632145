import UAParser from 'ua-parser-js';

export default function getUserAgent(): UAParser.UAParserInstance | null {
    try {
        return new UAParser(window.navigator.userAgent);
    } catch (e) {
        console.error('Failed to parse user agent', e);
    }

    return null;
}
