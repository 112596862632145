import CronosEmployeeEmailFields from './CronosEmployeeEmailFields';
import ExternalUserEmailField from './ExternalUserEmailField';
import NewCronosOrExternalUserCommonFields from './NewCronosOrExternalUserCommonFields';
import SearchForUserButton from './SearchForUserButton';
import UserFoundInCronosCentralInfo from './UserFoundInCronosCentralInfo';
import UserFoundInDatabaseInfo from './UserFoundInDatabaseInfo';
import UserNotFound from './UserNotFoundInfo';
import FuelcardFormData from '../../../../model/FuelcardFormData';
import { SetValueConfig, UseFormReturn } from 'react-hook-form';
import { Variant } from 'react-bootstrap/types';
import { MngrUserRegistrationState } from '../../../../model/MngrUserRegistrationState';
import { useState } from 'react';
import UserLogin from '../../../../model/user/UserLogin';

interface UserSearchProps {
    fuelcard: FuelcardFormData;
    isAdminOrManager: boolean;
    handleChange: <K extends keyof FuelcardFormData>(
        name: K,
        value: FuelcardFormData[K],
        setValue: (name: K, value: FuelcardFormData[K], options?: Partial<SetValueConfig>) => void
    ) => void;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
    mngrUserRegistrationSearchQuery: string;
    setMngrUserRegistrationSearchQuery: (value: React.SetStateAction<string>) => void;
    mngrUserRegistrationState: MngrUserRegistrationState;
    setFuelCard: (value: React.SetStateAction<FuelcardFormData>) => void;
    setToast: React.Dispatch<React.SetStateAction<{ message: string; bg?: Variant; autoHide: boolean; title?: string }>>;
    setMngrUserRegistrationState: React.Dispatch<React.SetStateAction<MngrUserRegistrationState>>;
    setHideCarRegistration: React.Dispatch<React.SetStateAction<boolean>>;
    autoFocus: boolean;
    userInfo: UserLogin;
}

export default function UserSearch(props: UserSearchProps) {
    const [isSearchingForUser, setIsSearchingForUser] = useState(false);

    return (
        <div className={'fuelcardForm-admin-user-details'} hidden={!props.isAdminOrManager && props.userInfo.phoneNumber.length > 8}>
            <div hidden={!props.isAdminOrManager}>
                <SearchForUserButton {...props} isSearchingForUser={isSearchingForUser} setIsSearchingForUser={setIsSearchingForUser} />
            </div>

            {isSearchingForUser ? (
                <></>
            ) : (
                <>
                    {props.mngrUserRegistrationState === 'alreadyRegisteredWithCar' ||
                    props.mngrUserRegistrationState === 'alreadyRegisteredWithoutCar' ? (
                        <UserFoundInDatabaseInfo {...props} />
                    ) : (
                        <></>
                    )}

                    {props.mngrUserRegistrationState === 'newXploregroup' ? <UserFoundInCronosCentralInfo {...props} /> : <></>}

                    {props.mngrUserRegistrationState === 'newUnknown' ||
                    props.mngrUserRegistrationState === 'newExternal' ||
                    props.mngrUserRegistrationState === 'newCronos' ? (
                        <UserNotFound {...props} />
                    ) : (
                        <></>
                    )}
                    {!props.isAdminOrManager ||
                    props.mngrUserRegistrationState === 'newCronos' ||
                    props.mngrUserRegistrationState === 'newExternal' ? (
                        <>
                            {props.mngrUserRegistrationState === 'newCronos' || !props.isAdminOrManager ? (
                                <CronosEmployeeEmailFields {...props} />
                            ) : (
                                <ExternalUserEmailField {...props} />
                            )}
                            <NewCronosOrExternalUserCommonFields {...props} />
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
}
