import React from 'react';
import { useQuery } from 'react-query';
import { getRecentlyCreatedModels } from '../../../../service/Api';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import Table from 'react-bootstrap/Table';
import CarModel from '../../../../model/car/CarModel';
import moment from 'moment';
import { TIMEZONE } from '../../../shared/Timezone';

const CreatedModelsTable = () => {
    const { data, isLoading, error } = useQuery('recentlyCreatedModels', () => getRecentlyCreatedModels(0, 10));

    function reformatTime(time?: Date | null) {
        if (!time) {
            return '';
        }
        return moment(time).tz(TIMEZONE).format('DD/MM/YYYY HH:mm');
    }

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <p>Error loading recently created models.</p>;
    }

    const models = data?.data?.content || [];

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Model</th>
                    <th>Under Brand</th>
                    <th>Created</th>
                </tr>
            </thead>
            <tbody>
                {models.map((model: CarModel) => (
                    <tr key={model.id}>
                        <td>{model.name}</td>
                        <td>{model.brand.name}</td>
                        <td>{reformatTime(model.createdDate)}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default CreatedModelsTable;
