import React from 'react';
import { useQuery } from 'react-query';
import { getRecentlyCreatedBrands } from '../../../../service/Api';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import Table from 'react-bootstrap/Table';
import CarBrand from '../../../../model/car/CarBrand';
import moment from 'moment';
import { TIMEZONE } from '../../../shared/Timezone';

const CreatedBrandsTable = () => {
    const { data, isLoading, error } = useQuery('recentlyCreatedBrands', () => getRecentlyCreatedBrands(0, 10));

    function reformatTime(time?: Date | null) {
        if (!time) {
            return '';
        }
        return moment(time).tz(TIMEZONE).format('DD/MM/YYYY HH:mm');
    }

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <p>Error loading recently created brands.</p>;
    }

    const brands = data?.data?.content || [];

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Brand</th>
                    <th>Created</th>
                </tr>
            </thead>
            <tbody>
                {brands.map((brand: CarBrand) => (
                    <tr key={brand.id}>
                        <td>{brand.name}</td>
                        <td>{reformatTime(brand.createdDate)}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default CreatedBrandsTable;
