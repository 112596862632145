import { replaceCarModel, updateCarModel } from '../../../../service/Api';
import { useEffect, useState } from 'react';
import '../../../../styling/carInfoManagement.css';
import CarModel from '../../../../model/car/CarModel';
import { Button, FormControl, FormGroup, FormLabel, FormSelect, InputGroup } from 'react-bootstrap';
import ConfirmCarInfoMergeModal from './ConfirmCarInfoMergeModal';
import ToastMessage from '../../../shared/ToastMessage';

interface CarInfoModelManagementProps {
    modelToManage: CarModel;
    allModels: CarModel[];
    refetchAllModels: () => void;
}

export default function CarInfoModelManagement(props: CarInfoModelManagementProps) {
    const [updatedName, setUpdatedName] = useState<string>(props.modelToManage.name);
    const [replacementModel, setReplacementModel] = useState<CarModel | null>(null);
    const [showReplacementModal, setShowReplacementModal] = useState<boolean>(false);

    const [toast, setToast] = useState<{ message: string; show: boolean }>({ message: '', show: false });

    useEffect(() => {
        setUpdatedName(props.modelToManage.name);
        setReplacementModel(null);
    }, [props.modelToManage.name]);

    const updateModel = () => {
        updateCarModel({
            id: props.modelToManage.id,
            name: updatedName,
            brand: props.modelToManage.brand,
            createdDate: props.modelToManage.createdDate
        })
            .then(() => {
                props.refetchAllModels();
            })
            .catch((e) => console.error(e));
    };

    const replaceModel = () => {
        if (replacementModel == null) {
            setToast({ message: 'Please select a replacement model first.', show: true });
            return;
        }
        replaceCarModel(props.modelToManage.brand.id, props.modelToManage.id, replacementModel.id)
            .then(() => {
                setToast({ message: 'Successfully merged brands', show: true });
                setShowReplacementModal(false);
                setReplacementModel(null);
                props.refetchAllModels();
            })
            .catch((e) => {
                console.error(e);
                setToast({
                    message: 'Something went wrong while trying to replace brand, please try again later.',
                    show: true
                });
            });
    };

    return (
        <>
            <ConfirmCarInfoMergeModal
                show={showReplacementModal}
                title={'Confirm merging models'}
                onCancel={() => setShowReplacementModal(false)}
                onConfirm={replaceModel}
                confirmButtonText={'Merge models'}
                cancelButtonText={'Cancel'}
            >
                <>
                    <p>
                        This action will update all existing models of {props.modelToManage.brand.name} from '{props.modelToManage.name}' to be '
                        {replacementModel?.name}'. And it will delete model '{props.modelToManage.name}'.
                    </p>
                    <p>
                        Do you wish to proceed with merging '{props.modelToManage.name}' into '{replacementModel?.name}'?
                    </p>
                </>
            </ConfirmCarInfoMergeModal>
            <ToastMessage message={toast.message} showToastMessage={toast.show} setShowToastMessage={() => setToast({ message: '', show: false })} />

            <FormGroup className="carInfoModelMngmtFormGroup">
                <FormLabel>Update model:</FormLabel>
                <InputGroup style={{ width: '40%', minWidth: 'fit-content' }}>
                    <InputGroup.Text>Change model name:</InputGroup.Text>
                    <FormControl
                        style={{ width: 'fit-content', minWidth: '10em' }}
                        placeholder="Name"
                        value={updatedName}
                        onChange={(e) => setUpdatedName(e.target.value)}
                    />
                    {updatedName !== props.modelToManage.name ? (
                        <Button
                            variant="outline-danger"
                            className="carInfoMngmtInputDecorationButton"
                            onClick={() => setUpdatedName(props.modelToManage.name)}
                        >
                            Revert
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Button variant="outline-secondary" onClick={updateModel} className="carInfoMngmtInputDecorationButton">
                        Update
                    </Button>
                </InputGroup>
            </FormGroup>

            <FormGroup className="carInfoModelMngmtFormGroup">
                <p style={{ margin: '1em 0 0 0' }}>Replace {props.modelToManage.name}:</p>
                <p
                    style={{
                        margin: '0 0 0.2em 0',
                        fontSize: 'x-small',
                        color: 'var(--bs-xploregroup-dark-gray)'
                    }}
                >
                    This option allows you to merge this model ({props.modelToManage.name}) into another model of {props.modelToManage.brand.name}.
                    This will result in this model being deleted and all cars that had this model being updated to have the selected model as their
                    new model.
                </p>
                <InputGroup>
                    <InputGroup.Text>Merge into:</InputGroup.Text>
                    <FormSelect
                        value={replacementModel?.name ?? ''}
                        onChange={(e) => {
                            setReplacementModel(props.allModels.find((model) => model.name === e.target.value) ?? null);
                        }}
                    >
                        <option>Select replacement model ...</option>
                        {props.allModels
                            .filter((m) => m.name !== props.modelToManage.name)
                            .map((toMergeIntoModel) => (
                                <option
                                    key={
                                        'merge-brand-' +
                                        props.modelToManage.brand.id +
                                        '-model-' +
                                        props.modelToManage.id +
                                        '-into-' +
                                        toMergeIntoModel.id
                                    }
                                >
                                    {toMergeIntoModel.name}
                                </option>
                            ))}
                    </FormSelect>
                    <Button
                        variant="outline-danger"
                        className="carInfoMngmtInputDecorationButton"
                        onClick={() => setShowReplacementModal(true)}
                        disabled={replacementModel == null}
                    >
                        Merge
                    </Button>
                </InputGroup>
            </FormGroup>
        </>
    );
}
