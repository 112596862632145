import React, { ChangeEvent } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import UserFuelcard from '../../../model/fuelcard/UserFuelcard';
import FuelcardState from './FuelcardState';
import moment from 'moment';
import { TIMEZONE } from '../../shared/Timezone';

interface FuelcardRowProps {
    fuelcard: UserFuelcard;
    state: { [key: string]: FuelcardState };
    setState: React.Dispatch<React.SetStateAction<{ [key: string]: FuelcardState }>>;
    updatedDisplayNames: { [key: string]: string };
    handleInputChange: (id: string, value: string, originalName: string) => void;
    saveDisplayName: (fuelcard: UserFuelcard) => void;
}

export default function FuelcardRow({ fuelcard, state, setState, updatedDisplayNames, handleInputChange, saveDisplayName }: FuelcardRowProps) {
    function reformatTime(time?: Date | null) {
        if (!time) {
            return '';
        }
        return moment(time).tz(TIMEZONE).format('DD/MM/YYYY HH:mm');
    }

    return (
        <tr key={fuelcard.fuelcardId}>
            <td style={{ verticalAlign: 'middle', maxWidth: '300px' }}>
                {state[fuelcard.fuelcardId]?.editMode ? (
                    <>
                        <Form.Control
                            type="text"
                            value={updatedDisplayNames[fuelcard.fuelcardId] ?? fuelcard.displayName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(fuelcard.fuelcardId, e.target.value, fuelcard.displayName)
                            }
                            placeholder="Enter display name"
                        />
                        <span className="text-danger" style={{ visibility: state[fuelcard.fuelcardId]?.validationMessage ? 'visible' : 'hidden' }}>
                            {state[fuelcard.fuelcardId]?.validationMessage || ' '}
                        </span>
                    </>
                ) : (
                    fuelcard.displayName
                )}
            </td>
            <td style={{ verticalAlign: 'middle' }}>{reformatTime(fuelcard.lastUsed)}</td>
            <td style={{ verticalAlign: 'middle', overflow: 'hidden' }}>
                <Row className="justify-content-center">
                    <Col xs="auto" className="mb-1 mt-1">
                        {state[fuelcard.fuelcardId]?.editMode ? (
                            <Button variant="primary" size="sm" style={{ width: '75px' }} onClick={() => saveDisplayName(fuelcard)}>
                                Save
                            </Button>
                        ) : (
                            <Button
                                variant="secondary"
                                size="sm"
                                style={{ width: '75px' }}
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        [fuelcard.fuelcardId]: {
                                            ...prevState[fuelcard.fuelcardId],
                                            editMode: true,
                                            validationMessage: ''
                                        }
                                    }))
                                }
                            >
                                Edit
                            </Button>
                        )}
                    </Col>
                    <Col xs="auto" className="mb-1 mt-1">
                        {state[fuelcard.fuelcardId]?.editMode ? (
                            <Button
                                variant="secondary"
                                size="sm"
                                style={{ width: '75px' }}
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        [fuelcard.fuelcardId]: {
                                            ...prevState[fuelcard.fuelcardId],
                                            editMode: false
                                        }
                                    }))
                                }
                            >
                                Cancel
                            </Button>
                        ) : (
                            <Button
                                variant="danger"
                                size="sm"
                                style={{ width: '75px' }}
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        [fuelcard.fuelcardId]: {
                                            ...prevState[fuelcard.fuelcardId],
                                            showDeleteModal: true
                                        }
                                    }))
                                }
                            >
                                Delete
                            </Button>
                        )}
                    </Col>
                </Row>
            </td>
        </tr>
    );
}
