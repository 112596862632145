import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useGetUserInfo from '../../hooks/useGetUserInfo';
import MailOpen from '../shared/icon/MailOpen';
import MailPlus from '../shared/icon/MailPlus';

interface MailtoProps {
    mail: string;
    toName?: string;
    subject?: string;
    body?: string;
}

const ButtonMailto = (props: MailtoProps) => {
    const name = useGetUserInfo().firstName;

    const [isHovered, setIsHovered] = useState<boolean>(false);

    const getMailTo = () => {
        let body = props.body;
        if (body == null) {
            body = `Hey${props.toName === undefined ? '' : ' ' + props.toName}, 
            
I would like to start charging at your station could we perhaps swap places so I could start charging?

Thanks in advance,
${name}`;
        }

        let subject = props.subject;
        if (subject == null) {
            subject = 'Charging Station Swap';
        }

        return `mailto:${props.mail}?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
    };

    return (
        <Link
            to="#"
            onClick={(e) => {
                e.preventDefault();
                setIsHovered(false);
                window.location.href = getMailTo();
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocus={() => setIsHovered(true)}
            onBlur={() => setIsHovered(false)}
            style={{ color: 'var(--bs-xploregroup-green)' }}
        >
            {isHovered ? <MailOpen width="18" height="18" /> : <MailPlus width="18" height="18" />}
        </Link>
    );
};

export default ButtonMailto;
