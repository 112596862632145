import '../../styling/feedbackOffCanvas.css';
import { Button, ButtonGroup, Form, FormControl, Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasTitle, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Feedback from '../../model/feedback/Feedback';
import { submitFeedback } from '../../service/Api';
import ToastMessage from '../shared/ToastMessage';
import { FeedBackType } from '../../model/feedback/FeedBackType';
import { isAxiosError } from 'axios';
import LoginRedirect from '../login/LoginRedirect';

interface FeedbackOffCanvasProps {
    show: boolean;
    setShow: (show: boolean) => void;
    errorDetails?: string;
    additionalFeedBackTypes?: FeedBackType[];
    selectedFeedbackType?: FeedBackType;
    defaultMessage?: string;
}

export default function FeedbackOffCanvas(props: FeedbackOffCanvasProps) {
    const [feedback, setFeedback] = useState<Feedback>({
        type: props.selectedFeedbackType ?? null,
        message: props.defaultMessage ?? ''
    });
    const [submittingState, setSubmittingState] = useState<'INITIAL' | 'SUBMITTING' | 'SUBMITTED'>('INITIAL');
    const [submitError, setSubmitError] = useState<unknown | null>(null);

    const defaultFeedbackTypes = [FeedBackType.FEATURE_REQUEST, FeedBackType.BUG, FeedBackType.OTHER];

    useEffect(() => {
        if (props.errorDetails !== undefined && props.errorDetails !== '') {
            setFeedback({ ...feedback, errorDetails: props.errorDetails, type: FeedBackType.BUG });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.errorDetails]);

    useEffect(() => {
        if (props.defaultMessage && props.defaultMessage !== '' && feedback.message === '') {
            setFeedback({
                ...feedback,
                message: props.defaultMessage
            });
        }
    }, [feedback, props.defaultMessage]);

    const onClose = () => {
        props.setShow(false);
        setSubmittingState('INITIAL');
    };

    const onSubmit = () => {
        setSubmittingState('SUBMITTING');
        submitFeedback(feedback)
            .then(() => {
                setSubmittingState('SUBMITTED');
                setFeedback({
                    type: null,
                    message: ''
                });
            })
            .catch((e) => {
                setSubmitError(e);
                setSubmittingState('INITIAL');
            });
    };

    const getPlaceHolderText = () => {
        let feedbackType = 'feedback';
        if (feedback.type === 'BUG') {
            feedbackType = 'bug';
        } else if (feedback.type === 'FEATURE_REQUEST') {
            feedbackType = 'feature request';
        }

        return 'Describe your ' + feedbackType + ' here';
    };

    const getDisplayName = (feedbackType: FeedBackType): string => {
        switch (feedbackType) {
            case FeedBackType.BUG:
                return 'Bug';
            case FeedBackType.CAR_REASSIGN_REQUEST:
                return 'Car reassign request';
            case FeedBackType.FEATURE_REQUEST:
                return 'Feature request';
            case FeedBackType.OTHER:
                return 'Other';
            default:
                return feedbackType;
        }
    };

    if (submitError != null && isAxiosError(submitError) && submitError.response?.data?.status === 401) {
        return <LoginRedirect />;
    }

    return (
        <Offcanvas show={props.show} onHide={onClose} placement="end" style={{ width: '60vw', minWidth: '350px' }} className={'feedback-off-canvas'}>
            <ToastMessage
                message={'Something went wrong while submitting your feedback, try again later.'}
                showToastMessage={submitError != null}
                setShowToastMessage={(show) => {
                    if (!show) {
                        setSubmitError(null);
                    }
                }}
                bg={'warning'}
            />
            <OffcanvasHeader closeButton>
                <OffcanvasTitle>Submit feedback</OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody style={{ paddingTop: '0' }}>
                {submittingState === 'SUBMITTED' ? (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <p>Your feedback was successfully submitted.</p>
                        <Button variant={'primary'} onClick={onClose}>
                            Close window
                        </Button>
                    </div>
                ) : (
                    <>
                        <Form.Select
                            value={feedback.type?.toString()}
                            onChange={(e) => {
                                if (e.target.value == null || e.target.value === '') {
                                    setFeedback({ ...feedback, type: null });
                                } else {
                                    // @ts-ignore
                                    setFeedback({ ...feedback, type: e.target.value.toUpperCase() });
                                }
                            }}
                        >
                            <option hidden={feedback.type !== null} value={''}>
                                Select type of feedback
                            </option>
                            {props.additionalFeedBackTypes ? (
                                props.additionalFeedBackTypes.map((f) => (
                                    <option value={f} key={'key-' + f}>
                                        {getDisplayName(f)}
                                    </option>
                                ))
                            ) : (
                                <></>
                            )}
                            {defaultFeedbackTypes.map((f) => (
                                <option key={'key-' + f} value={f}>
                                    {getDisplayName(f)}
                                </option>
                            ))}
                        </Form.Select>
                        {feedback.type === null ? (
                            <></>
                        ) : (
                            <>
                                <FormControl
                                    as="textarea"
                                    className={'feedback-textarea'}
                                    placeholder={getPlaceHolderText()}
                                    value={feedback.message}
                                    onChange={(e) =>
                                        setFeedback({
                                            ...feedback,
                                            message: e.target.value
                                        })
                                    }
                                />

                                {feedback.type === 'BUG' && props.errorDetails !== undefined && props.errorDetails !== '' ? (
                                    <>
                                        <p className={'feedback-error-detail-title'}>
                                            The following error details will be included with your report:
                                        </p>
                                        {props.errorDetails.split(',').map((detail) => (
                                            <p key={detail} className={'feedback-error-detail-value'}>
                                                {detail}
                                            </p>
                                        ))}
                                    </>
                                ) : (
                                    <></>
                                )}

                                <ButtonGroup style={{ width: '100%', margin: '1vh auto' }}>
                                    <Button variant={'outline-secondary'} onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant={'primary'}
                                        onClick={onSubmit}
                                        disabled={submittingState === 'SUBMITTING' || feedback.message === ''}
                                    >
                                        {' '}
                                        {submittingState === 'SUBMITTING' ? (
                                            <>
                                                <Spinner animation="border" variant="success" role="status" size="sm" /> Submitting ...
                                            </>
                                        ) : (
                                            <>Submit feedback</>
                                        )}
                                    </Button>
                                </ButtonGroup>
                                <p className={'feedback-disclaimer'}>
                                    Your name, email, and phone number will be shared with the administrators of this site when you submit feedback
                                </p>
                            </>
                        )}
                    </>
                )}
            </OffcanvasBody>
        </Offcanvas>
    );
}
