import '../../../styling/notificationManagement.css';
import Footer from '../../shared/Footer';
import useCheckIfAdminOrManager from '../../../hooks/useCheckIfAdminOrManager';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../shared/NavigationBar';
import { useQuery } from 'react-query';
import { getLocations } from '../../../service/Api';
import LoadingSpinner from '../../shared/LoadingSpinner';
import ChargingStationLocation from '../../../model/ChargingStationLocation';
import ManageCslNotificationCCPreferences from './ManageCslNotificationCCPreferences';
import ApiError from '../../shared/ApiError';
import { Tab, Tabs } from 'react-bootstrap';
import useCheckIfAdmin from '../../../hooks/useCheckIfAdmin';
import ManageCslNotificationSettings from './ManageCslNotificationSettings';

export default function NotificationManagementPage() {
    const isAdminOrManager = useCheckIfAdminOrManager();
    const isAdmin = useCheckIfAdmin();
    const navigate = useNavigate();
    if (!isAdminOrManager) {
        navigate('/dashboard');
    }

    const {
        data: csls,
        isLoading: cslsLoading,
        error: cslsError
    } = useQuery('chargingStationLocations', getLocations, {
        refetchOnWindowFocus: false
    });

    if (cslsLoading) {
        return (
            <>
                <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                    <NavigationBar />
                    <div style={{ padding: '1em' }}>
                        <h1 className="h2">Manage Notifications</h1>
                        <LoadingSpinner />
                        <p style={{ textAlign: 'center', marginTop: '1%' }}>Loading locations ...</p>
                    </div>
                </div>
                <Footer />
            </>
        );
    } else if (cslsError) {
        return (
            <>
                <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                    <NavigationBar />
                    <div style={{ padding: '1em' }}>
                        <h1 className="h2">Manage Notifications</h1>
                        <ApiError title={<>Something went wrong while fetching locations to manage the notifications for</>} error={cslsError} />
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                <NavigationBar />
                <div className="notificationMngmtPage">
                    <h1 className="h2">Manage Notifications</h1>
                    <Tabs defaultActiveKey="cc">
                        <Tab eventKey="cc" title="Mail CC management">
                            <p>
                                On this page you can manage which mails types you are CCed in when they are sent to users.{' '}
                                {isAdmin ? 'This can be managed for each separate location.' : ''}
                            </p>
                            {csls?.data?.map((csl: ChargingStationLocation) => <ManageCslNotificationCCPreferences key={csl.key} csl={csl} />)}
                        </Tab>
                        <Tab eventKey="disableNotif" title="Disable Notification Types" disabled={!isAdmin}>
                            {csls?.data?.map((csl: ChargingStationLocation) => <ManageCslNotificationSettings key={csl.key} csl={csl} />)}
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <Footer />
        </>
    );
}
