import '../../styling/login.css';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Cookies from 'js-cookie';
import { getUserData } from '../../service/Api';
import { InteractionStatus } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ToastMessage from '../shared/ToastMessage';

export default function LoginPage() {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const [tokenSet, setTokenSet] = useState(false);
    const [registerRedirect, setRegisterRedirect] = useState(false);
    const location = useLocation();
    const [signInMessage, setSignInMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        if (isAuthenticated && localStorage.getItem('SignInRequest') === 'true') {
            setSignInMessage('successfully signed in through Cronos.');
            getAccessToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts, inProgress, isAuthenticated]);

    useEffect(() => {
        if (tokenSet) {
            if (registerRedirect) {
                localStorage.setItem('SignInRequestRegisterRedirect', 'false');
                navigate('/register');
            } else {
                const manageSettings = localStorage.getItem('manageSettings');
                const manageSettingsOfLocation = localStorage.getItem('manageSettingsOfLocation');

                if (manageSettings !== null && manageSettings === 'true') {
                    let url = '/dashboard?manageSettings=true';
                    if (manageSettingsOfLocation !== null) {
                        url += `&locationName=${manageSettingsOfLocation}`;
                    }
                    localStorage.removeItem('manageSettings');
                    localStorage.removeItem('manageSettingsOfLocation');
                    localStorage.setItem('redirectedFromLoginWithSearchParams', 'true');
                    navigate(url);
                } else {
                    navigate('/dashboard');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenSet]);

    useEffect(() => {
        if (
            localStorage.getItem('SignInRequestRegisterRedirect') === 'true' ||
            new URLSearchParams(location.search).get('registerredirect') === 'true'
        ) {
            setRegisterRedirect(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeSignIn = async () => {
        try {
            localStorage.setItem('SignInRequest', 'true');
            if (registerRedirect) {
                localStorage.setItem('SignInRequestRegisterRedirect', 'true');
            }
            instance.loginRedirect({
                scopes: ['openid', 'profile', 'email', 'User.Read']
            });
        } catch (error) {
            console.error('Error during sign-in:', error);
        }
    };

    const getAccessToken = async () => {
        if (inProgress === InteractionStatus.None) {
            try {
                instance
                    .acquireTokenSilent({
                        account: accounts[0],
                        scopes: ['openid', 'profile', 'email', 'User.Read']
                    })
                    .then((response) => {
                        getUserAndToken(response.accessToken);
                    });
            } catch (error) {
                console.error('Error acquiring token:', error);
            }
        }
    };

    const getUserAndToken = async (accessToken: string) => {
        try {
            await getUserData(
                accessToken,
                localStorage.getItem('registrationToken') !== null ? (localStorage.getItem('registrationToken') as string) : ''
            ).then(async (response) => {
                if (response.status === 200) {
                    const data = response.data;
                    await setCookiesAndLocalStorage(data).then(() => {
                        setTokenSet(true);
                    });
                } else {
                    console.error('Failed to fetch data from API:', response.status, response.statusText);
                }
            });
        } catch (error) {
            console.error('Error fetching data from API:', error);
            setSignInMessage('');
            setErrorMessage('We could not log you in due to an unknown reason, please contact the website maintainer.');
        }
    };

    const setCookiesAndLocalStorage = async (data: any) => {
        Cookies.set('token', data.token, { expires: 9 / 24, secure: true, sameSite: 'Strict' });
        localStorage.setItem('userInfo', JSON.stringify(data));
        localStorage.setItem('SignInRequest', 'false');
    };

    return (
        <div>
            <ToastMessage
                message={errorMessage}
                showToastMessage={errorMessage !== ''}
                setShowToastMessage={(show) => {
                    if (!show) {
                        setErrorMessage('');
                    }
                }}
                bg={'danger'}
                autoHide={false}
            />
            <div className="loginBox">
                <h1 className="welcome">Welcome</h1>
                <div className="buttonContainer">
                    <button className={registerRedirect ? 'loginButton loginButtonWithRegister' : 'loginButton'} onClick={initializeSignIn}>
                        {registerRedirect ? 'Register ' : 'Sign in '}With Cronos Account
                    </button>
                </div>
                <p className="signInMessage" id="signInMessage">
                    {signInMessage}
                </p>
            </div>
        </div>
    );
}
