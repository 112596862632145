interface PoolAddButtonProps {
    setShowAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PoolAddButton(props: PoolAddButtonProps) {
    return (
        <>
            <div onClick={() => props.setShowAddUserModal(true)} className="pool-item pool-add-button" key="addButton">
                <p className="pool-add-button-plus-icon">+</p>
                <p>Add new user to your pool</p>
            </div>
        </>
    );
}
