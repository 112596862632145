import { Button, Modal } from 'react-bootstrap';
import MessageModalProps from '../../props/MessageModalProps';

export default function MessageModal(props: MessageModalProps) {
    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered backdrop={props.staticBackdrop ? 'static' : undefined}>
            <Modal.Header closeButton>{props.title}</Modal.Header>
            <Modal.Body style={{ textAlign: 'left' }}>
                <p>{props.message}</p>
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
