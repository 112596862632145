import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import CreatableSelect from 'react-select/creatable';
import { createCar, getCarBrandNames, getCarByLicensePlate, getCarModelNames, getUserOwnCar, updateCar } from '../../service/Api';
import NavigationBar from '../shared/NavigationBar';
import { Alert, Button, Form } from 'react-bootstrap';
import LoadingSpinner from '../shared/LoadingSpinner';
import useGetUserInfo from '../../hooks/useGetUserInfo';
import licensePlateRegex from '../../model/LicensePlateRegex';
import '../../styling/carPage.css';
import Car from '../../model/car/Car';
import OtherUserCarModal from '../fuelcard/registration/modals/OtherUserCarModal';
import { FeedBackType } from '../../model/feedback/FeedBackType';
import FeedbackOffCanvas from '../feedback/FeedbackOffCanvas';
import { isAxiosError } from 'axios';

interface FormData {
    licensePlate: string;
    brand: { value: string; label: string } | null;
    model: { value: string; label: string } | null;
}

const CarPage = () => {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors }
    } = useForm<FormData>({
        criteriaMode: 'all'
    });
    const [selectedBrand, setSelectedBrand] = useState<string | null>(null);
    const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);
    const [submissionError, setSubmissionError] = useState<string>('');

    const [showOtherUserCarModal, setShowOtherUserCarModal] = useState<boolean>(false);
    const [otherUserCarLicensePlate, setOtherUserCarLicensePlate] = useState<string>('');
    const [feedback, setFeedBack] = useState<{
        show: boolean;
        additionalFeedBackTypes: FeedBackType[];
        selectedFeedbackType: FeedBackType;
        defaultMessage: string;
    }>({
        show: false,
        additionalFeedBackTypes: [FeedBackType.CAR_REASSIGN_REQUEST],
        selectedFeedbackType: FeedBackType.CAR_REASSIGN_REQUEST,
        defaultMessage: ''
    });

    const { data: carBrandsData, isLoading: isLoadingCarBrands } = useQuery('carBrandNames', getCarBrandNames, { refetchOnWindowFocus: false });

    const { data: carModelsData, isLoading: isLoadingCarModels } = useQuery(
        ['carModelNames', selectedBrand],
        () => getCarModelNames(selectedBrand || ''),
        {
            refetchOnWindowFocus: false,
            enabled: !!selectedBrand
        }
    );

    const {
        data: userCarData,
        isSuccess: isUserCarLoaded,
        isLoading: isLoadingCarOfUser,
        refetch: refetchUserCar
    } = useQuery('carOfUser', getUserOwnCar, { refetchOnWindowFocus: false });

    const userInfo = useGetUserInfo();
    const userCar = userCarData?.data;
    const carBrands = carBrandsData?.data;
    const carModels = carModelsData?.data;

    useEffect(() => {
        if (isUserCarLoaded && userCar) {
            setValue('licensePlate', userCar.licensePlate);
            setSelectedBrand(userCar.brand);
            setValue('brand', { value: userCar.brand, label: userCar.brand });
            setValue('model', { value: userCar.model, label: userCar.model });
        }
    }, [isUserCarLoaded, userCar, setValue]);

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const newCar = {
            id: userCar?.id || null,
            licensePlate: data.licensePlate,
            brandId: carBrands?.find((brand: any) => brand.name === data.brand?.value)?.id || null,
            brand: data.brand?.value || '',
            modelId: carModels?.find((model: any) => model.name === data.model?.value)?.id || null,
            model: data.model?.value || '',
            reassignable: false,
            userId: userCar?.userId || null
        };

        try {
            await updateCar(newCar);
            setSubmissionStatus('success');
            refetchUserCar();
        } catch (e) {
            setSubmissionStatus('error');
            if (isAxiosError(e) && e.response && e.response.data && e.response.data.detail) {
                setSubmissionError(e.response.data.detail);
            }
        }
    };

    const addCar: SubmitHandler<FormData> = async (data) => {
        const newCar = {
            licensePlate: data.licensePlate,
            brandName: data.brand?.value || '',
            modelName: data.model?.value || '',
            email: userInfo.email
        };

        try {
            await createCar(newCar);
            setSubmissionStatus('success');
            refetchUserCar();
        } catch (e) {
            setSubmissionStatus('error');
            if (isAxiosError(e) && e.response && e.response.data && e.response.data.detail) {
                setSubmissionError(e.response.data.detail);
            }
        }
    };

    const onChangeLicensePlate = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const licensePlate = e.target.value;
            if (licensePlate == null || licensePlate === '' || !licensePlateRegex.test(licensePlate)) {
                return;
            }
            const carResp = await getCarByLicensePlate(licensePlate);
            const car: Car = carResp?.data;
            if (car == null || car.userId == null) {
                return null;
            }

            if (car.userId !== userInfo.id && !car.reassignable) {
                setOtherUserCarLicensePlate(licensePlate);
                setShowOtherUserCarModal(true);
                return;
            }

            setValue('brand', { value: car.brand, label: car.brand });
            setValue('model', { value: car.model, label: car.model });
            setSelectedBrand(car.brand); // Also update selectedBrand state to trigger model loading
        } catch (error) {
            console.error('Unable to find brand or model for given license plate.');
        }
    };

    if (isLoadingCarOfUser) {
        return (
            <>
                <div style={{ minHeight: 'var(--bs-content-min-height)' }}>
                    <NavigationBar />
                    <div style={{ marginTop: '5rem', textAlign: 'center' }}>
                        <LoadingSpinner />
                        <p style={{ marginTop: '2vh' }}>Loading your car ...</p>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <NavigationBar />
            <OtherUserCarModal
                showOtherUserCar={showOtherUserCarModal}
                setShowOtherUserCar={setShowOtherUserCarModal}
                submitFeedback={() =>
                    setFeedBack({
                        ...feedback,
                        defaultMessage:
                            'Hi, the system is telling me that the car with license plate ' +
                            otherUserCarLicensePlate +
                            ' belongs to another user, but it belongs to me.  Would you be able to manually correct this?  Thanks in advance, ' +
                            userInfo.firstName,
                        show: true
                    })
                }
                hideClearEnteredCarButton
                licensePlate={otherUserCarLicensePlate}
            />
            <FeedbackOffCanvas
                {...feedback}
                setShow={(show) => {
                    setFeedBack({
                        ...feedback,
                        show: show
                    });
                }}
            />

            <div className="container">
                <h2 className="my-4 text-center">My Car</h2>
                <Form onSubmit={handleSubmit(userCar ? onSubmit : addCar)}>
                    {submissionStatus === 'success' && <Alert variant="success">Car information updated successfully!</Alert>}
                    {submissionStatus === 'error' && (
                        <Alert variant="danger">
                            {submissionError === '' ? <>An error occurred while updating car information.</> : <>{submissionError}</>}{' '}
                        </Alert>
                    )}
                    <Form.Group className="mb-3">
                        <Form.Label>License Plate:</Form.Label>
                        <Form.Control
                            type="text"
                            id="licensePlate"
                            {...register('licensePlate', {
                                required: 'License plate is required',
                                pattern: {
                                    value: licensePlateRegex,
                                    message:
                                        "Please enter a valid Belgian or Dutch license plate. Valid Belgian formats: ABC-123, 1-ABC-123, Z-ABC-123. Valid Dutch formats: A-123-BC, ABC-12-D. ('-', ' ', and '.' are accepted separators)"
                                }
                            })}
                            isInvalid={!!errors.licensePlate}
                            onChange={onChangeLicensePlate}
                        />
                        <Form.Control.Feedback type="invalid">
                            {typeof errors.licensePlate?.message === 'string' && errors.licensePlate.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Brand:</Form.Label>
                        <Controller
                            name="brand"
                            control={control}
                            rules={{
                                required: 'Brand is required',
                                maxLength: 50,
                                pattern: {
                                    value: /^[\p{L}0-9 .\-+#]+$/u,
                                    message: 'Brand may only contain letters, numbers, spaces, periods, hyphens, plus signs, and hashes'
                                }
                            }}
                            render={({ field }) => (
                                <CreatableSelect
                                    {...field}
                                    classNamePrefix="react-select"
                                    placeholder="Select the brand of your car ..."
                                    options={carBrands?.map((brand: any) => ({ value: brand.name, label: brand.name }))}
                                    onChange={(selectedOption) => {
                                        field.onChange(selectedOption);
                                        setSelectedBrand(selectedOption?.value || null);
                                        setValue('model', null);
                                    }}
                                    isClearable
                                    isLoading={isLoadingCarBrands}
                                />
                            )}
                        />
                        {errors.brand && <p className="text-danger">{String(errors.brand.message)}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Model:</Form.Label>
                        <Controller
                            name="model"
                            control={control}
                            rules={{
                                required: 'Model is required',
                                maxLength: 50,
                                pattern: {
                                    value: /^[\p{L}0-9 .\-+#]+$/u,
                                    message: 'Model may only contain letters, numbers, spaces, periods, hyphens, plus signs, and hashes'
                                }
                            }}
                            render={({ field }) => (
                                <CreatableSelect
                                    {...field}
                                    classNamePrefix="react-select"
                                    placeholder={`Select the model of your ${selectedBrand || 'car'} ...`}
                                    options={carModels?.map((model: any) => ({ value: model.name, label: model.name }))}
                                    isClearable
                                    isLoading={isLoadingCarModels}
                                    isDisabled={!selectedBrand}
                                />
                            )}
                        />
                        {errors.model && <p className="text-danger">{String(errors.model.message)}</p>}
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt- m-auto">
                        Submit
                    </Button>
                </Form>
            </div>
        </>
    );
};

export default CarPage;
