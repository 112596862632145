import '../../styling/leaderBoard.css';
import ChargingStationLocation from '../../model/ChargingStationLocation';
import Trophy from '../shared/icon/Trophy';
import { useQuery } from 'react-query';
import { getTopUsersLeaderboardScores } from '../../service/Api';
import { useEffect, useState } from 'react';
import LeaderboardTopUser from '../../model/user/LeaderboardTopUser';
import LoadingSpinner from '../shared/LoadingSpinner';
import ApiError from '../shared/ApiError';

interface LocationWallOfFameProps {
    location: ChargingStationLocation;
}

export default function Top3Podium(props: LocationWallOfFameProps) {
    const [topUsers, setTopUsers] = useState<LeaderboardTopUser[]>([]);

    const {
        data: topUsersData,
        isLoading: isTopUsersLoading,
        error: topUsersError
    } = useQuery(['fullWallOfFame', props.location.name], () => getTopUsersLeaderboardScores(props.location.name), {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (!isTopUsersLoading && topUsersData) {
            if (topUsersData.status === 204) {
                setTopUsers([]);
            } else if (topUsersData.status === 200) {
                setTopUsers(topUsersData.data);
            }
        }
    }, [isTopUsersLoading, topUsersData]);

    const getUserOfRank = (rank: number): LeaderboardTopUser | undefined => {
        return topUsers.find((u) => u.rank === rank);
    };

    if (isTopUsersLoading) {
        return <LoadingSpinner />;
    } else if (topUsersError) {
        return <ApiError error={topUsersError} title={'Something went wrong while trying to fetch the top 3 users for this location'} />;
    }

    if (topUsers.length === 0) {
        return <></>;
    }

    return (
        <>
            <h2 className={'h3'}>{props.location.displayName} Top 3:</h2>
            <div className="wallOfFamePodium">
                <div className="second place">
                    <div className="platform silver">
                        <div className="trophy">
                            <Trophy width="40" height="40" />
                            <span className="position">2</span>
                        </div>
                        <p className="name">{getUserOfRank(1)?.name}</p>
                        <p className="company">{getUserOfRank(1)?.company}</p>
                    </div>
                </div>
                <div className="first place">
                    <div className="platform gold">
                        <div className="trophy">
                            <Trophy width="40" height="40" />
                            <span className="position">1</span>
                        </div>
                        <p className="name">{getUserOfRank(0)?.name}</p>
                        <p className="company">{getUserOfRank(0)?.company}</p>
                    </div>
                </div>
                <div className="third place">
                    <div className="platform bronze">
                        <div className="trophy">
                            <Trophy width="40" height="40" />
                            <span className="position">3</span>
                        </div>
                        <p className="name">{getUserOfRank(2)?.name}</p>
                        <p className="company">{getUserOfRank(2)?.company}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
