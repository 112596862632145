import { Accordion, OverlayTrigger, Popover } from 'react-bootstrap';
import Footer from '../shared/Footer';
import NavigationBar from '../shared/NavigationBar';
import { FC, ReactNode } from 'react';

interface LinkProps {
    id: string;
    title: string;
    children: ReactNode;
}

export default function FaqPage() {
    const Link: FC<LinkProps> = ({ id, children, title }) => (
        <OverlayTrigger
            overlay={
                <Popover id={id} style={{ padding: '1em' }}>
                    {title}
                </Popover>
            }
        >
            <span style={{ textDecoration: 'underline' }}>{children}</span>
        </OverlayTrigger>
    );

    return (
        <>
            <div style={{ textAlign: 'center', minHeight: 'var(--bs-content-min-heigth)' }}>
                <NavigationBar />
                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <h2>FAQ</h2>
                </div>
                <div>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>How do I install this website as a PWA?</Accordion.Header>
                            <Accordion.Body style={{ textAlign: 'left' }}>
                                <p>
                                    You can install our website as a Progressive Web App (PWA) on your device for easy access from your home screen.
                                </p>
                                <p style={{ marginBottom: '0.2em' }}>In most browsers on most devices you can do this via the following steps:</p>
                                <ol style={{ listStylePosition: 'inside', padding: '0', marginLeft: '1rem' }}>
                                    <li>Open the menu in your browser by tapping the three dots at the top-right corner.</li>
                                    <li>Look for the "install app" button and tap it, then follow the prompts to complete the installation.</li>
                                    <li>
                                        Once installed, you can launch our PWA directly from your device's home screen or app launcher, just like any
                                        other app.
                                    </li>
                                </ol>
                                <p>
                                    If the above does not work on your device, please consult{' '}
                                    <a
                                        href={'https://www.howtogeek.com/196087/how-to-add-websites-to-the-home-screen-on-any-smartphone-or-tablet/'}
                                        rel="noopener"
                                        target="_blank"
                                    >
                                        this
                                    </a>{' '}
                                    guide on how to install it.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How do I register my fuelcard?</Accordion.Header>
                            <Accordion.Body style={{ textAlign: 'left' }}>
                                <ol style={{ listStylePosition: 'inside', padding: '0' }}>
                                    <p>To start charging at one of our charging stations, you must first register your fuelcard.</p>
                                    <li>
                                        Access the{' '}
                                        <a href="/register" target="_blank">
                                            registration page
                                        </a>{' '}
                                        on an Android device using a{' '}
                                        <Link title="Chrome Android, Opera Android, Samsung Internet or WebView Android." id="t-1">
                                            supported browser
                                        </Link>{' '}
                                        for NFC scanning.
                                    </li>
                                    <li>Press the "Scan Fuelcard" button.</li>
                                    <li>When prompted, grant permission for NFC scanning.</li>
                                    <li>Wait for the "{'>'} Scan started" message.</li>
                                    <li>Place your fuelcard against the back of your device.</li>
                                    <li>
                                        Once scanned, the fuelcard serial number will be automatically inserted in the fuelcard serial number text
                                        field.
                                    </li>
                                    <li>Fill in the license plate, brand and model of your electric vehicle.</li>
                                    <li>Press "submit" to register your fuelcard.</li>
                                </ol>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>How do I correctly start a charging session?</Accordion.Header>
                            <Accordion.Body style={{ textAlign: 'left' }}>
                                <div style={{ maxWidth: '640px', marginBottom: '1%' }}>
                                    <div
                                        style={{
                                            position: 'relative',
                                            paddingBottom: '56.25%',
                                            height: '0',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <iframe
                                            src="https://cronos-my.sharepoint.com/personal/vansami_cronos_be/_layouts/15/embed.aspx?UniqueId=81197fe0-cb02-4f22-80a3-9a0536ac57bf&embed=%7B%22hvm%22%3Atrue%2C%22ust%22%3Atrue%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
                                            width="640"
                                            height="360"
                                            allowFullScreen
                                            title="Correct starten laadsessie.mp4"
                                            style={{
                                                border: 'none',
                                                position: 'absolute',
                                                top: '0',
                                                left: '0',
                                                right: '0',
                                                bottom: '0',
                                                height: '100%',
                                                maxWidth: '100%'
                                            }}
                                        ></iframe>
                                    </div>
                                </div>
                                <ol style={{ listStylePosition: 'inside', padding: '0' }}>
                                    <li>
                                        Initiate the session by tapping your fuelcard against the charging station. Only after starting the session
                                        should you insert the charging cable into the charging station.
                                    </li>
                                    <li>
                                        Hold the fuelcard with the printed side and chip facing towards you, slightly tilted as indicated on the
                                        station. Ensure the chip is positioned towards the bottom left.
                                        <br />
                                        If nothing happens, try holding the card directly over the green lights. This should prompt the charging
                                        process to begin.
                                    </li>
                                    <li>
                                        A successful session initiation is indicated by flashing BLUE lights. If the lights are green or red, it means
                                        the initiation was unsuccessful.
                                    </li>
                                    <li>
                                        You can then plug in your charging cable. Once the lights turn LIGHT BLUE the charging process has started.
                                    </li>
                                </ol>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>What is the pool?</Accordion.Header>
                            <Accordion.Body style={{ textAlign: 'left' }}>
                                <p>
                                    The{' '}
                                    <a href="/pool" target="_blank">
                                        pool
                                    </a>{' '}
                                    is a system that aims to facilitate easier switching of parking spots between people who have finished charging
                                    and people who want to start charging.
                                </p>
                                <p>
                                    On the pool page you can add people you know to your pool by email, the other person needs to accept your request.
                                    You have the ability to enable a notification on the dashboard that will notify you once a person in your pool has
                                    finished charging (on the{' '}
                                    <a href="/dashboard?manageSettings=true" target="_blank">
                                        dashboard
                                    </a>{' '}
                                    you can find more information on this).
                                </p>
                                <p>
                                    You can remove people from your pool at any time. You can have a maximum of 15 people in your pool at any one
                                    time.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <Footer />
        </>
    );
}
