import ChargingStation from '../../../model/ChargingStation';
import '../../../styling/dashboardContainer.css';
import DashboardItem from './DashboardItem';
import CommonDashboardProps from '../../../props/CommonDashboardProps';
import CommonDashboardTransactionProps from '../../../props/CommonDashboardTransactionProps';
import useCheckIfAdminOrManagerForLocation from '../../../hooks/useCheckIfAdminOrManagerForLocation';
import { mapStatus } from '../../../service/StatusMapper';
import LoadingSpinner from '../../shared/LoadingSpinner';
import ApiError from '../../shared/ApiError';
import UserFuelcard from '../../../model/fuelcard/UserFuelcard';
import ChargingStationLocation from '../../../model/ChargingStationLocation';

interface DashboarContainerProps extends CommonDashboardProps, CommonDashboardTransactionProps {
    chargingStations: ChargingStation[];
    filter: null | string;
    chargingStationLocation: ChargingStationLocation;
    userFuelcards: UserFuelcard[];
    chargingStationsLoading: boolean;
    currentCslStationsError?: unknown;
}

export default function DashboardContainer(props: DashboarContainerProps) {
    const isAdminOrManager = useCheckIfAdminOrManagerForLocation(props.chargingStationLocation.name);

    const shouldDisplayCs = (cs: ChargingStation): boolean => {
        if (props.filter == null || props.filter === '') {
            return true;
        } else if (props.filter === 'session' && props.userFuelcards.length !== 0) {
            return props.userFuelcards.some((fc) => fc.fuelcardId === cs.fuelCardId);
        } else if (props.filter.includes('status-')) {
            const csStatus = 'status-' + mapStatus(cs.comStatus === 'DOWN' && !isAdminOrManager ? cs.comStatus : cs.ocppStatus);
            return props.filter === csStatus;
        } else if (props.filter === 'pool') {
            return cs.contactEmail !== null && cs.contactEmail !== undefined;
        }
        return true;
    };

    let dashboardContent;

    if (props.chargingStationsLoading) {
        dashboardContent = (
            <>
                <LoadingSpinner style={{ marginTop: '2em' }} />
                <p style={{ width: '100%', textAlign: 'center', marginTop: '1em' }}>Loading charging stations ...</p>
            </>
        );
    } else if (props.currentCslStationsError) {
        dashboardContent = <ApiError error={props.currentCslStationsError} />;
    } else if (props.chargingStations.length <= 0) {
        dashboardContent = <p>Could not load charging stations data. Please try again. If this error persists, please contact the site maintainer</p>;
    } else {
        dashboardContent = (
            <div className="dashboard-grid-container">
                {props.chargingStations.filter(shouldDisplayCs).map((cs) => (
                    <DashboardItem key={cs.key} chargingStation={cs} isAdminOrManager={isAdminOrManager} {...props} />
                ))}
            </div>
        );
    }

    return <div className="dashboard">{dashboardContent}</div>;
}
