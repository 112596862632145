import { Button, Modal } from 'react-bootstrap';
import UserPool from '../../model/UserPool';

interface ConfirmRemoveUserFromPoolProps {
    show: boolean;
    setShow: (show: boolean) => void;
    doRemove: () => void;
    userPoolItem: UserPool;
}

export default function ConfirmRemoveUserFromPool(props: ConfirmRemoveUserFromPoolProps) {
    const handleClose = () => props.setShow(false);

    let bodyText = <>Are you sure you want to remove {props.userPoolItem.name} from your pool?</>;
    let buttonText = 'Remove';
    let titleText = <>Confirm removing {props.userPoolItem.name} from pool</>;

    if (props.userPoolItem.poolStatus === 'REQUESTING') {
        bodyText = <>Are you sure you want to retract your request to {props.userPoolItem.name} to be added to your pool?</>;
        buttonText = 'Retract request';
        titleText = <>Confirm retract request to {props.userPoolItem.name}</>;
    } else if (props.userPoolItem.poolStatus === 'REQUESTED') {
        bodyText = <>Are you sure you want to decline {props.userPoolItem.name} their request to their pool?</>;
        buttonText = 'Decline request';
        titleText = <>Confirm decline request to {props.userPoolItem.name}</>;
    }

    return (
        <Modal show={props.show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{titleText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyText}</Modal.Body>
            <Modal.Footer className="d-flex justify-content-center flex-column flex-sm-row" style={{ flexWrap: 'nowrap' }}>
                <Button variant="outline-secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={props.doRemove}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
