import { useQuery } from 'react-query';
import NavigationBar from '../shared/NavigationBar';
import { getPoolOfUser } from '../../service/Api';
import LoadingSpinner from '../shared/LoadingSpinner';
import Footer from '../shared/Footer';
import '../../styling/pool.css';
import PoolContainer from './PoolContainer';
import { useState } from 'react';
import AddUserModal from './AddUserModal';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import LoginRedirect from '../login/LoginRedirect';
import UserPool from '../../model/UserPool';
import ToastMessage from '../shared/ToastMessage';
import SessionExpiredErrorMessage from '../shared/SessionExpiredErrorMessage';
import { isAxiosError } from 'axios';
import ApiError from '../shared/ApiError';
import InformationPopover from '../shared/InformationPopover';
import { bottom } from '@popperjs/core';

export default function MyPoolPage() {
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [isUnauthorizedError, setIsUnauthorizedError] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);

    const {
        data: userPool,
        isLoading: isLoadingPool,
        error: errorPool,
        isError: isErrorPool,
        refetch: refetchPool
    } = useQuery<UserPool[]>('userPool', () => getPoolOfUser(), {
        refetchOnWindowFocus: false,
        onError: (error) => {
            if (isAxiosError(error) && (error.response?.status === 403 || error.response?.status === 401)) {
                setIsUnauthorizedError(true);
            }
        }
    });

    if (isLoadingPool) {
        return (
            <>
                <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                    <NavigationBar />
                    <div style={{ marginTop: '5rem', textAlign: 'center' }}>
                        <LoadingSpinner />
                        <p style={{ marginTop: '2vh' }}>Loading your pool ...</p>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    if (isErrorPool) {
        return (
            <>
                <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                    <NavigationBar />
                    <div style={{ marginTop: '1rem', marginBottom: '1rem', textAlign: 'center' }}>
                        <h2>My Pool</h2>
                        {isUnauthorizedError ? (
                            <p style={{ textAlign: 'left', marginLeft: '1%' }}>
                                <SessionExpiredErrorMessage />
                            </p>
                        ) : (
                            <div style={{ textAlign: 'left', width: '90%', margin: '2em auto' }}>
                                <ApiError error={errorPool} />
                            </div>
                        )}
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <AuthenticatedTemplate>
                <ToastMessage
                    showToastMessage={toastMessage !== null}
                    setShowToastMessage={(show) => {
                        if (!show) setToastMessage(null);
                    }}
                    message={toastMessage ?? ''}
                />
                <AddUserModal show={showAddUserModal} onHide={() => setShowAddUserModal(false)} refetchPool={refetchPool} />
                <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                    <NavigationBar />
                    <main>
                        <div style={{ marginTop: '1rem', marginBottom: '1rem', textAlign: 'center' }}>
                            <h2>My Pool</h2>
                            <p>
                                {userPool ? <>{userPool.length} / 15</> : <></>}

                                <InformationPopover
                                    infoHeader={'Welcome to the "My Pool" page'}
                                    desktopOverLayPlacement={bottom}
                                    desktopPopoverStyle={{ maxWidth: '80vw', width: '100%' }}
                                    overlayDelay={{ show: 100, hide: 1000 }}
                                >
                                    <p>
                                        This page allows you to manage your pool.
                                        {userPool ? <> Your current pool has {userPool.length} out of a maximum of 15 members.</> : <></>}
                                    </p>
                                    <p>
                                        The goal of the pool is to facilitate easier switching of parking spots between people who have finished
                                        charging and people who want to start charging.
                                    </p>
                                    <p>
                                        You, and people in your pool, have the ability to enable a notification on the{' '}
                                        <a href="/dashboard?manageSettings=true" target="_blank">
                                            dashboard
                                        </a>{' '}
                                        to get notified when someone in your pool has finished charging. If you have this notification enabled you
                                        will receive an email with the contact details of the person that has finished charging so you can contact
                                        them directly to arrange swapping parking spots with them.
                                    </p>
                                    <p>
                                        You can add people to your pool by their email address by clicking the '+' button below. The other person has
                                        to accept this request. You can retract this request at any time before it is accepted or can remove someone
                                        from your pool at any time, both of these are achieved by clicking on the red trash bin icon.
                                    </p>
                                </InformationPopover>
                            </p>
                        </div>
                        <PoolContainer
                            userPool={userPool ? userPool : []}
                            setShowAddUserModal={setShowAddUserModal}
                            refetchPool={refetchPool}
                            setToastMessage={setToastMessage}
                        />
                    </main>
                </div>
                <Footer />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LoginRedirect />
            </UnauthenticatedTemplate>
        </>
    );
}
