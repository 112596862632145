import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import UserFuelcard from '../../../model/fuelcard/UserFuelcard';
import FuelcardState from './FuelcardState';

interface FuelcardModalsProps {
    fuelcardData: UserFuelcard[] | undefined;
    state: { [key: string]: FuelcardState };
    setState: React.Dispatch<React.SetStateAction<{ [key: string]: FuelcardState }>>;
    deleteFuelcard: (id: string) => void;
}

export default function FuelcardModals({ fuelcardData, state, setState, deleteFuelcard }: FuelcardModalsProps) {
    return (
        <>
            {fuelcardData?.map((fuelcard: UserFuelcard) => (
                <Modal
                    key={fuelcard.fuelcardId}
                    show={state[fuelcard.fuelcardId]?.showDeleteModal ?? false}
                    onHide={() =>
                        setState((prevState) => ({
                            ...prevState,
                            [fuelcard.fuelcardId]: {
                                ...prevState[fuelcard.fuelcardId],
                                showDeleteModal: false
                            }
                        }))
                    }
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the fuelcard '{fuelcard.displayName}'?</Modal.Body>
                    <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="secondary"
                            onClick={() =>
                                setState((prevState) => ({
                                    ...prevState,
                                    [fuelcard.fuelcardId]: {
                                        ...prevState[fuelcard.fuelcardId],
                                        showDeleteModal: false
                                    }
                                }))
                            }
                        >
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => deleteFuelcard(fuelcard.fuelcardId)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            ))}
        </>
    );
}
