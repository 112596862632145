import Page from '../../model/Page';
import CommonButton from './CommonButton';

interface PaginationButtonsProps {
    setPage: (page: Page) => void;
    page: Page;
    changingPage: boolean;
}

export default function PaginationButtons(props: PaginationButtonsProps) {
    if (props.page.isEmpty || props.page.totalPages === 1) {
        return <></>;
    }

    return (
        <div className="pagination-controls">
            <CommonButton
                className="pagination-button"
                onClick={() => props.setPage({ ...props.page, currentPage: Math.max(props.page.currentPage - 1, 0) })}
                disabled={props.page.currentPage === 0 || props.changingPage}
                buttonText="Previous"
            />
            <span>
                {' '}
                Page {props.page.currentPage + 1} of {props.page.totalPages}{' '}
            </span>
            <CommonButton
                className="pagination-button"
                onClick={() =>
                    props.setPage({
                        ...props.page,
                        currentPage: props.page.currentPage === props.page.totalPages - 1 ? props.page.currentPage : props.page.currentPage + 1
                    })
                }
                disabled={props.page.currentPage === props.page.totalPages - 1 || props.changingPage}
                buttonText="Next"
            />
        </div>
    );
}
