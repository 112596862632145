import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Cookies from 'js-cookie';
import { API_URL } from '../../config';
import useCheckIfAdminOrManager from '../../hooks/useCheckIfAdminOrManager';
import { Badge, NavDropdown } from 'react-bootstrap';
import '../../styling/navbar.css';
import useCheckIfAdmin from '../../hooks/useCheckIfAdmin';
import ToastMessage from './ToastMessage';
import React, { useEffect, useState } from 'react';
import { setTheme } from '../../service/DarkThemeFunctions';
import { getDisabledNotifications, getOpenPoolRequests } from '../../service/Api';
import FeedbackOffCanvas from '../feedback/FeedbackOffCanvas';
import { AxiosResponse } from 'axios';

import ChatLeftDots from './icon/ChatLeftDots';
import MailOpen from './icon/MailOpen';
import PersonSlash from './icon/PersonSlash';
import UserGear from './icon/UserGear';
import Sun from './icon/Sun';
import Moon from './icon/Moon';
import HalfCircle from './icon/HalfCircle';
import BoxArrowRight from './icon/BoxArrowRight';
import { useMediaQuery } from 'react-responsive';
import Search from './icon/Search';
import Megaphone from './icon/Megaphone';
import Car from './icon/Car';
import SwaggerUI from './icon/SwaggerUI';
import Copy from './icon/Copy';
import People from './icon/People';
import Card from './icon/Card';
import EvFront from './icon/EvFront';

export default function NavigationBar() {
    const [showToast, setShowToast] = useState<boolean>(false);
    const [counters, setCounters] = useState({
        userPoolRequests: 0,
        disabledNotifications: 0,
        myInfoTotal: 0,
        managementTotal: 0,
        total: 0
    });
    const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
    const [submittingFeedback, setSubmittingFeedback] = useState<boolean>(false);

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const isAdminOrManager = useCheckIfAdminOrManager();
    const isAdmin = useCheckIfAdmin();
    const swaggerUri = API_URL + '/swagger-ui/index.html';

    const getDisabledNotificationsIfAdmin = async () => {
        if (isAdmin) {
            return await getDisabledNotifications();
        } else {
            return new Promise<AxiosResponse<number>>(() => 0);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch both sets of data in parallel
                const [disabledNotifResponse, poolRequestsResponse] = await Promise.all([getDisabledNotificationsIfAdmin(), getOpenPoolRequests()]);

                // Update state with results
                setCounters({
                    userPoolRequests: poolRequestsResponse.data,
                    disabledNotifications: disabledNotifResponse.data,
                    myInfoTotal: poolRequestsResponse.data,
                    managementTotal: disabledNotifResponse.data,
                    total: poolRequestsResponse.data + disabledNotifResponse.data
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isCollapsedView = useMediaQuery({
        query: '(max-width: 991px)'
    });

    const handleNavigation = (route: string) => {
        navigate(route);
    };

    const handleExternalNavigation = (route: string) => {
        window.open(route);
    };

    const copyJwt = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const token = Cookies.get('token');
        if (token) {
            navigator.clipboard.writeText(token);
        }
        setShowToast(true);
    };

    const initializeSignOut = () => {
        instance.logout();
        Cookies.remove('token');
        localStorage.removeItem('userInfo');
    };

    if (!isAuthenticated) return <></>;

    return (
        <>
            <ToastMessage showToastMessage={showToast} setShowToastMessage={setShowToast} message="Copied JWT to clipboard." />
            <FeedbackOffCanvas show={submittingFeedback} setShow={setSubmittingFeedback} />
            <Navbar style={{ textAlign: 'left' }} className="appNavbar" variant="dark" expand="lg">
                <Container fluid>
                    <Navbar.Brand className="brand" onClick={() => handleNavigation('/dashboard')}>
                        <img src="/favicon-64x64.ico" className="d-inline-block align-top" alt="Xplore group logo" />
                        Dashboard
                    </Navbar.Brand>
                    <span className="nav-hamburger-holder">
                        <Navbar.Toggle className={'hamburger'} aria-controls="offcanvasNavbar" onClick={() => setMobileNavOpen(!mobileNavOpen)} />
                        <Badge hidden={counters.total === 0 || !isCollapsedView || mobileNavOpen} pill bg="danger" className="nav-hamburger-badge">
                            {counters.total}
                        </Badge>
                    </span>
                    <Navbar.Collapse>
                        <Nav>
                            <Nav.Link onClick={() => handleNavigation('/register')}>Registration</Nav.Link>
                            <Nav.Link onClick={() => handleNavigation('/walloffame')}>Wall&nbsp;of&nbsp;Fame</Nav.Link>

                            <span className={'my-info-dropdown'}>
                                <NavDropdown
                                    menuVariant="dark"
                                    title={
                                        <>
                                            My Info{' '}
                                            <Badge hidden={counters.myInfoTotal === 0} pill bg="danger" className="my-info-badge">
                                                {counters.myInfoTotal}
                                            </Badge>
                                        </>
                                    }
                                >
                                    <NavDropdown.Item onClick={() => handleNavigation('/pool')}>
                                        <span className="my-pool-navlink">
                                            <People /> Pool{' '}
                                            <Badge hidden={counters.userPoolRequests === 0} pill bg="danger" className="my-pool-badge">
                                                {counters.userPoolRequests}
                                            </Badge>
                                        </span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleNavigation('/car')}>
                                        <Car /> Car
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleNavigation('/fuelcard')}>
                                        <Card /> Fuelcards
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleNavigation('/charginghistory')}>
                                        <EvFront /> Charging History
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </span>

                            <Nav.Link onClick={() => handleNavigation('/faq')}>FAQ</Nav.Link>

                            {isAdminOrManager ? (
                                <span className={'management-dropdown'}>
                                    <NavDropdown
                                        menuVariant="dark"
                                        title={
                                            <>
                                                Management{' '}
                                                <Badge hidden={counters.managementTotal === 0} pill bg="danger" className="management-badge">
                                                    {counters.managementTotal}
                                                </Badge>
                                            </>
                                        }
                                    >
                                        <NavDropdown.Item onClick={() => handleNavigation('/fuelcard/search')}>
                                            <Search /> Fuelcard search
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleNavigation('/management/announcement')}>
                                            <Megaphone /> Announcement management
                                        </NavDropdown.Item>
                                        {isAdmin ? (
                                            <>
                                                <NavDropdown.Item onClick={() => handleNavigation('/management/car/carinfo')}>
                                                    <Car /> Car info management
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => handleNavigation('/management/user/inactive')}>
                                                    <PersonSlash /> Inactive user management
                                                </NavDropdown.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <NavDropdown.Item onClick={() => handleNavigation('/management/notification')}>
                                            <MailOpen /> Notification management{' '}
                                            <Badge hidden={counters.disabledNotifications === 0} pill bg="danger" className="notification-badge">
                                                {counters.disabledNotifications}
                                            </Badge>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => handleExternalNavigation(swaggerUri)}>
                                            <SwaggerUI /> Swagger UI
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={copyJwt}>
                                            Copy JWT <Copy />
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </span>
                            ) : (
                                <></>
                            )}
                        </Nav>
                        <Nav className="justify-content-end" style={{ width: '100%' }}>
                            <NavDropdown
                                title={<UserGear width="18" height="18" />}
                                menuVariant="dark"
                                drop={isCollapsedView ? 'down-centered' : 'start'}
                            >
                                <NavDropdown.Header>Select Theme</NavDropdown.Header>
                                <NavDropdown.Item onClick={() => setTheme('light')}>
                                    <Sun /> Light
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => setTheme('dark')}>
                                    <Moon /> Dark
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => setTheme('auto')}>
                                    <HalfCircle /> Use System Settings
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => setSubmittingFeedback(true)}>
                                    <ChatLeftDots /> Submit feedback
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => initializeSignOut()}>
                                    <BoxArrowRight /> Sign out
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}
