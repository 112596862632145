import { useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { getChargingStationsOfLocationTVMode } from '../../service/Api';
import ChargingStation from '../../model/ChargingStation';
import '../../styling/dashboard.css';
import '../../styling/dashboardContainer.css';
import DashboardItem from './DashboardItem';
import ApiError from '../shared/ApiError';
import { setTheme } from '../../service/DarkThemeFunctions';

export default function TvDashboard() {
    const handle = useFullScreenHandle();
    const browserLocation = useLocation();
    const [locationName, setLocationName] = useState('');
    const [chargingStations, setChargingStations] = useState<ChargingStation[]>([]);
    const [showWallOfFame, setShowWallOfFame] = useState(false);
    const [isSwitchViewOn, setIsSwitchViewOn] = useState(false);

    const {
        data: CslStationsData,
        isLoading: isCslStationsDataLoading,
        error: CslStationsError,
        isError: isCslStationsError
    } = useQuery(['CslStationsData'], () => getChargingStationsOfLocationTVMode(locationName), {
        enabled: locationName !== '',
        refetchInterval: 300000,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(browserLocation.search);
        setLocationName(searchParams.get('locationName') || '');
        setIsSwitchViewOn(searchParams.get('switchView') === 'true' || false);
        const theme = searchParams.get('theme');
        if (theme && theme === 'light') {
            setTheme('light');
        } else {
            setTheme('dark');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSwitchViewOn) {
            // Switch to Wall of Fame view every minute
            const interval = setInterval(() => {
                setShowWallOfFame((prevShowWallOfFame) => !prevShowWallOfFame);
            }, 60000);

            return () => clearInterval(interval);
        }
    }, [isSwitchViewOn]);

    useEffect(() => {
        const handleKeyDown = (event: { key: string }) => {
            if (event.key === 'Enter') {
                if (handle.active) {
                    handle.exit();
                } else {
                    handle.enter();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handle]);

    useEffect(() => {
        if (CslStationsData && CslStationsData.data) {
            setChargingStations(CslStationsData.data);
        }
    }, [CslStationsData]);

    return (
        <>
            <div style={{ textAlign: 'center', margin: '1%' }}>
                <h4>
                    Press enter or click{' '}
                    <span style={{ cursor: 'pointer', color: '#1e90ff' }} onClick={handle.enter}>
                        here
                    </span>{' '}
                    to go full screen
                </h4>
                <p>
                    Location: {locationName ? locationName : 'No location Specified'} / Switch View is currently turned{' '}
                    {isSwitchViewOn ? 'on' : 'off'}.
                    <br />
                    Both can be specified in the URL. e.g. '
                    <a href="/tvdashboard?locationName=XPG_KONTICH_PBL24E&switchView=true">
                        /tvdashboard?locationName=XPG_KONTICH_PBL24E&switchView=true
                    </a>
                    '
                </p>
            </div>

            <FullScreen handle={handle}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: handle.active ? '100vh' : 'auto'
                    }}
                >
                    {showWallOfFame ? (
                        <div style={{ textAlign: 'center' }}>
                            <h4>Wall Of Fame</h4>
                        </div> // not yet implemented
                    ) : (
                        <div className="dashboard" style={{ width: '100%' }}>
                            {isCslStationsDataLoading && chargingStations.length === 0 && (
                                <div style={{ textAlign: 'center' }}>
                                    <h4>Loading dashboard...</h4>
                                </div>
                            )}
                            {isCslStationsError && chargingStations.length === 0 && (
                                <div style={{ marginLeft: '1%', marginRight: '1%' }}>
                                    <ApiError error={CslStationsError} />
                                </div>
                            )}
                            <div className="dashboard-grid-container">
                                {chargingStations.map((cs) => (
                                    <DashboardItem key={cs.key} chargingStation={cs} />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </FullScreen>
        </>
    );
}
