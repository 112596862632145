import React, { useState } from 'react';
import { Table, Accordion } from 'react-bootstrap';
import moment from 'moment';
import ArrowUp from '../shared/icon/ArrowUp';
import Clock from '../shared/icon/Clock';
import EvFront from '../shared/icon/EvFront';
import EvStation from '../shared/icon/EvStation';
import InformationPopover from '../shared/InformationPopover';
import UserStats from '../../model/stats/UserStats';
import CaretDown from '../shared/icon/CaretDown';
import CaretUp from '../shared/icon/CaretUp';

interface UserStatDetailProps {
    userStats: UserStats[];
}

function UserStatsTable(props: UserStatDetailProps) {
    const [activeKey, setActiveKey] = useState<string | null>(null);

    const toggleRow = (key: string) => {
        setActiveKey(activeKey === key ? null : key);
    };

    return (
        <Accordion activeKey={activeKey}>
            <Table className="user-stats-table">
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Session Time</th>
                        <th>Amount Charged</th>
                        <th>Charging Time</th>
                        <th>Occupation Time</th>
                        <th>Occupation Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {props.userStats.map((stat, index) => {
                        const chargingTime = moment.duration(stat.chargingTime);
                        const occupationTime = moment.duration(stat.occupationTime);
                        const unmovedDuration = moment.duration(occupationTime.asMinutes() - chargingTime.asMinutes(), 'minutes');
                        const badUnmovedDurationMinutes = 30;

                        return (
                            <React.Fragment key={`stat-${index}`}>
                                <tr
                                    style={{ cursor: window.innerWidth > 768 ? 'pointer' : 'default' }}
                                    onClick={() => {
                                        if (window.innerWidth > 768) {
                                            toggleRow(`stat-${index}`);
                                        }
                                    }}
                                >
                                    <td data-label="Location">{stat.chargingStationLocation.displayName}</td>
                                    <td data-label="Session Time">
                                        {moment(stat.startDate).format('DD/MM HH:mm')} - {moment(stat.endDate).format('HH:mm')}
                                    </td>
                                    <td data-label="Amount Charged">
                                        <ArrowUp />
                                        &nbsp;{stat.kWh} kWh
                                    </td>
                                    <td data-label="Charging Time">
                                        <EvFront />
                                        &nbsp;{moment.duration(stat.chargingTime).humanize()}
                                    </td>
                                    <td
                                        data-label="Occupation Time"
                                        style={unmovedDuration.asMinutes() > badUnmovedDurationMinutes ? { color: 'var(--bs-xploregroup-red)' } : {}}
                                    >
                                        <Clock />
                                        &nbsp;{moment.duration(stat.occupationTime).humanize()}
                                    </td>
                                    <td data-label="Occupation Rate">
                                        <EvStation />
                                        &nbsp;
                                        {Math.max(
                                            stat.occupiedStationsWhenFinishedCharging,
                                            stat.occupiedStationsWhenMoved,
                                            stat.maxOccupiedStationsWhileUnmoved ?? 0
                                        )}
                                        / {stat.totalChargingStations}
                                    </td>
                                    <td className="arrow-cell">{activeKey === `stat-${index}` ? <CaretUp /> : <CaretDown />}</td>
                                </tr>
                                <tr className={`details ${activeKey === `stat-${index}` ? 'expanded' : ''}`}>
                                    <td colSpan={6}>
                                        <Accordion.Collapse eventKey={`stat-${index}`}>
                                            <div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {/* Left Column */}
                                                    <div style={{ flex: '0 0 48%' }}>
                                                        <p>
                                                            <strong>
                                                                Location
                                                                <InformationPopover infoHeader="Location">
                                                                    <p>
                                                                        The office where this session occurred. We only have information about
                                                                        sessions that occur at our offices.
                                                                    </p>
                                                                </InformationPopover>
                                                                : &nbsp;
                                                            </strong>
                                                            {stat.chargingStationLocation.displayName}
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                Start Time
                                                                <InformationPopover infoHeader="Start time">
                                                                    <p>
                                                                        The date and time we detected the session started. This can be off by up to 10
                                                                        minutes.
                                                                    </p>
                                                                </InformationPopover>
                                                                : &nbsp;
                                                            </strong>
                                                            {moment(stat.startDate).format('HH:mm DD/MM/YYYY')}
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                End Time
                                                                <InformationPopover infoHeader="End time">
                                                                    <p>
                                                                        The date and time we detected the session ended. This can be off by up to 10
                                                                        minutes.
                                                                    </p>
                                                                </InformationPopover>
                                                                : &nbsp;
                                                            </strong>
                                                            {moment(stat.endDate).format('HH:mm DD/MM/YYYY')}
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                Charging Time
                                                                <InformationPopover infoHeader="Charging time">
                                                                    <p>
                                                                        The amount of time your vehicle was charging. This can be off by up to 10
                                                                        minutes.
                                                                    </p>
                                                                </InformationPopover>
                                                                : &nbsp;
                                                            </strong>
                                                            {moment.duration(stat.chargingTime).humanize()}
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                Occupation Time
                                                                <InformationPopover infoHeader="Occupation time">
                                                                    <p>
                                                                        The amount of time your vehicle was occupying the charging station. This can
                                                                        be off by up to 10 minutes.
                                                                    </p>
                                                                </InformationPopover>
                                                                : &nbsp;
                                                            </strong>
                                                            {moment.duration(stat.occupationTime).humanize()}
                                                        </p>
                                                        {unmovedDuration.asMinutes() === 0 ? (
                                                            <></>
                                                        ) : (
                                                            <p>
                                                                <strong>
                                                                    Unmoved Duration
                                                                    <InformationPopover infoHeader="Unmoved duration">
                                                                        <p>The amount of time between your car being charged and it being moved.</p>
                                                                    </InformationPopover>
                                                                    : &nbsp;
                                                                </strong>
                                                                <span
                                                                    style={
                                                                        unmovedDuration.asMinutes() > badUnmovedDurationMinutes
                                                                            ? { color: 'var(--bs-xploregroup-red)' }
                                                                            : {}
                                                                    }
                                                                >
                                                                    {unmovedDuration.hours()} hour(s) {unmovedDuration.minutes()} minute(s)
                                                                </span>
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div style={{ flex: '0 0 48%' }}>
                                                        <p>
                                                            <strong>
                                                                Amount Charged
                                                                <InformationPopover infoHeader="Amount charged">
                                                                    <p>The amount of electricity you charged during this session.</p>
                                                                </InformationPopover>
                                                                : &nbsp;
                                                            </strong>
                                                            {stat.kWh} kWh
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                Was Load Balanced
                                                                <InformationPopover infoHeader="Was load balanced">
                                                                    <p>Whether load balancing occurred during this charging session.</p>
                                                                    <p>
                                                                        Load balancing occurs when there isn't sufficient electrical capacity to keep
                                                                        all the charging stations active simultaneously, causing some stations to
                                                                        pause partway through the charging session.
                                                                    </p>
                                                                </InformationPopover>
                                                                : &nbsp;
                                                            </strong>
                                                            {stat.wasLoadBalanced ? 'Yes' : 'No'}
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                Occupation when finished charging
                                                                <InformationPopover infoHeader="Occupation when finished charging">
                                                                    <p>
                                                                        The number of stations that were occupied out of the total stations when your
                                                                        car finished charging
                                                                    </p>
                                                                </InformationPopover>
                                                                : &nbsp;
                                                            </strong>
                                                            {stat.occupiedStationsWhenFinishedCharging}/{stat.totalChargingStations}
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                Occupation when moved
                                                                <InformationPopover infoHeader="Occupation when moved">
                                                                    <p>
                                                                        The number of stations that were occupied out of the total stations when you
                                                                        moved your car
                                                                    </p>
                                                                </InformationPopover>
                                                                : &nbsp;
                                                            </strong>
                                                            {stat.occupiedStationsWhenMoved}/{stat.totalChargingStations}
                                                        </p>
                                                        {stat.maxOccupiedStationsWhileUnmoved != null ? (
                                                            <p>
                                                                <strong>
                                                                    Max Occupation while unmoved
                                                                    <InformationPopover infoHeader="Max occupation while unmoved">
                                                                        <p>
                                                                            The maximum number of stations that were occupied out of the total
                                                                            stations between when your car finished charging and when you moved your
                                                                            car
                                                                        </p>
                                                                    </InformationPopover>
                                                                    : &nbsp;
                                                                </strong>
                                                                {stat.maxOccupiedStationsWhileUnmoved}/{stat.totalChargingStations}
                                                            </p>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                    {props.userStats.length === 0 && (
                        <tr className="no-results">
                            <td colSpan={10}>
                                <h1>No results</h1>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Accordion>
    );
}

export default UserStatsTable;
