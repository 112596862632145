import NavigationBar from '../../../shared/NavigationBar';
import Footer from '../../../shared/Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useCheckIfAdminOrManager from '../../../../hooks/useCheckIfAdminOrManager';
import { ChangeEvent, useEffect, useState } from 'react';
import { Alert, ButtonGroup, Form, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { useQuery } from 'react-query';
import {
    createCar,
    deleteCar,
    getCarBrandNames,
    getCarByLicensePlate,
    getCarModelNames,
    getCarOfUser,
    reassignCar,
    updateCar
} from '../../../../service/Api';
import Button from 'react-bootstrap/Button';
import ToastMessage from '../../../shared/ToastMessage';
import { isAxiosError } from 'axios';
import { FormProvider, SetValueConfig, useForm } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { SingleValue } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import Car from '../../../../model/car/Car';
import CarBrand from '../../../../model/car/CarBrand';
import CarModel from '../../../../model/car/CarModel';
import { Variant } from 'react-bootstrap/types';
import licensePlateRegex from '../../../../model/LicensePlateRegex';

interface Option {
    value: string;
    label: string;
}

export default function UserCarManagementPage() {
    const isAdminOrManager = useCheckIfAdminOrManager();
    const navigate = useNavigate();
    const location = useLocation();

    const methods = useForm<Car>({
        criteriaMode: 'all'
    });

    const [userDetails, setUserDetails] = useState<{ name: string; id: string; email: string }>({
        name: location.state?.username ?? '',
        id: location.state?.userId ?? '',
        email: location.state?.userEmail ?? ''
    });

    const [userCar, setUserCar] = useState<Car | null>(null);
    const [editingState, setEditingState] = useState<'updating' | 'deleting' | 'reassign' | 'creating' | 'error' | ''>('');
    const [resetModel, setResetModel] = useState<boolean>(false);
    const [toast, setToast] = useState<{ message: string; bg?: Variant; autoHide: boolean; title?: string }>({
        message: '',
        autoHide: true
    });

    const {
        data: userCarData,
        isLoading: isUserCarDataLoading,
        error: userCarError,
        refetch: refetchUserCar
    } = useQuery(['userCar', userDetails.id], () => getCarOfUser(userDetails.id), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: userDetails.id !== ''
    });

    const { data: carBrands, isLoading: isLoadingCarBrands } = useQuery('carBrandNames', () => getCarBrandNames(), {
        refetchOnWindowFocus: false,
        enabled: editingState === 'creating' || editingState === 'updating'
    });

    const { data: carModels, isLoading: isLoadingCarModels } = useQuery(
        ['carModelNames', userCar?.brand],
        () => getCarModelNames(userCar?.brand ?? ''),
        {
            refetchOnWindowFocus: false,
            enabled: (editingState === 'creating' || editingState === 'updating') && userCar?.brand != null && userCar?.brand !== ''
        }
    );

    useEffect(() => {
        if (!isAdminOrManager) {
            navigate('/dashboard');
        }
    }, [isAdminOrManager, navigate]);

    useEffect(() => {
        setUserDetails({
            name: location.state?.username ?? '',
            id: location.state?.userId ?? '',
            email: location.state?.userEmail ?? ''
        });
    }, [location.state]);

    useEffect(() => {
        if (isUserCarDataLoading) return;

        if (userCarError) {
            if (!isAxiosError(userCarError) || userCarError.response === undefined) {
                setEditingState('error');
                return;
            }
            if (userCarError.response.status === 404) {
                setEditingState('creating');
                setUserCar({
                    id: null,
                    brand: '',
                    model: '',
                    licensePlate: '',
                    brandId: null,
                    modelId: null,
                    reassignable: false,
                    userId: parseInt(userDetails.id) ?? null
                });
            } else {
                setEditingState('error');
            }
        } else if (userCarData && userCarData.data) {
            setEditingState('updating');
            const car = userCarData.data as Car;
            setUserCar(car);
            methods.setValue('brand', car.brand);
            methods.setValue('model', car.model);
            methods.setValue('licensePlate', car.licensePlate);
        } else {
            setUserCar(null);
            setEditingState('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserCarDataLoading, userCarData, userCarError]);

    useEffect(() => {
        if ((editingState === 'creating' || editingState === 'updating') && (!userCar?.brand || resetModel)) {
            handleChange('model', '', methods.setValue);
            setResetModel(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCar?.brand, resetModel]);

    const getSelectOptions = (possibleVals: string[] | undefined) => {
        if (!possibleVals) {
            return [];
        }
        return possibleVals.map((value) => {
            return { value: value, label: value };
        });
    };

    const customFilterOption = (option: FilterOptionOption<Option>, inputValue: string) => {
        return option.value.toLowerCase().includes(inputValue.toLowerCase());
    };

    const mapToOption = (value: string | null | undefined): Option | null => {
        if (!value) return null;
        return { value: value, label: value };
    };

    const handleError = (e: any) => {
        console.error(e);
        if (isAxiosError(e) && e.response?.data?.detail) {
            setToast({ autoHide: false, bg: 'danger', title: 'Something went wrong', message: e.response.data.detail });
        } else {
            setToast({ ...toast, bg: 'danger', message: 'Something unexpected went wrong, please try again later.' });
        }
    };

    const handleDeleteCar = () => {
        if (userCar == null || userCar.id == null) {
            setToast({
                ...toast,
                bg: 'dander',
                message:
                    'Could not delete car because of a missing ID.  Please try again later.  If the issue persists, please contact the website maintainer.'
            });
            return;
        }

        deleteCar(userCar.id)
            .then(() => {
                setToast({ ...toast, message: 'Successfully deleted car' });
                setEditingState('creating');
                refetchUserCar();
            })
            .catch((e) => {
                handleError(e);
                setEditingState('updating');
            });
    };

    const handleChange = <K extends keyof Car>(
        name: K,
        value: Car[K],
        setValue: (name: K, value: Car[K], options?: Partial<SetValueConfig>) => void
    ) => {
        // @ts-ignore
        setUserCar({ ...userCar, [name]: value === undefined ? null : value });
        setValue(name, value);
        if (name === 'licensePlate' && value !== '') {
            checkCarBelongsToOtherUser(value as string);
        }
        if (name === 'brand') {
            setResetModel(true);
        }
    };

    const checkCarBelongsToOtherUser = (licensePlate: string) => {
        if (!licensePlateRegex.test(licensePlate)) {
            return;
        }
        getCarByLicensePlate(licensePlate).then((result) => {
            if (result.data && result.data.userId && result.data.userId !== userDetails.id) {
                setEditingState('reassign');
            }
        });
    };

    const handleReassignCar = () => {
        if (userCar !== null) {
            reassignCar(userCar)
                .then(() => {
                    setToast({ ...toast, message: 'Successfully updated car' });
                    setEditingState('updating');
                    refetchUserCar();
                })
                .catch((e) => {
                    handleError(e);
                });
        }
    };

    const handleSubmit = (data: Car) => {
        if (editingState === 'creating') {
            createCar({
                email: userDetails.email,
                brandName: data.brand,
                modelName: data.model,
                licensePlate: data.licensePlate
            })
                .then(() => {
                    setToast({ ...toast, message: 'Successfully created car' });
                    setEditingState('updating');
                    refetchUserCar();
                })
                .catch((e) => {
                    handleError(e);
                });
        } else {
            if (userCar == null) {
                setToast({
                    ...toast,
                    bg: 'danger',
                    message:
                        'Could not update car because no existing car was found.  Please try again.  If this error persists, please contact the website maintainer.'
                });
                return;
            }

            updateCar({
                id: userCar.id,
                brandId: null,
                brand: data.brand,
                modelId: null,
                model: data.model,
                licensePlate: data.licensePlate,
                reassignable: false,
                userId: userCar.userId
            })
                .then(() => {
                    setToast({ ...toast, message: 'Successfully updated car' });
                    setEditingState('updating');
                    refetchUserCar();
                })
                .catch((e) => {
                    handleError(e);
                });
        }
    };

    if (location.state == null || userDetails.name === '' || userDetails.id === '' || userDetails.email === '' || editingState === 'error') {
        const originalSearchText = location.state?.originalSearchText ?? '';

        return (
            <>
                <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                    <NavigationBar />
                    <div style={{ padding: '1em' }}>
                        <Alert variant="danger">
                            <p>
                                {editingState === 'error' ? <>Something unexpected went wrong.</> : <>Unable to load user details.</>} Please try
                                again by&nbsp;
                                <Link to={{ pathname: '/fuelcard/search' }} state={{ searchText: originalSearchText }}>
                                    returning to fuelcard search page
                                </Link>
                                .
                            </p>
                            <p style={{ marginBottom: '0' }}>If this error persists, please contact the website maintainer.</p>
                        </Alert>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <ToastMessage
                message={toast.message}
                showToastMessage={toast.message !== ''}
                bg={toast.bg}
                autoHide={toast.autoHide}
                title={toast.title}
                setShowToastMessage={(show) => {
                    if (!show) {
                        setToast({
                            message: '',
                            bg: undefined,
                            autoHide: toast.bg === 'danger' ? false : true,
                            title: undefined
                        });
                    }
                }}
            />

            <div style={{ minHeight: 'var(--bs-content-min-heigth)' }}>
                <NavigationBar />
                <div style={{ padding: '1em' }}>
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                        <h1>Managing car of {userDetails.name}</h1>
                        <Link to={{ pathname: '/fuelcard/search' }} state={{ searchText: location.state.originalSearchText ?? '' }}>
                            Return to fuelcard search page
                        </Link>
                    </div>

                    {editingState === 'creating' ? <h2 className={'h4'}>Create a new car:</h2> : <></>}
                    {editingState === 'updating' ? <h2 className={'h4'}>Update the car:</h2> : <></>}
                    {editingState === 'creating' || editingState === 'updating' ? (
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(handleSubmit)}>
                                <Form.Group>
                                    <Form.Label>License Plate:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoFocus
                                        id="licensePlate"
                                        {...methods.register('licensePlate', {
                                            required: 'License plate is required',
                                            pattern: {
                                                value: licensePlateRegex,
                                                message:
                                                    "Please enter a valid Belgian or Dutch license plate. Valid Belgian formats: ABC-123, 1-ABC-123, Z-ABC-123. Valid Dutch formats: A-123-BC, ABC-12-D. ('-', ' ', and '.' are accepted separators)"
                                            }
                                        })}
                                        isInvalid={!!methods.formState.errors.licensePlate}
                                        value={userCar?.licensePlate ?? ''}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                            handleChange('licensePlate', e.target.value.toUpperCase(), methods.setValue)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {typeof methods.formState.errors.licensePlate?.message === 'string' &&
                                            methods.formState.errors.licensePlate.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Car brand:</Form.Label>
                                    <CreatableSelect
                                        classNamePrefix="react-select"
                                        placeholder="Select car brand ..."
                                        className="selectDropdown"
                                        isClearable
                                        options={getSelectOptions(carBrands?.data.map((brand: CarBrand) => brand.name))}
                                        filterOption={customFilterOption}
                                        isLoading={isLoadingCarBrands}
                                        onChange={(newVal: SingleValue<any>) => {
                                            handleChange('brand', newVal ? newVal.value : '', methods.setValue);
                                        }}
                                        value={mapToOption(userCar?.brand)}
                                        menuPlacement="auto"
                                    />
                                    <Form.Control
                                        hidden
                                        disabled
                                        value={userCar?.brand}
                                        isInvalid={!!methods.formState.errors.brand}
                                        {...methods.register('brand', {
                                            required: 'Brand is required.',
                                            minLength: { value: 1, message: 'Brand name must be at least 1 character.' },
                                            maxLength: { value: 50, message: 'Brand name must be less than or equal to 50 characters.' },
                                            pattern: {
                                                value: /^[\p{L}0-9 .\-+#]+$/u,
                                                message: 'Brand name may only contain letters, numbers, spaces, periods, and hyphens.'
                                            }
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {typeof methods.formState.errors.brand?.message === 'string' && methods.formState.errors.brand.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Car model:</Form.Label>
                                    <CreatableSelect
                                        classNamePrefix="react-select"
                                        placeholder={'Select ' + userCar?.brand + ' model ...'}
                                        className="selectDropdown"
                                        isClearable
                                        options={getSelectOptions(carModels?.data.map((model: CarModel) => model.name))}
                                        filterOption={customFilterOption}
                                        isLoading={isLoadingCarModels}
                                        onChange={(newVal: SingleValue<any>) => handleChange('model', newVal ? newVal.value : '', methods.setValue)}
                                        value={mapToOption(userCar?.model)}
                                        menuPlacement="auto"
                                    />
                                    <Form.Control
                                        hidden
                                        disabled
                                        value={userCar?.model}
                                        isInvalid={!!methods.formState.errors.model}
                                        {...methods.register('model', {
                                            required: 'Model is required.',
                                            minLength: { value: 1, message: 'Model name must be at least 1 character.' },
                                            maxLength: { value: 50, message: 'Model name must be less than or equal to 50 characters.' },
                                            pattern: {
                                                value: /^[\p{L}0-9 .\-+#]+$/u,
                                                message: 'Model name may only contain letters, numbers, spaces, periods, and hyphens.'
                                            }
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {typeof methods.formState.errors.model?.message === 'string' && methods.formState.errors.model.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <ButtonGroup style={{ margin: '2% auto', width: '80%' }}>
                                    {editingState === 'updating' ? (
                                        <Button
                                            style={{ marginRight: '2%', borderRadius: '3px' }}
                                            variant="outline-secondary"
                                            onClick={() => {
                                                navigate('/fuelcard/search', { state: { searchText: location.state.originalSearchText ?? '' } });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                    <Button style={{ borderRadius: '3px' }} variant="danger" onClick={() => setEditingState('deleting')}>
                                        Delete
                                    </Button>
                                    <Button style={{ marginLeft: '2%', borderRadius: '3px' }} variant="success" type="submit">
                                        {editingState === 'creating' ? 'Create' : 'Submit'}
                                    </Button>
                                </ButtonGroup>
                            </Form>
                        </FormProvider>
                    ) : (
                        <></>
                    )}

                    <Modal show={editingState === 'reassign'} onHide={() => setEditingState('updating')}>
                        <ModalBody>
                            <p>
                                Are you sure you want to reassign "{'(' + userCar?.licensePlate + ')'}" to {userDetails.name}?
                            </p>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="outline-secondary" onClick={() => setEditingState('updating')}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleReassignCar}>
                                Reassign
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal show={editingState === 'deleting'} onHide={() => setEditingState('updating')}>
                        <ModalBody>
                            <p>
                                Are you sure you want to delete "{userCar?.brand + ' ' + userCar?.model + ' (' + userCar?.licensePlate + ')'}" of{' '}
                                {userDetails.name}?
                            </p>
                            <p>This action cannot be undone.</p>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="outline-secondary" onClick={() => setEditingState('updating')}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleDeleteCar}>
                                Delete car
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
            <Footer />
        </>
    );
}
