import { ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { Variant } from 'react-bootstrap/types';

interface ToastMessageProps {
    title?: string;
    message: string;
    showToastMessage: boolean;
    setShowToastMessage: (show: boolean) => void;
    bg?: Variant;
    autoHide?: boolean;
    delay?: number;
}

export default function ToastMessage(props: ToastMessageProps) {
    return (
        <ToastContainer className="p-3 position-fixed" position="top-center">
            <Toast
                onClose={() => props.setShowToastMessage(false)}
                show={props.showToastMessage}
                delay={props.delay ?? 3000}
                autohide={props.autoHide ?? true}
                bg={props.bg}
            >
                <Toast.Header>
                    <strong className="me-auto">{props.title ?? ''}</strong>
                </Toast.Header>
                <Toast.Body>{props.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}
