import UserDto from '../../../../model/user/UserDto';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { deleteUser } from '../../../../service/Api';
import ApiError from '../../../shared/ApiError';

interface DeleteInactiveUserModalProps {
    userData: UserDto;
    show: boolean;
    setShow: (show: boolean) => void;
}

export default function DeleteInactiveUserModal(props: DeleteInactiveUserModalProps) {
    const [deleteState, setDeleteState] = useState<'initial' | 'deleted' | 'error'>('initial');
    const [deleteError, setDeleteError] = useState<unknown | undefined>(undefined);
    if (!props.show) {
        setDeleteState('initial');
        return <></>;
    }

    const doDelete = () => {
        deleteUser(props.userData.contactEmail)
            .then(() => setDeleteState('deleted'))
            .catch((e) => {
                setDeleteState('error');
                setDeleteError(e);
            });
    };

    return (
        <Modal show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Are you sure you want to delete {props.userData.firstName} {props.userData.lastName}?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'left' }}>
                {deleteState === 'initial' ? (
                    <>
                        <p>
                            Are you sure you want to delete {props.userData.firstName} {props.userData.lastName}?
                        </p>
                        <p>
                            The following data will be deleted:
                            <ul>
                                <li>Their car</li>
                                <li>Their fuelcards</li>
                                <li>Their role</li>
                                <li>Their user data</li>
                            </ul>
                        </p>
                        <p>This action cannot be undone.</p>
                    </>
                ) : (
                    <></>
                )}
                {deleteState === 'deleted' ? <p>Successfully deleted user</p> : <></>}
                {deleteState === 'error' ? <ApiError error={deleteError} title={'Failed to delete user'} /> : <></>}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center" style={{ flexWrap: 'nowrap' }}>
                <ButtonGroup hidden={deleteState !== 'initial'}>
                    <Button style={{ width: 'fit-content' }} variant="outline-secondary" onClick={() => props.setShow(false)}>
                        Close without deleting
                    </Button>
                    <Button style={{ width: 'fit-content' }} variant="warning" onClick={doDelete}>
                        Delete user
                    </Button>
                </ButtonGroup>

                <Button hidden={deleteState === 'initial'} style={{ width: 'fit-content' }} variant="secondary" onClick={() => props.setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
