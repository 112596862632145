import ChargingStationLocation from '../model/ChargingStationLocation';

const preferredDashboardKey = 'preferredDashboard';

const getPreferredCsl = (locations: ChargingStationLocation[]): ChargingStationLocation => {
    const preferredDashboardName = localStorage.getItem(preferredDashboardKey);
    let toSelectCsl = locations[0];
    if (preferredDashboardName != null) {
        const prefCsl = locations.find((csl: ChargingStationLocation) => csl.name === preferredDashboardName);
        if (prefCsl !== undefined) {
            toSelectCsl = prefCsl;
        }
    }
    return toSelectCsl;
};

export { getPreferredCsl };
