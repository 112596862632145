import '../../../styling/fuelcardSearch.css';
import ChargingStationLocation from '../../../model/ChargingStationLocation';
import useCheckIfAdminOrManagerForLocation from '../../../hooks/useCheckIfAdminOrManagerForLocation';
import { useQuery } from 'react-query';
import { getNotificationSettings, updateNotificationSetting } from '../../../service/Api';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { Form } from 'react-bootstrap';
import { FormEvent, useEffect, useState } from 'react';
import ApiError from '../../shared/ApiError';
import NotificationType from '../../../model/NotificationType';
import InformationPopover from '../../shared/InformationPopover';
import NotificationSetting from '../../../model/notification/NotificationSettings';

interface ManageCslNotificationSettingProps {
    csl: ChargingStationLocation;
}

export default function ManageCslNotificationSettings(props: ManageCslNotificationSettingProps) {
    const isAdminOrManager = useCheckIfAdminOrManagerForLocation(props.csl.name);

    const [notifSettings, setNotifSettings] = useState<NotificationSetting[]>([]);
    const [notifCcUpdateError, setNotifCcUpdateError] = useState<unknown | undefined>(undefined);

    const {
        data: notifSettingsData,
        isLoading: notifSettingsLoading,
        error: notifSettingsError,
        refetch: notifSettingsRefetch
    } = useQuery(['notifSettings', props.csl.name], () => getNotificationSettings(props.csl.name), {
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (!notifSettingsLoading && !notifSettingsError) {
            const fetchedData = notifSettingsData?.data;
            if (Array.isArray(fetchedData)) {
                setNotifSettings(fetchedData);
            }
        }
    }, [notifSettingsLoading, notifSettingsError, notifSettingsData]);

    if (!isAdminOrManager) {
        return <></>;
    } else if (notifSettingsLoading) {
        return (
            <div style={{ width: '100%' }}>
                <LoadingSpinner />
                <p style={{ width: '100%', textAlign: 'center' }}>Loading notification cc preferences for {props.csl.displayName}</p>
            </div>
        );
    } else if (notifSettingsError) {
        return <ApiError error={notifSettingsError} title={<>Failed to load notification cc preferences for {props.csl.displayName}</>} />;
    } else if (notifCcUpdateError) {
        return <ApiError error={notifCcUpdateError} title={<>Failed to update notification cc preferences for {props.csl.displayName}</>} />;
    }

    const doUpdate = async (e: FormEvent<HTMLInputElement>, notifSettingToUpdate: NotificationSetting) => {
        e.preventDefault();
        updateNotificationSetting(notifSettingToUpdate.notification, notifSettingToUpdate.chargingStationLocationName)
            .then(() => notifSettingsRefetch())
            .catch((e) => {
                setNotifCcUpdateError(e);
            });
    };

    return (
        <div className="notificationMngmtItemHolder">
            <h2 className="h4">{props.csl.displayName} notification settings</h2>
            {notifSettings
                .sort((a, b) => (a.notification < b.notification ? -1 : 1))
                .map((pref) => (
                    <div className={'notificationMngmtItem'} key={pref.notification + pref.chargingStationLocationName}>
                        <div className={'notificationMngmtItemTitle'}>
                            {pref.notification === NotificationType.FINISHED_CHARGING ? 'Fully Charged' : ''}
                            {pref.notification === NotificationType.UNMOVED_EV ? 'Unmoved Vehicle reminder' : ''}
                            {pref.notification === NotificationType.AVAILABLE_STATION ? 'Available Station' : ''}
                            {pref.notification === NotificationType.BLOCKED_FUELCARD ? 'Blocked Fuelcard' : ''}
                            {pref.notification === NotificationType.POOL_USER_READY_FOR_PICKUP ? 'Pool User ready for pickup' : ''}
                            {pref.notification === NotificationType.STATION_DOWN ? 'Station Down' : ''}
                            {pref.notification === NotificationType.STATION_DOWN_REMINDER ? 'Station Down Reminder' : ''}
                            {pref.notification === NotificationType.UNKNOWN_FUELCARD ? 'Unknown Fuelcard' : ''}
                        </div>

                        <div className={'notificationMngmtItemButton centerSelf'}>
                            <Form.Check type="switch" onChange={(e) => doUpdate(e, pref)} checked={pref.isActive} />
                            <div>
                                <InformationPopover>
                                    <>
                                        <p hidden={pref.notification !== NotificationType.FINISHED_CHARGING}>
                                            This toggle will enable/disable whether the finished charging notifications are sent out.
                                        </p>
                                        <p hidden={pref.notification !== NotificationType.UNMOVED_EV}>
                                            This toggle will enable/disable whether unmoved vehicle reminder notifications are sent out.
                                        </p>
                                        <p hidden={pref.notification !== NotificationType.AVAILABLE_STATION}>
                                            This toggle will enable/disable whether notifications about available stations are sent out.
                                        </p>
                                        <p hidden={pref.notification !== NotificationType.BLOCKED_FUELCARD}>
                                            This toggle will enable/disable whether notifications about blocked fuelcards are sent out.
                                        </p>
                                        <p hidden={pref.notification !== NotificationType.POOL_USER_READY_FOR_PICKUP}>
                                            This toggle will enable/disable whether notifications about pool users ready for pickup are sent out.
                                        </p>
                                        <p hidden={pref.notification !== NotificationType.STATION_DOWN}>
                                            This toggle will enable/disable whether notifications about stations being down are sent out.
                                        </p>
                                        <p hidden={pref.notification !== NotificationType.STATION_DOWN_REMINDER}>
                                            This toggle will enable/disable whether reminders about stations being down are sent out.
                                        </p>
                                        <p hidden={pref.notification !== NotificationType.UNKNOWN_FUELCARD}>
                                            This toggle will enable/disable whether notifications about unknown fuelcards are sent out.
                                        </p>
                                    </>
                                </InformationPopover>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
}
