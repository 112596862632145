import getUserAgent from './useGetUserAgent';

export default function likelySupportsNfc(): boolean | null {
    const userAgent = getUserAgent();

    if (userAgent == null) {
        return null;
    }

    try {
        const browser = userAgent.getBrowser().name?.toLowerCase();
        if (browser === undefined) {
            return null;
        }

        const isAndroidDevice = userAgent.getOS().name?.toLowerCase() === 'android';
        const isSupportedBrowser = browser === 'chrome' || browser === 'opera' || browser.includes('samsung') || browser.includes('webview');

        return isAndroidDevice && isSupportedBrowser;
    } catch (e) {
        console.error('Failed to determine if this browser likely supports webNFC', e);
    }

    return null;
}
