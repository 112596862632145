import { isStationDown } from '../../../../service/ChargingStationFunctions';
import ChargingStation from '../../../../model/ChargingStation';

interface CsNumberProps {
    chargingStation: ChargingStation;
}

export default function CsNumber(props: CsNumberProps) {
    return (
        <div className="csNumber" style={{ border: 'none' }}>
            <div className={isStationDown(props.chargingStation) ? 'circle-container circle-container-down' : 'circle-container'}>
                <h4 className={isStationDown(props.chargingStation) ? 'crossed' : ''}>{props.chargingStation.nr}</h4>
            </div>
        </div>
    );
}
