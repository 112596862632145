import '../../styling/announcementBanner.css';
import Announcement from '../../model/Announcement';
import { Alert } from 'react-bootstrap';
import ExclamationTriangle from './icon/ExclamationTriangle';

interface AnnouncementBannerProps {
    announcement?: Announcement | null;
    show: boolean;
}

export default function AnnouncementBanner(props: AnnouncementBannerProps) {
    if (!props.show || props.announcement == null || props.announcement.message === undefined) {
        return <></>;
    }

    if (props.announcement.outOfService) {
        return (
            <div className="outOfServiceContainer">
                <Alert variant={'warning'}>
                    <Alert.Heading>
                        <ExclamationTriangle /> Out Of Service
                    </Alert.Heading>
                    <p>This location is currently out of service. We apologize for the inconvenience.</p>
                    <hr />
                    <p className={'mb-0'}>{props.announcement.message}</p>
                </Alert>
            </div>
        );
    }

    return (
        <div className="announcementContainer">
            <Alert variant={'warning'}>{props.announcement.message}</Alert>
        </div>
    );
}
