import { Button, Modal } from 'react-bootstrap';

interface OtherUserCarModalProps {
    showOtherUserCar: boolean;
    setShowOtherUserCar: (show: boolean) => void;
    submitFeedback: () => void;
    clearEnteredCar?: () => void;
    hideClearEnteredCarButton?: boolean;
    licensePlate: string;
}

export default function OtherUserCarModal(props: OtherUserCarModalProps) {
    return (
        <Modal size="lg" centered show={props.showOtherUserCar} onHide={() => props.setShowOtherUserCar(false)}>
            <Modal.Header closeButton={props.hideClearEnteredCarButton}>
                <Modal.Title>Car belongs to another user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>The license plate ({props.licensePlate}) you entered belongs to a car that belongs to another user.</p>
                <p>
                    If you believe this is incorrect, you can contact the site maintainer so they can manually correct this by clicking the "submit
                    feedback" button below.
                </p>
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant={'outline-danger'}
                    hidden={props.hideClearEnteredCarButton}
                    onClick={() => {
                        if (props.clearEnteredCar) {
                            props.clearEnteredCar();
                        }
                        props.setShowOtherUserCar(false);
                    }}
                >
                    Enter different license plate
                </Button>
                <Button
                    variant={'primary'}
                    onClick={() => {
                        props.submitFeedback();
                        props.setShowOtherUserCar(false);
                    }}
                >
                    Submit feedback
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
