import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Dispatch, SetStateAction, useState } from 'react';
import NotificationToggleMgr from '../../../../model/NotificationToggleMgr';
import { formatTo2Numbers, formatToIsoString } from '../../../../service/DateTimeFunctions';
import DateTime, { DateTimeBuilder } from '../../../../model/DateTime';

interface SetReminderTimeModalProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    doSetTime: (toggle: NotificationToggleMgr) => Promise<void>;
    toggle: NotificationToggleMgr;
}

export default function SetReminderTimeModal(props: SetReminderTimeModalProps) {
    const [reminderDateTime, setReminderDateTime] = useState<DateTime>(
        new DateTimeBuilder()
            .withHour(formatTo2Numbers(new Date().getHours() + 2 < 24 ? (new Date().getHours() + 2).toString() : new Date().getHours().toString()))
            .withMinute('00').build
    );

    const handleClose = () => props.setShow(false);

    const handleSetTime = () => {
        handleClose();
        props.toggle.reminder = formatToIsoString(reminderDateTime);
        props.doSetTime(props.toggle);
    };

    return (
        <Modal show={props.show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Set a time for the reminder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Please select the date and time you wish to be reminded at if station #{props.toggle.chargingStation.nr} has remained down from
                    now until then. If the station comes back up between now and the entered time, the reminder will be disabled.
                </p>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Date: </InputGroup.Text>
                    <Form.Control
                        aria-label="Day"
                        maxLength={2}
                        required
                        value={reminderDateTime.day}
                        onChange={(e) => setReminderDateTime({ ...reminderDateTime, day: e.target.value })}
                        isValid={
                            reminderDateTime.day !== '' && Number.parseInt(reminderDateTime.day) > 0 && Number.parseInt(reminderDateTime.day) < 32
                        }
                    />
                    <InputGroup.Text>/</InputGroup.Text>
                    <Form.Control
                        aria-label="Month"
                        maxLength={2}
                        required
                        value={reminderDateTime.month}
                        onChange={(e) => setReminderDateTime({ ...reminderDateTime, month: e.target.value })}
                        isValid={
                            reminderDateTime.month !== '' &&
                            Number.parseInt(reminderDateTime.month) > 0 &&
                            Number.parseInt(reminderDateTime.month) < 13
                        }
                    />
                    <InputGroup.Text>/</InputGroup.Text>
                    <Form.Control
                        aria-label="Year"
                        maxLength={4}
                        required
                        value={reminderDateTime.year}
                        onChange={(e) => setReminderDateTime({ ...reminderDateTime, year: e.target.value })}
                        isValid={
                            reminderDateTime.year !== '' &&
                            Number.parseInt(reminderDateTime.year) >= new Date().getFullYear() &&
                            Number.parseInt(reminderDateTime.year) < 2100
                        }
                    />
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Text>Time: </InputGroup.Text>
                    <Form.Control
                        aria-label="Hour"
                        maxLength={2}
                        required
                        value={reminderDateTime.hour}
                        onChange={(e) => setReminderDateTime({ ...reminderDateTime, hour: e.target.value })}
                        isValid={
                            reminderDateTime.hour !== '' && Number.parseInt(reminderDateTime.hour) >= 0 && Number.parseInt(reminderDateTime.hour) < 24
                        }
                    />
                    <InputGroup.Text>:</InputGroup.Text>
                    <Form.Control
                        aria-label="Minute"
                        maxLength={2}
                        required
                        value={reminderDateTime.minute}
                        onChange={(e) => setReminderDateTime({ ...reminderDateTime, minute: e.target.value })}
                        isValid={
                            reminderDateTime.minute !== '' &&
                            Number.parseInt(reminderDateTime.minute) >= 0 &&
                            Number.parseInt(reminderDateTime.minute) < 61
                        }
                    />
                </InputGroup>
                <span hidden={new Date(formatToIsoString(reminderDateTime)) > new Date()} className="text-danger">
                    Reminder time cannot be in the past
                </span>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center" style={{ flexWrap: 'nowrap' }}>
                <Button variant="primary" onClick={handleSetTime} disabled={new Date(formatToIsoString(reminderDateTime)) < new Date()}>
                    Set reminder
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
