import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID || 'default value';
const TENANT_ID: string = process.env.REACT_APP_TENANT_ID || 'default value';
const baseRedirectUri = window.location.origin;

const msalConfiguration: Configuration = {
    auth: {
        clientId: CLIENT_ID,
        authority: 'https://login.microsoftonline.com/' + TENANT_ID,
        redirectUri: baseRedirectUri + '/login',
        postLogoutRedirectUri: baseRedirectUri + '/login'
    },
    cache: {
        cacheLocation: 'localStorage', // Clears cache on user logout instead of window/tab close
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const pca = new PublicClientApplication(msalConfiguration);

root.render(
    <MsalProvider instance={pca}>
        <App />
    </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
